<template>
<!--  球员信息-->
  <div class="PlayerInter">
     <div class="titles">
        <span>美巡赛球员</span>
      </div>
    <div class="shell">     
      <div class="shell_content">        
        <div class="content">
          <div class="title">
            <div class="content_img">
              <img :src="players.playerAvatar" alt="">
            </div>
            <div class="right">
              <h3>{{players.playerName}}</h3>
              <h5>{{players.playerEnglishName}} {{players.playerEnglishLastname}}</h5>
              <div class="country">
                <span>{{players.playerNation}}</span>
                <svg class="icon" aria-hidden="true">
                  <use v-bind:xlink:href="'#icon-'+players.playerNationE"></use>
                </svg>

              </div>
            </div>

          </div>

          <div class="article">
            <div v-html="players.playerContent"></div>
          </div>         
      </div>
    </div>          
    </div>
    <div class="top">
      <a href="/Players" >返回 ></a>
    </div> 
  </div>
</template>

<script>
  import getAxios from "../../request";

  export default {
    name: "PlayerInter",
    data(){
      return {
        players:{}
      }

    },
    created() {
      window.scrollTo(0,0)
      this.getPlayerDetail()


    },
    methods:{
    //  请求球员详情
      async getPlayerDetail(){
        this.players = await getAxios({
          url:'/playerDetail',
          data:{
            playerId : this.$route.query.playerId
          }
        })
        console.log("players",this.players.playerContent);
        if(this.$route.query.playerNationE) this.players.playerNationE = this.$route.query.playerNationE
        // console.log(this.players.playerNationE);
      }
    }
  }
</script>

<style scoped lang="less">
  .shell_content{
    width: 1300px;
  }
  .PlayerInter{
    .top{ 
      display: flex;
      justify-content: center;
      width: 100%;
      margin-top: -20px;     
      a{
        width: 300px;
        margin-bottom: 30px;
        padding-bottom: 10px;
        background-color: #ffffff;
        display: block;
        text-align: center;
        padding-top: 10px;
        text-decoration: none;
        color: #0C2848;
        font-size: 23px;
        font-weight: bold;
        line-height: 35px;
      }

    }
    .content{
      padding: 20px 40px 30px 40px;
      .article{
        margin-top: 20px;
        font-size: 18px;
        line-height: 32px;
        /*text-indent: 2em;*/
        overflow: hidden;
        //font-family: My_Medium;
        // font-weight: 600;
        p:nth-child(1){
          text-indent: 0;
        }
      }
      .title{
        display: flex;
        padding-bottom: 30px;
        border-bottom: 1px solid #777777;
        .p_time{
          padding: 5px 0 15px 0;
          font-size: 10px;
          font-weight: 300;
         }
        .content_img{
          width: 120px;
          background-color: #777777;
          height: 150px;
          img{
            width: 100%;
            height: 100%;
            background-color: #eeeeee;
            background-image: none;
          }
        }
        .right{
           padding-left: 20px;
           padding-top:50px;
           font-size: 25px;
           letter-spacing: 2px;
          font-weight: 600;
          h3{
			  font-weight: bold;
            //font-family: My_Medium;
          }
          h3{
            //font-family: My_Black;
          }
           .country{
             margin-top: 10px;
             display: flex;
             svg{
               height: 20px;
               width: 20px;
               margin-left: 20px;
             }
             span{

               font-size: 15px;
             }
           }

         }
      }
      .article{
        padding-top: 30px;
      }

    }
  }
  .titles{
    display: inline-block;
    margin-top: 25px;
    margin-left: 300px;
    span{
      width: 235px;
      height: 44px;
      font-size: 45px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #183666;
      line-height: 60px;   
    }
  }
</style>