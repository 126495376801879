<template>
	<div class="roundCard">
		<table>
			<tr>
				<th class="roundCard01">球洞</th>
				<th class="roundCard02" v-for="(item,index) in hotesItem" :key="index">
					{{item}}
				</th>
				<!-- <th class="roundCard02">OUT</th>
				<th class="roundCard02" v-for="i in 9" :key="i">
					{{i+9}}
				</th>
				<th class="roundCard02">IN</th> -->
				<th class="roundCard03">本轮成绩</th>
			</tr>
			<!--标准杆-->
			<tr>
				<th class="roundCard01">标准杆</th>
				<th class="roundCard02"  :class="{'bold':item.withoutStyle}" v-for="(item,index) in cardHoles" :key="index">
					{{(item.par)?(item.par):'-'}}
				</th>
				<!-- <th class="roundCard02">{{inStatic.par}}</th> -->
				<!-- <th class="roundCard02" v-for="(item,i) in cardHolesOUT" :key="i">
					{{item.par}}
				</th> -->
				<!-- <th class="roundCard02">{{outStatic.par}}</th> -->
				<th class="roundCard03 bold" > {{Static.par}}</th>
			</tr>
			<!--第几轮-->
			<tr>
				<th class="roundCard01">第 {{roundNum}} 轮</th>
				<th class="roundCard02" v-for="(item,index) in cardHoles" :key="index">
					<div class="_shell">
						<div class=" _shell_child"
							:class="{
								'_out_round':!item.withoutStyle&&(item.strokes-item.par)<=-1&&item.strokes!==0,
								'_out_square':!item.withoutStyle&&(item.strokes-item.par)>=1&&item.strokes!==0,
								'bold':item.withoutStyle}">
							<div
								:class="{
									'_in_square':!item.withoutStyle&&(item.strokes-item.par)>=2&&item.strokes!==0, 
									'_in_round':!item.withoutStyle&&(item.strokes-item.par)<=-2&&item.strokes!==0,
									'bold':item.withoutStyle}">
								
								{{(item.strokes)?(item.strokes):0}}
							</div>
						</div>
					</div>
				</th>
				<th class="roundCard03 bold " > {{Static.strokes}}</th>
			</tr>
			<!--成绩-->
			<tr>
				<th class="roundCard01">杆差</th>
				<th class="roundCard02" :class="{'bold':item.withoutStyle}"  v-for="(item,index) in cardHoles" :key="index">
					{{item.status}}
				</th>
				<!-- <th class="roundCard02">-</th>
				<th class="roundCard02" v-for="(item,i) in cardHolesOUT" :key="i">
					{{item.status}}
				</th> -->
				<!-- <th class="roundCard02">-</th> -->
				<th class="roundCard03 bold">{{total}}</th>
			</tr>
		</table>
		<!--备注栏-->
		<div class="explain">
			<div class="_shell">
				<div class=" _shell_child _out_round">
					<div class=" _in_round">
					</div>
				</div>
				<p>老鹰或者更佳</p>
			</div>
			<div class="_shell">
				<div class=" _shell_child _out_round">
					<div>
					</div>
				</div>
				<p>小鸟</p>
			</div>
			<div class="_shell">
				<div class=" _shell_child _out_square">
				</div>
				<p>柏忌</p>
			</div>
			<div class="_shell">
				<div class=" _shell_child _out_square">
					<div class=" _in_square">
					</div>
				</div>
				<p>双柏忌+</p>
			</div>
		</div>
	</div>
</template>

<script>
	import pgaApi from "../../request/pgaApi";

	export default {
		name: "roundCard",
		props: {
			playerId: '',
			roundNum: {
				type: Number,
				default: 0
			}, //第几轮
		},
		data() {
			return {
				cardData: null, //积分卡数据
				cardHoles: [],//球洞数据
				Static: {
					par: 0,
					strokes: 0,
					status: 0
				}, //杆数
				// outStatic: {
				// 	par: 0,
				// 	strokes: 0,
				// 	status: 0
				// }, //后9轮杆数
				// total: 0, //总成绩
				hotesItem:[1,2,3,4,5,6,7,8,9,'OUT',10,11,12,13,14,15,16,17,18,'IN'],
				total:0,
				holes:[]
			}
		},
		created() {
			this.getCard(this.playerId)
		},
		methods: {
			/**
			 * 请求积分卡数据
			 * */
			async getCard(playerId) {
				let _this = this
				if (playerId) {
					let list = await pgaApi({
						url: '/getScoreCard',
						data: {
							playerId: playerId,
							roundNumber: _this.roundNum
						}
					})
					console.log("list",list);
					if (list!=-1) {
						// _this.cardData = list
						// this.round_total_strokes = list[0].round_total_strokes //轮次
						let holes = list[0].holes			
						if (holes[0].hole_number!=1) {
							let card1=[]
							let card2 = []
							let OUT = {
								par:0,
								strokes:0,
								status:"-"
							}
							let IN ={
								par:0,
								strokes:0,
								status:"-"                  
							}                
							card1 = holes.slice(0,9)//10-18
							card2 = holes.slice(9,18)//1-9
							for(let item of card2){
								OUT.par += item.par
								OUT.strokes +=item.strokes
								OUT.withoutStyle = true
							}
							for(let item of card1){
								IN.par += item.par
								IN.strokes +=item.strokes
								IN.withoutStyle = true
							} 
							card1.push(IN)
							card2.push(OUT)               
							_this.cardHoles = card2.concat(card1)
							_this.statisticsAll(_this.cardHoles)
						}else{
							let card1=[]
							let card2 = []
							let OUT = {
								par:0,
								strokes:0,
								status:"-"
							}
							let IN ={
								par:0,
								strokes:0,
								status:"-"                  
							}
							card1 = holes.slice(0,9)
							card2 = holes.slice(9,18)
							for(let item of card1){
								OUT.par += item.par
								OUT.strokes +=item.strokes
								OUT.withoutStyle = true
							}
							for(let item of card2){
								IN.par += item.par
								IN.strokes +=item.strokes
								IN.withoutStyle = true
							}
							card1.push(OUT)                          
							card2.push(IN)                       
							_this.cardHoles = card1.concat(card2)
							console.log(_this.cardHoles);
							_this.statisticsAll(_this.cardHoles)
						}
						_this.$forceUpdate()
					} else {			
						// _this.cardData = null
						_this.Static = {}
						_this.total = 0
						_this.cardHoles =  ['-','-','-','-','-','-',,'-','-','-','-','-','-','-','-','-','-','-','-','-','-']
					}
				} else {
					// console.log('无球员ID')
				}
			},
			/**
			 * 统计前/后9成绩
			 * */
			statisticsAll(cardHoles) {
				this.Static.par = 0
				this.Static.strokes = 0
				this.Static.par = cardHoles[9].par + cardHoles[19].par
				this.Static.strokes = cardHoles[9].strokes + cardHoles[19].strokes				
				// for(let item of ardHoles){
				// 	this.Static.par += item.par
				// 	this.Static.strokes += item.strokes
				// }
				this.total =this.cardHoles[18].status

				// this.inStatic.par = 0
				// this.inStatic.strokes = 0
				// this.outStatic.par = 0
				// this.outStatic.strokes = 0
				// let card1 = []
				// let card2 = []
				// if (rnd !== 0) {
				// 	card1 = this.cardData[0].holes.slice(0, 9)
				// 	card2 = this.cardData[0].holes.slice(9, 18)
				// }

				// if (rnd === 1) {
				// 	for (let item of card1) {
				// 		this.inStatic.par += item.par
				// 		this.inStatic.strokes += item.strokes
				// 		// this.inStatic.status += item.status
				// 	}
				// 	for (let item of card2) {
				// 		this.outStatic.par += item.par
				// 		this.outStatic.strokes += item.strokes
				// 		// this.inStatic.status += item.status
				// 	}
				// } else if (rnd === 9) {
				// 	for (let item of card2) {
				// 		this.inStatic.par += item.par
				// 		this.inStatic.strokes += item.strokes
				// 		// this.inStatic.status += item.status
				// 	}
				// 	for (let item of card1) {
				// 		this.outStatic.par += item.par
				// 		this.outStatic.strokes += item.strokes
				// 		// this.inStatic.status += item.status
				// 	}
				// }
				// console.log("outsatic");
				// this.total = (this.outStatic.strokes + this.inStatic.strokes) - (this.outStatic.par + this.inStatic.par)
				// if (this.total > 0) {
				// 	this.total = '+' + this.total
				// } else if (this.total === 0) {
				// 	this.total = 'E'
				// }
			},
		}
	}
</script>

<style scoped lang="less">
	.roundCard {
		border-bottom: 1px solid #eeeeee;
		box-shadow: 0 10px 15px -7px #dddd;
	}

	table {
		width: 100%;
		font-size: 16px;
		border-spacing: 0;

		/*把单元格间隙设置为0,就能去掉白边*/
		tr:nth-child(2n+1) {
			background-color: #f0f0f0;
		}

		tr {
			th {
				height: 50px;
				border-right: 1px solid #e9e9e9;
				border-bottom: 1px solid #eeeeee;
				font-weight: unset
			}

		}

		.roundCard01 {
			width: 62px;
			//font-family: My_Medium;
			text-align: left;
			padding-left: 25px;
			font-weight: bold;
		}

		.roundCard02 {
			width: 50px;
		}
		.roundCard03{
			width: 70px;
		}
		.bold{
			font-weight: bold;
		}		
		._shell {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;

			._shell_child {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			._out_square,
			._out_round {
				width: 21px;
				height: 21px;
				line-height: 23px;
				border: 1px solid;
				text-align: center;
			}

			._out_round {
				border-radius: 50%;
			}

			._in_round,
			._in_square {
				width: 16px;
				height: 16px;
				line-height: 16px;
				border: 1px solid;
				text-align: center;
				border-radius: 50%;
			}

			._in_square {
				border-radius: 0;
			}
		}
	}

	.explain {
		width: 779px;
		display: flex;
		padding: 10px;
		position: relative;
		left: -10px;
		margin-left: 30px;

		._shell {
			margin-top: 10px;
			margin-bottom: 10px;
			display: flex;
			margin-right: 20px;

			p {
				font-weight: 600;
			}

			._shell_child {
				display: flex;
				justify-content: center;
				align-items: center;
			}

			._out_square,
			._out_round {
				width: 10px;
				height: 10px;
				border: 1px solid;
				text-align: center;
			}

			._out_round {
				border-radius: 50%;
			}

			._in_round,
			._in_square {
				width: 6px;
				height: 6px;
				border: 1px solid;
				text-align: center;
				border-radius: 50%;
			}

			._in_square {
				border-radius: 0;
			}

			p {
				margin-left: 8px;
				position: relative;
				top: -3.5px;
				font-size: 12px;
			}

		}
	}
</style>
