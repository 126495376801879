<template>
  <div class="loader"></div>
</template>

<script>
  export default {
    name: "Loading"
  }
</script>

<style scoped>

  .loader {
    /*background-color: #053970;*/
    height: 40px;
    width: 40px;
    border: 10px solid #eeeeee;

    position: relative;
    /*border-image: -webkit-linear-gradient(#00569D , #99d1ff )round; !* 控制边框颜色渐变 *!*/
    /*border-image: -moz-linear-gradient(#00569D , #99d1ff ) round;*/
    /*border-image: linear-gradient(#00569D , #99d1ff ) 15 round; !* 标准的必须写在最后 *!*/
    /*border-image: linear-gradient(#00569D , #99d1ff );*/
    border-radius: 50% ;
    animation: load-effect 1s infinite linear;
  }
  .loader::before{
    position: absolute;
    content: '';
    width: 40px;
    height: 40px;
    top: -10px;
    left: -10px;
    border-radius: 50%;
    border-top: 10px solid  #595959;
    border-left: 10px solid rgba(0,0,0,0);
    border-right: 10px solid rgba(0,0,0,0);
    border-bottom: 10px solid rgba(0,0,0,0);

  }


  /*制动画，定义好关键帧,用animation进行引用，添加在before中*/
  @keyframes load-effect{
    0%{
      transform: rotate(0deg);
      /*width: 0;*/
      /*height: 0;*/
    }
   50%{
     /*height: 50px;*/
     /*width: 50px;*/
   }
    100%{
      transform: rotate(-360deg);
      /*width: 0;*/
      /*height: 0;*/
    }
  }
</style>