<template>
  <div class="container Players">
    <div class="shell">
      <div class="shell_content">
        <div class="title">
          <span>美巡赛球员</span>
        </div>
        <PushTop :topTitle="top_title" @getPushTop="handleRealtime" >  
            <!-- 联邦快递杯排名 -->
            <template slot="girl" >
              <div class="select">
                <div class="nav">
                  <div :class="{'active':isactive}" @click="changNav(0)">
                    联邦快递杯积分榜
                  </div>
                  <div  :class="{'active':!isactive}" @click="changNav(1)">
                    康卡斯特前十排行榜
                  </div>
                </div>
                  <rankFedexcup v-show="isSelect" :fedexList="fedexList"/>
                  <rankSchedule v-show="!isSelect" :fedexList="fedexList"/>
              </div>
              <!-- <Advert :location="'player'" /> -->
            </template>
        </PushTop>      
        <div class="players">
          <div class="space"></div>
          <div class="content">
            <div class="top">
              <div class="__top">
                <div class="top_title">
                  <span>球员搜索</span>
                </div>
                <div class="center">
                  <p @click="goToClassfy(item)" v-for="(item,i) in zimu" :key="i">
                    <span>{{item}}</span>
                    <span v-if="i<zimu.length-1">|</span>
                  </p>
                </div>
              </div>
              <div class="top_find">
                <input v-model="in_search" @keyup.enter="search()" placeholder="球员搜索"/>
                <span v-if="in_search!==''" class="iconfont icon-guanbi1" @click="delete_in_search"></span>
                <div class="iconfont icon-icon-search" @click="search()"></div>
              </div>
            </div>

            <div class="bm" ref="bm">
              <div class="classfy"
                   v-for="(item_classfy,key1) of players_classfy?players_classfy:''"
                   v-if="!if_show"
                   :key="key1"
                   :ref="key1">
                  <h5  >
                   {{key1}}
                  </h5>
                  <p style="width: 100%;font-size: 15px;color: #777777;text-align: center;padding-bottom: 10px" v-if="item_classfy.length!==0?false:true">暂无数据</p>
                  <div class="player_mode" v-for="(item,key,index) in item_classfy" :key="index">
                    <a href="javascript:;"
                       @click="goDetail(item.playerId, item.playerNationE)">
                      <img  :src='item.playerAvatar' alt="" :onerror="imgURL">
                      <div class="player_title">
                        <div class="title_p">
                          <p>{{item.playerName}}</p>
                          <p>{{item.playerEnglishName}}&nbsp;{{item.playerEnglishLastname}}</p>
                        </div>
                        <div class="country">
                          <h5>{{item.playerNation}}</h5>
                          <span>
<!--                        <img :src="item.flag" alt="">-->
                            <svg class="icon" aria-hidden="true">

                             <use v-bind:xlink:href="'#icon-'+item.playerNationE"></use>
<!--                               <use xlink:href="#icon-Taiwan"></use>-->
                            </svg>

                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
              </div>
<!--              搜索内容展示-->
              <div class="classfy"
                   v-if="if_show"
                   >
                  <p style="width: 100%;font-size: 15px;color: #777777;text-align: center;padding-bottom: 10px" v-if="find_plaers.length!==0?false:true">你想找的信息还没有哦哦！</p>
                  <div class="player_mode" v-for="(item2,index) of find_plaers?find_plaers:''" :key="index">
                    <a href="javascript:;"
                       @click="goDetail(item2.playerId, item2.playerNationE)" >
                      <img  :src='item2.playerAvatar' alt="" :onerror="imgURL">
                      <div class="player_title">
                        
                        <div class="title_p">
                          <p>{{item2.playerName}}</p>
                          <p>{{item2.playerEnglishName}} {{item2.playerEnglishLastname}}</p>
                        </div>
                        <div class="country">
                          <h5>{{item2.playerNation}}</h5>
                          <span>
<!--                        <img :src="item.flag" alt="">-->
                            <svg class="icon" aria-hidden="true">
                             <use v-bind:xlink:href="'#icon-'+item2.playerNationE"></use>

                            </svg>
                          </span>
                        </div>
                      </div>
                    </a>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    视频播放插件-->
    <div class="bofang" v-show="bofang">
      <div class="cast">
        <broadcast  ref="broadcast"></broadcast>
        <div class="close iconfont icon-guanbi" @click="closeIt()"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import PushTop from "../components/PushTop";
  import rankFedexcup from "../components/rankingList/rankFedexcup";
  import rankSchedule from "../components/rankingList/rankSchedule";
  import broadcast from "../components/broadcast/broadcast";
  import getAxios from "../request";
  import methods_share from "../assets/js/methods_share";
  import Advert from "../components/Advert";
   import pgaApi from "../request/pgaApi";

  export default {
    name: "Players",
    components:{
      PushTop,
      rankFedexcup,
      rankSchedule,
      broadcast,
      Advert
    },
    data(){
      return{
        nationFlag: {
          'Scotland': 'http://...',
          'Scotland': 'http://...',
          'Scotland': 'http://...',
        },
        imgURL:'this.src="'+require('../assets/default.png')+'"',
        //26个英文字母
        zimu:[
         'A', 'B', 'C','D', 'E' ,'F', 'G', 'H', 'I', 'J', 'K' ,'L', 'M', 'N', 'O' ,'P' ,'Q', 'R', 'S', 'T' ,'U' ,'V', 'W', 'X', 'Y', 'Z'
       ],
        //联邦快递杯排行
        fedexList:[],
      //  美巡排行

      //  显示字段
        isSelect:true,
      //  选择字段
        isactive:true,
      //  球员信息
        players_classfy:{},
      //  搜索数据
        in_search:'',
      // 搜索结果
        find_plaers:[],
      //  搜素结果是否显示标志
        if_show:false,
      //  顶部标题
        top_title:'player',
      // 中部模块标题 和 传递给子组件PushTop的数据
        center_title: '美巡赛',
      // 控制是否打开播放组件
        bofang:false,
        advert: {},
        list:[],
      }

    },
    created() {
      let date = methods_share.getLocal('players_classfy_createtime') || 0
      let list = methods_share.getLocal('players_classfy')
      // console.log(Date.now());
      if ((Date.now() - date) <= 1000 ){
        this.players_classfy = list
      }else {
        //发送请求球员数据
        this.getPlayers()
      }
    },
    mounted(){
      // this.getAdvert()
    },
    methods:{
      //跳转到详情
      goDetail(playerId,playerNationE){
        this.$router.push({
          path: '/PlayerInter/PlayerInter?',
          query: {
            playerId,
            playerNationE
          }
        })
      },
      //跳转到相应分类
      goToClassfy(A){
        let childHeight = 0
        let offsetTop = this.$refs.bm.offsetTop
        switch (A) {
           case 'A':
             if(this.$refs.A !== undefined) {
               childHeight = this.$refs.A
               this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
             }
             break;
          case "B":
            if(this.$refs.B !== undefined) {
              childHeight = this.$refs.B
              this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
            }
            break;
          case "C":
            if(this.$refs.C !== undefined) {
              childHeight = this.$refs.C
              this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
            }
            break;
          case "D":
            if(this.$refs.D !== undefined) {
              childHeight = this.$refs.D
              this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
            }
            break;
          case "E":
            if(this.$refs.E !== undefined) {
              childHeight = this.$refs.E
              this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
            }
            break;
          case "F":
            if(this.$refs.F !== undefined) {
              childHeight = this.$refs.F
              this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
            }
            break;
          case "G":
            if(this.$refs.G !== undefined) {
              childHeight = this.$refs.G
              this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
            }
            break;
          case "H":
            if(this.$refs.H !== undefined) {
              childHeight = this.$refs.H
              this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
            }
            break;
          case "I":
            if(this.$refs.I !== undefined) {
              childHeight = this.$refs.I
              this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
            }
            break;
          case "J":
            if(this.$refs.J !== undefined) {
              childHeight = this.$refs.J
              this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
            }
            break;case "K":
            if(this.$refs.K !== undefined) {
              childHeight = this.$refs.K
              this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
            }
            break;case "L":
            if(this.$refs.L !== undefined) {
              childHeight = this.$refs.L
              this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
            }
            break;case "M":
            if(this.$refs.M !== undefined) {
              childHeight = this.$refs.M
              this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
            }
            break;
            case "N":
               if(this.$refs.N !== undefined) {
                childHeight = this.$refs.N
                this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
             }
             break;
            case "O":
              if(this.$refs.O !== undefined){
                childHeight = this.$refs.O
                this.$refs.bm.scrollTop =  childHeight[0].offsetTop - this.$refs.bm.offsetTop;
              }
               break;
            case "P":
              if(this.$refs.P !== undefined) {
                childHeight = this.$refs.P
                this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
              }
              break;
            case "Q":
              if(this.$refs.Q !== undefined) {
                childHeight = this.$refs.Q
                this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
              }
            break;
            case "R":
              if(this.$refs.R !== undefined) {
                childHeight = this.$refs.R
                this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
              }
            break;case "S":
            if(this.$refs.S !== undefined) {
              childHeight = this.$refs.S
              this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
            }
            break;case "T":
             if(this.$refs.T !== undefined) {
              childHeight = this.$refs.T
              this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
              }
              break;
             case "U":
              if(this.$refs.U !== undefined) {
                childHeight = this.$refs.U
                this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
              }
               break;
            case "V":
              if(this.$refs.V !== undefined) {
                childHeight = this.$refs.V
                this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
             }
            break;case "W":
             if(this.$refs.W !== undefined) {
              childHeight = this.$refs.W
              this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
            }
            break;case "X":
             if(this.$refs.X !== undefined) {
              childHeight = this.$refs.X
              this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
            }
            break;case "Y":
             if(this.$refs.Y !== undefined) {
              childHeight = this.$refs.Y
              this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
            }
            break;case "Z":
            if(this.$refs.Z !== undefined) {
              childHeight = this.$refs.Z
              this.$refs.bm.scrollTop = childHeight[0].offsetTop - offsetTop;
            }
            break;
        }
      },
      //请求球员列表数据
      async getPlayers(){
        this.players_classfy = []
        this.players_classfy =await getAxios(
            {
              url:'/playerList',
              data:{}
            }
        )
        for(let key in this.players_classfy ) {
          let reg = /^[a-z]$/;
          if(reg.test(key)) {
            console.log(this.players_classfy[key]);
            this.players_classfy[key.toUpperCase()].push(...this.players_classfy[key])
            delete this.players_classfy[key];
          }
        }
        for (let [key, value] of Object.entries(this.players_classfy) ){
          // console.log(value);
          for (let j in value){
            // console.log(value[j].playerNationE);
            if (value[j].playerNationE!==null)
            value[j].playerNationE = value[j].playerNationE.replace(/\s+|(\(|\)|')+/g,"");
          }
        }
        // console.log(this.players_classfy);
        methods_share.writeLocal('players_classfy', this.players_classfy)
        methods_share.writeLocal('players_classfy_createtime',Date.now())

      },
      // 搜索请求数据事件
      async findPlayer(){
         this.find_plaers = await getAxios({
           url:"/searchPlayerByName",
           data:{
             playerName:this.in_search
           }
         }, 'post')
         for( let key in this.find_plaers){
           if(this.find_plaers[key].playerNationE!==null){
            this.find_plaers[key].playerNationE = this.find_plaers[key].playerNationE.replace(/\s+|(\(|\)|')+/g,"");
           }
         }
        // console.log(this.find_plaers);
      },
      //  关闭播放组件
      closeIt(){
        this.bofang = false
      },
      //顶部表格切换事件
      changNav(index){
        if (index){
          this.isSelect = false
          this.isactive = false
        }else{
          this.isSelect = true
          this.isactive = true
        }
      },
    // 输入框是否输入触发的事件
      ispush(){
         if(this.in_search === ''){
           this.find_plaers = []
           this.if_show = false
         }
      },
      delete_in_search(){
        this.find_plaers = []
        this.if_show = false
        this.in_search = ''
      },

    //  搜索函数
      search(){
        this.findPlayer()
        this.if_show = true
        // console.log(this.in_search);
      },
      //  接收Realtime/PushTop组件传递过来的参数
      handleRealtime(e){
        if(e.classfy === 'video'){
          // this.videoId = e.id
          this.bofang = true
          this.$refs.broadcast.getVideo(e.id)
        }else if(e.classfy === 'article'){
          this.$router.push({
            path: 'NewsDetails',
            query: {id :e.id}
          })
          // console.log('ss'+this.$router);
        }
      },
    }
  }
</script>

<style scoped lang="less">
  .title{
    width: 100%;
    background-color: #eeeeee;
    font-size: 40px;
    padding-bottom: 25px;
    span{
      letter-spacing: 2px;
      color: #053970;
      //font-family: My_Bold;
    }
  }
  .select{
    height: 95%;
    .nav{
      display: flex;
      background-color: #001629;
      color:  #2389f5;
      div{
        flex: 1;
        text-align: center;
        padding: 6px;
        font-size: 10px;
        /*border-bottom: 2px solid rgba(0,0,0,0);*/
      }
      div:hover{
        color: #ffffff;
        border-bottom: 1px solid #1490ff ;
      }
    }
  }
  /* 广告图样式 */
  .ad{
    margin-top: 0px;
    bottom: 15px;
    width: 100%;
    height: 100%;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .active{
    color: #ffffff;
    border-bottom: 1px solid #ED282A ;
  }
  .players{
    width: 1300px; //9.27根据美巡赛球员模块的宽度加的宽度
    .space{
      width: 100%;
      height: 40px;
      background-color: #eeeeee;
    }
    .content{
      border-top: 7px solid #053970;
      padding: 30px 0 30px 0;
      background: #FFF;//9.27根据美巡赛球员模块的宽度加的背景色
      .top{
        padding: 10px 0 40px 10px;
        margin-right: 30px;
        position: relative;
        /*padding-right: 10px;*/
        display: flex;
        height: 60px;
        flex-direction: column;
        /*padding-bottom: 40px;*/
        align-items: center;
        border-bottom: 1px solid #777777;
        .__top{
          display: flex;
        }
        .top_title{
          padding-left: 75px;
          letter-spacing: 2px;
          font-size: 17px;
          //font-family: My_Bold;
          color: #999999;
          line-height: 35px;
        }
        .center{
          //font-family: My_Medium;
          /*padding-top: 10px;*/
          margin-left: 20px;
          font-size: 15px;
          /*letter-spacing: 6px;*/
          line-height: 35px;
          display: flex;
          /*border: 1px solid red;*/
          p{
            cursor: pointer;
            text-decoration: none;
            span{
              //font-family: My_Medium;
             letter-spacing: 6.5px;
            }
            span:nth-last-child(2){
              color: #003c7c;
            }
          }
        }
        .top_find{
          /*position: absolute;*/
          /*right: 0;*/
          /*backgrounsd-color: #777777;*/
          // margin-top: 10px;
          height: 30px;
          padding-left: 10px;
          width: 300px;
          border: 1px solid #777777;
          /*border-right: none;*/
          display: flex;
          justify-content: center;
          align-items: center;
          input{
            outline: none;
            flex: 7;
          }
          span{
            flex:1;
            font-size: 10px;
            text-align: right;
            padding-right: 5px;
          }
          .icon-icon-search{
            flex: 1;
            display: inline-block;
            /*border: 1px solid red;*/
            text-align: center;
            height: 100%;
            line-height: 30px;
            /*border-left: 1px solid #777777;*/
          }
          outline: none;
        }
      }


      .bm{
        margin-top: 30px;
        height: 800px;
        overflow: auto;
        /*IE滚动条样式*/
        scrollbar-face-color:#01407e;
        scrollbar-arrow-color:#01407e;
        /*滚动条背景色*/
        scrollbar-track-color:#eeeeee;
        /*padding-bottom: 500px;*/
        .classfy:nth-last-child(1){
          padding-bottom: 600px;
        }
        .classfy{
          width: 100%;
          h5{
            border-bottom: 1px solid #b3b3b3;
            border-top: 1px solid #b3b3b3;
            color: #00182e;
            font-size: 28px;
            padding: 15px;
            margin-bottom: 20px;
            background-color: #eeeeee;
            height: 30px;
            line-height: 25px;
          }

          .player_mode{
               display: inline-block;
               margin: 12px 0 12px 15px;
               position: relative;
               width: 198px;
               height: 265px;
               img{
                 background-image: none;
               }
               a{
                 text-decoration: none;
                 background-color: #eeeeee;
                 img{
                   width: 210px;
                   height: 263px;
                   /*background-color: red;*/
                   background-image: linear-gradient(to right, #4f4f4f,#8a8a8a);
                   /*background-eat: no-repeat;*/
                   background-size:100%;
                 }
               }
               .player_title{
                 position: absolute;
                 bottom: 0;
                 color: #ffffff;
                 padding-left: 10px;
                 padding-top: 5px;
                 width: 102%;
                 font-weight: bold;
                 font-size: 20px;
                 /*<!--text-shadow:  2px 2px 2px #000000,-2px -2px 2px #000000,2px -2px 2px #000000;-->*/
                 /*background-image: linear-gradient(rgba(135, 135, 135,.1),#333333);*/
                 background-color: rgba(0,0,0,.5);
                 .title_p{
                   width: 100%;
                   font-size: 18px;
                   /*border: 2px solid red;*/
                   border-bottom: 1px solid #656565;
                   /*padding-bottom: 2px;*/
                   p{
                     //font-family: My_Bold;
                   }
                 }

                 .country{
                   font-weight: 500;
                   overflow: hidden;
                   font-size: 17px;
                   padding-top: 2px;
                   height: 25px;
                   /*border: 1px solid red;*/
                   h5{
                     /*border: 1px solid red;*/
                     float: left;
                     /*text-align: center;*/
                     height: 17px;
                     display: inline-block;
                     /*width: 30px;*/
                     color: #ffffff;
                     font-size: 15px;
                     background-color: rgba(0,0,0,0);
                     border: none;
                     margin: 0;
                     padding: 0;
                     font-weight: 600;
                     /*//font-family: My_Medium;*/
                   }

                   svg{
                     height: 16px;
                     margin-top: 5px;
                     width: 24px;
                     float: right;
                     margin-right: 15px;
                     background-color: #eeeeee;
                   }

                 }
               }
             }
        }
      }
      .bm::-webkit-scrollbar {
        /*display:none;*/
        width:8px;
        /*height:100%;*/
        background-color: #eeeeee;
        /*color:#01407e;*/
        /*border-top: 1px solid #edf2f9;*/
      }
      .bm::-webkit-scrollbar-thumb {
        background-color: #01407e;
      }
    }


  }
  .bofang{
    background-color: #ffffff;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 20;
    background-color: rgba(0,0,0,.5);
    .cast{
      background-color: #ffffff;
      width: 60vw;
      position: absolute;
      left: 50%;
      top: 10vh;
      margin-left: -30vw;
      .close{
        font-size: 30px;
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    
  }
  .WorldRanking{  
    width: 287px;
    height: 575px;
    padding-top: 10px;
    background-color: #011d35;
    .top{
      padding: 10px 10px 10px 10px;
      .__title{
        font-size: 21px;
        span{
          //font-family: My_Bold;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 32px;
        }
      
      }
    }
    .__list{
      background-color: #0e2e4b;
      height: 40px;
      margin: 10px;       
     ._list{
        font-size: 12px;
        .list_child{
          padding-top: 5px;
          padding-left: 5px;
          display: flex;
          height: 30px;
          border: 1px solid #18344e;       
          .line01{
            width: 60px;
            color: #FFFFFF;
            text-align: center;
            border-right: 1px solid #18344e; 
          }
          .line02{
            //font-family: My_Medium;;
            width: 140px;
            
            text-align: center;
            color: #FFFFFF;
            border-right: 1px solid #18344e; 
          }
          .line02_other{
            //font-family: My_Medium;
            color: #ffffff;
          }

        }
      .list_child:nth-child(1){
          div{
            //font-family: My_Medium;
          }
        }
        .bg{
          background-color: #0e2e4b;
        }
        .more{
          margin-top: 20px;
          background-color:#183d5d;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 40px;
          a{
            color: #ffffff;
            width: 50px;
            height: 13px;
            font-weight: 400;
          }
        }
      }
      ._list{
        .list_tr_child{
          line-height: 18px !important;
          // padding-top: 5px;
          background-color: #0e2e4b;

        }
        .bg{
          background-color: #011d35;
        }
      }
    }
  }
  
  .Players /deep/ .outside .ad {
    width: 225px;
    padding-left: 10px;
  }
</style>
