const methods_share = {
    // 时间戳转日期函数
    transformDate(timestamp) {
        let date = new Date(timestamp);
        // let date = new Date(parseInt(timestamp) * 1000);//时间戳为10位需*1000，
        let Y = date.getFullYear() + '-';
        let M = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
        let D = date.getDate()
            // console.log('date.getFullYear()'+date.getFullYear());
        return Y + M + D
    },
    transformDateDian(timestamp) {
        let date = new Date(timestamp);
        // let date = new Date(parseInt(timestamp) * 1000);//时间戳为10位需*1000，
        let Y = date.getFullYear() + '年';
        let M = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) + '.' : date.getMonth() + 1 + '月';
        let D = date.getDate() + '日'
            // console.log('date.getFullYear()'+date.getFullYear());
        return Y + M + D
    },
    // 使用 LocalStorage 进行缓存
    writeLocal(key, value) {
        // localStorage.setItem("key", "value");
        localStorage.setItem(key, JSON.stringify(value));
    },
    getLocal(key) {
        return JSON.parse(localStorage.getItem(key));
    },
    //对数组进行排序
    sortBy(attr, rev) {
        //第二个参数没有传递 默认升序排列
        if (rev == undefined) {
            rev = 1;
        } else {
            rev = (rev) ? 1 : -1;
        }
        return function(a, b) {
            a = a[attr];
            b = b[attr];
            if (a < b) {
                return rev * -1;
            }
            if (a > b) {
                return rev * 1;
            }
            return 0;
        }
    }
}

export default methods_share