<template>
  <div class="rankNews">
    <!-- 广告图 -->
    <Advert :location="'news1'" />
    <div class="content">
      <div class="title">
        <h2>热门新闻</h2>
      </div>
      <div class="hot">
        <!-- <a href="javascript:;"
           @click="goDetail(hotNews.articleId)" class="hot_new" v-for="(hotNews ,index) of hotNews" :key="hotNews.articleId">
          <div class="left">
            <img :src="hotNews.articlePostUrl" alt="">
          </div>
          <div class="right">
            <span>{{hotNews.articleTitle.length>20?hotNews.articleTitle.slice(0,20)+'...':hotNews.articleTitle}}</span>
          </div>
        </a> -->
        <ul class="infinite-list newScroll" 
          v-infinite-scroll="infiniteScroll" 
          :infinite-scroll-disabled="isInfiniteScroll" 
          :infinite-scroll-distance="5"  
          style="overflow:auto; height: inherit;">
          <li v-for="(hotNews ,index) of hotNews" class="infinite-list-item">
              <a href="javascript:;" class="hot_new" @click="goDetail(hotNews.articleId)" >
                  <div class="left">
                    <img :src="hotNews.articlePostUrl" alt="">
                  </div>
                  <div class="right">
                    <span>{{hotNews.articleTitle.length>20?hotNews.articleTitle.slice(0,20)+'...':hotNews.articleTitle}}</span>
                  </div>
              </a>
          </li>
        </ul>
      </div>
    </div>
     <!-- 广告图 -->
     <Advert :location="'news2'" />
  </div>
</template>

<script>
  import Advertising from "../Advertising";
  import getAxios from "../../request";
  import Advert from "../Advert";

  export default {
    name: "rankNews",
    components:{
      Advertising,
      Advert
    },
    data(){
      return {
        //热门新闻
        hotNews:[],
        total: 0,
        page:{
          current:1,
          size:7
        },
        isInfiniteScroll: false, // 控制滚动禁用
      }
    },
    created() {
    //  请求热门新闻列表
      // this.getHotNews()
    },
    methods:{
      //跳转到详情
      goDetail(id){
        window.scrollTo(0,0)
        this.$emit('getID',id)
        this.$router.push({
          path: '/NewsDetails',
          query: {id : id}
        })
      },
    //  请求热门新闻列表事件
       getHotNews(){
         getAxios({
          url:"/getHotArticleList",
          data:{
              current:this.page.current,
              size:this.page.size
          }
        }).then(res=>{
          let {total,records} = res
          this.hotNews.push(...records)
          this.total = total
          this.page.current +=1
          if (records.length<this.page.size || this.hotNews.length== this.total) {
            this.isInfiniteScroll = true
            return false
          }
          this.isInfiniteScroll = false
        })
        // this.hotNews = this.hotNews.concat(list)
        // console.log(this.hotNews);
      },      
      //
      infiniteScroll() {
        this.isInfiniteScroll = true;
        this.getHotNews();
      },
    },

  }
</script>

<style scoped lang="less">
  .rankNews{
    /*background-color: rgba(217, 217, 217,.2);*/
    .ad{
       width: 300px;
       margin: 25px;
       height: 250px;
      /*border: 1px solid red;*/
      img{
        width: 100%;
      }
     }
    .ad2{
      width: 300px;
      padding: 25px;
      height: 500px;
      img{
        width: 100%;
      }
    }
    .content{
      // margin: 25px;
      margin-top: 65px;
      margin-bottom: 40px;
      .title{
        font-weight: 700;
        font-size: 17px;
        text-transform: uppercase;
        color: #163e6f;
        margin: 0;
        padding: 0 0 15px 0;
        border-bottom: solid 1px #c5c5c5;
        letter-spacing: 1px;
        h2{
          font-family: 'Microsoft YaHei';
        }
      }
      .hot{
        height: 800px;
        .hot_new{
          text-decoration: none;
          display: flex;
          padding: 20px 0 20px 0;
          border-bottom: 1px solid #eeeeee;
          .left{
            width: 130px;
            height: 76px;
            background-color: #777777;
            /*overflow: hidden;*/
            img{
              width: 130px;
              height: 73px;
            }
          }
          .right{
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            margin: 0;
            padding: 0 0 0 10px;
            color: #333;
            span{
              //font-family: My_Medium;
            }
          }

        }
        .btm{
          margin-top: 20px;
           width: 100%;
           text-align: center;
           display: flex;
           justify-content: center;
           align-items: center;
           /*border: 1px solid red;*/
          font-size: 17px;
          color: #053970;
          font-weight: bold;
          padding: 5px;
          span:nth-child(2){
            padding-left: 5px;
          }
         }
      }
      .newScroll{
        padding-right:5px ;
      }
      .newScroll::-webkit-scrollbar {
        /*display:none;*/
        width:10px;
        /*height:100%;*/
        background-color: #eeeeee;
        /*color:#01407e;*/
        /*border-top: 1px solid #edf2f9;*/
      }
      .newScroll::-webkit-scrollbar-thumb {
        background-color: gray;
      }
    }
  }
</style>