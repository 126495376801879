<template>
  <div class="Stats">
    <div class="shell">
      <div class="shell_content">
        <div class="title" >
          <span>美巡数据</span>
        </div>
        <div class="nav">
          <div class="nav_child"><router-link to="/Stats/dataForm">数据概览</router-link></div>
          <div class="nav_child"><router-link to="/Stats/ScoreBoard">联邦快递杯积分榜</router-link></div>
          <div class="nav_child"><router-link to="/Stats/Money">官方奖金榜</router-link></div>
          <div class="nav_child"><router-link to="/Stats/WorldRanking">世界排名</router-link></div>
          <!-- <div class="nav_child"><router-link to="/Stats/Campion">生涯冠军排名（前50名）</router-link></div>
          <div class="nav_child"><router-link to="/Stats/GrandSlam">大满贯赛冠军排名</router-link></div> -->
          <div class="nav_child" :class="{'child-dn':!isShow, 'child-db':isShow}" >
            <router-link to="/Stats/DataTemplate">查看更多</router-link>
          </div>
        </div>
      <router-view/>
      </div>
    </div>
  </div>
</template>


<script>
  export default {
    name: "Stats",
    components:{ },
    data(){
      return {
        isShow: false,
        tabName:'数据模板',
      }
    },
    methods:{
      func( val ){
        console.log(val)
        this.isShow = true
        this.tabName = val 
      }
    }
  }
</script>

<style scoped lang="less">
  .title{
    width: 100%;
    background-color: #eeeeee;
    font-size: 40px;
    color: #053970;
    padding-bottom: 25px;
    span{
      /*font-weight: 700;*/
      letter-spacing: 2px;
      //font-family: My_Bold;
    }
  }
  .nav{
    height: 60px;
    background-image: linear-gradient(#ffffff 2px, #eeeeee);
    border: 1px solid #dddddd;
    /*overflow: hidden;*/
    display: flex;
    .nav_child{
      display: flex;
      position: relative;
      a{
        line-height: 60px;
        color: #000000;
        text-decoration: none;
        font-size: 20px;
        position: relative;
        border-right: 1px solid #dddddd;
        //font-family: My_Tab_Bold;
        padding: 0 20px 20px 20px;
        z-index: 2;
      }
      .stat_shadow{
        position: absolute;
        width: 100%;
        /*height: 50px;*/
        background-color: transparent;
        top: -5px;
        left: 0;
        border-bottom: 50px solid transparent;
        border-bottom-left-radius: 60px;
        border-bottom-right-radius: 60px;
        box-shadow: 0 4px 19px #555555;
        z-index: 1;
        display: none;
      }
      .stat_shadow1{
        width: 80%;
        box-shadow: 15px 4px 19px #777777;
      }
      .activeNav{
        display: block;
      }
      .router-link-active{
        z-index: 2;
        position: relative;
        height: 45px;
        background-color: #ffffff;
        border-top: 5px solid #013d7c;
        color: #013d7c;
        font-weight: 600;
        top: -5px;
        /*padding: 0 20px 50px 20px;*/
        /*top: -5px;*/
      }
    }
    .child-dn{
      display: none !important;
    }
     .child-db{
      display: flex;
    }



    /*.router-link-active:after{*/
    /*  content: '';*/
    /*  !*background-color: #333333;*!*/
    /*  position: absolute;*/
    /*  width: 40px;*/
    /*  height: 40px;*/
    /*  border-radius: 50%;*/
    /*  right: -2px;*/
    /*  top: -20px;*/
    /*  box-shadow: 5px 0 15px #cccccc;*/
    /*  z-index: 2;*/
    /*}*/
  }
</style>