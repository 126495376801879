<template>
  <div class="field-module">
    <div class="title">PLAYER NAMES</div>
    <div class="grid" :style="{'grid-template-columns': `repeat(${columns},1fr)`}">
      <div class="list name" v-for="(item,index) in nameList" :key="index" :class="item.value==0?'light-cell':''">{{item.name}}</div>
    </div>
    <h2 class="mt20">ALTERNATES</h2>
    <div class="grid grid2 mt20" :style="{'grid-template-columns': `repeat(${columns2},1fr)`}">
      <div class="list name" v-for="(item,index) in nameList2" :key="index" :class="item.value==0?'light-cell':''">{{item.name}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "lineup",
  data() {
    return {
      nameList:
      [
        {name:'An, Byeong Hun',value:0},
        {name:'Straka, Sepp',value:0},
        {name:'An, Byeong Hun',value:0},
        {name:'Straka, Sepp',value:0},
        {name:'An, Byeong Hun',value:0},
        {name:'Straka, Sepp',value:1},
        {name:'An, Byeong Hun',value:1},
        {name:'Straka, Sepp',value:1},
        {name:'An, Byeong Hun',value:1},
        {name:'Straka, Sepp',value:0},
        {name:'An, Byeong Hun',value:0},
        {name:'Straka, Sepp',value:0},
        {name:'An, Byeong Hun',value:0},
        {name:'Straka, Sepp',value:1},
        {name:'An, Byeong Hun',value:1},
        {name:'Straka, Sepp',value:1},
        {name:'An, Byeong Hun',value:1},
        {name:'Straka, Sepp',value:0},
        {name:'An, Byeong Hun',value:0},
        {name:'Straka, Sepp',value:0},
        {name:'An, Byeong Hun',value:0},
        {name:'An, Byeong Hun',value:1},
        {name:'Straka, Sepp',value:1},
        {name:'An, Byeong Hun',value:0},
        {name:'Straka, Sepp',value:1},
        {name:'An, Byeong Hun',value:0},
        {name:'Straka, Sepp',value:1},
        {name:'An, Byeong Hun',value:1},
        {name:'Straka, Sepp',value:0},
      ],
      nameList2:
      [
        {name:'An, Byeong Hun',value:0},
        {name:'Straka, Sepp',value:1},
        {name:'An, Byeong Hun',value:0},
        {name:'Straka, Sepp',value:1},
        {name:'An, Byeong Hun',value:0},
        {name:'Straka, Sepp',value:1},
        {name:'An, Byeong Hun',value:0},
        {name:'Straka, Sepp',value:1},
        {name:'An, Byeong Hun',value:0},
        {name:'Straka, Sepp',value:1},
        {name:'An, Byeong Hun',value:0},
        
      ],
      columns: 4,
      columns2:1,

    };
  },
  methods: {
  },
};
</script>

<style lang="less">
@import 'lineup.css';
</style>