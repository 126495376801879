<template>
  <div class="full_scorecard">
    <div class="shell">
      <div class="shell_content">
        <!--头像信息-->
        <div class="playerInter">
          <div class="headPortrait">
            <!-- <diimg class="img" :url="headImage"></diimg> -->
            <img  :src="headImg" alt="">
          </div>
          <div class="full_inter">
            <p>{{ playerEname }}</p>
            <p>{{ playerName }}</p>
            <div class="country">
              <!-- <svg class="icon" aria-hidden="true">
                <use v-bind:xlink:href="'#icon-'+'China'"></use>
              </svg>
              <span>中国</span> -->
              <span
                class="tc dis-bi vab flag flag-24x24"
                :class="country"
              ></span>
              <span>{{ country }}</span>
            </div>
          </div>
        </div>
        <!--比赛名称-->
        <div class="match">
          <p>{{ matchs.matchTime }}</p>
          <p>{{ matchs.matchName }}</p>
        </div>
        <!--选择栏信息-->
        <!-- <div class="full_select" v-if="false">
          <div class="select_left">
            <div class="content" @click="isSlectYear=!isSlectYear">
              {{selectYear}}
              <span class="iconfont icon-xiala1"></span>
            </div>
            <div class="list" v-show="isSlectYear">
              <p v-for="i in 30" @click="hanleSelectYear(2021-i)">{{2021-i}}</p>
            </div>
          </div>
          <div class="select_center">
            <div class="content" @click="isSlectmatch=!isSlectmatch">
              {{selectMatch}}
              <span class="iconfont icon-xiala1"></span>
            </div>
            <div class="list1" v-show="isSlectmatch">
              <p v-for="i in 1" @click="hanleSelectMatch()"></p>
            </div>
          </div>
          <div class="select_right">
            <button>去领先榜了解更多</button>
          </div>
        </div> -->
        <!--积分卡数据-->
        <div class="roundCard" v-for="(item,index) in activeIndexTitles" :key="index">
            <div class="roundTitle" >第{{item}}轮 </div>
            <roundCard :roundNum="(index+1)" :playerId="playerId"></roundCard> 
          <!-- <div class="roundTitle">
            第四轮
          </div>
          <roundCard :roundNum="4" :playerId="playerId"></roundCard>
        </div>
        <div class="roundCard">
          <div class="roundTitle">
            第三轮
          </div>
          <roundCard :roundNum="3" :playerId="playerId"></roundCard>
        </div>
        <div class="roundCard">
          <div class="roundTitle">
            第二轮
          </div>
          <roundCard :roundNum="2" :playerId="playerId"></roundCard>
        </div>
        <div class="roundCard">
          <div class="roundTitle">
            第一轮
          </div>
          <roundCard :roundNum="1" :playerId="playerId"></roundCard> -->
        </div>
        <!-- <div class="roundCard">
          <div class="roundTitle">
            比赛数据
          </div>
          <tournamentData></tournamentData>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import roundCard from "../../components/FullScorecards/roundCard";
import tournamentData from "../../components/FullScorecards/tournamentData";
import getAxios from "../../request";
 import pgaApi from "../../request/pgaApi";

export default {
  name: "index",
  components: { roundCard, tournamentData },
  data() {
    return {
      selectYear: new Date().getFullYear(),
      selectMatch: "", //选中的比赛
      isSlectYear: false, //是否打开年份选择
      isSlectmatch: false, //是否开比赛选择
      matchs: {},
      playerId: "",
      headImage: "",
      playerName: "",
      playerEname: "",
      country: "",
      headImg:"",
      activeIndexTitles:["一","二","三","四"]

    };
  },
  created() {
    this.playerId = this.$route.query.playerId;
    this.playerName = this.$route.query.playerName;
    this.playerEname = this.$route.query.playerEname;
    this.headImg = this.$route.query.headImg;
    this.country = this.$route.query.country;
    this.getMatch();
    this.getCardData();
    // console.log("playerName",this.$route.query.playerName);
    // this.headImage = "https://pga-tour-res.cloudinary.com/image/upload/c_fill,dpr_1.1,f_auto,g_face:center,h_160,q_auto,t_headshots_leaderboard_l,w_160/headshots_"+this.playerId+".png"
  },
  methods: {
    /**
     * 选择年份
     * */
    // hanleSelectYear(year) {
    //   this.selectYear = year;
    //   this.isSlectYear = false;
    // },
    // /**
    //  *  选择比赛
    //  * */
    // hanleSelectMatch() {},
    getMatch() {
      getAxios({
        url: "/leaderboardMatchInfo",
        data: {},
      })
        .then((response) => {
          this.matchs = response;
        })
        .catch(() => {});
    },
    async getCardData(){
      let list = await pgaApi({
        url:'/getScoreCard',
        data:{
          playerId: this.$route.query.playerId,
          roundNumber: 1
        }
      })
      if( list.length>0 ){
        // this.headImg = list[0].headImage
      }

      },
  },
};
</script>

<style scoped lang="less">
.full_scorecard {
  .playerInter {
    padding: 20px;
    display: flex;
    height: 100px;
    width: 97%;
    border-bottom: 1px solid #dddddd;
    .headPortrait {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
          width: 128px;
          height: 128px;
          background-color:#cbcbcb;
          border-radius: 50%;
          margin-top: 10px;
      }
    }
    .full_inter {
      margin-left: 38px;
      margin-top: 15px;
      p {
        //font-family: My_Bold;
        font-size: 22px;
        line-height: 22px;
      }
      p:nth-child(2) {
        padding-top: 5px;
        font-size: 17px;
      }
      .country {
        display: flex;
        margin-top: 10px;
        width: 500px;
        height: 18px;
        position: relative;
        span:nth-child(2) {
          top: 1px;
          font-size: 16px;
          //font-family: My_Medium;
          margin-left: 10px;
        }
      }
    }
  }

  .match {
    padding-left: 20px;
    margin-top: 10px;
    margin-bottom: 20px;
    p {
      line-height: 25px;
      //font-family: My_Bold;
    }
    p:nth-child(1) {
      font-size: 15px;
    }
    p:nth-child(2) {
      padding-top: 10px;
      font-size: 26px;
    }
  }

  .full_select {
    display: flex;
    padding-left: 15px;
    padding-bottom: 30px;
    border-bottom: 1px solid #dddddd;
    .select_left {
      position: relative;
      .content {
        width: 70px;
        cursor: pointer;
        padding: 5px;
        height: 20px;
        border: 1px solid #dddddd;
        font-size: 15px;
        //font-family: My_Medium;
        span {
          background-color: #b3b3b3;
          color: #ffffff;
          position: absolute;
          /*font-size: 15px;*/
          right: 5px;
        }
      }
      .list {
        position: absolute;
        border: 1px solid #7b9dd4;
        height: 300px;
        width: 100%;
        overflow: auto;
        background-color: #ffffff;
        p {
          cursor: pointer;
          font-size: 15px;
          padding-left: 5px;
          //font-family: My_Medium;
        }
        p:hover {
          background-color: #1e90ff;
          color: #ffffff;
        }
      }
    }

    .select_center {
      margin-left: 30px;
      position: relative;
      .content {
        height: 20px;
        width: 490px;
        cursor: pointer;
        padding: 5px;
        border: 1px solid #dddddd;
        font-size: 15px;
        //font-family: My_Medium;
        span {
          background-color: #b3b3b3;
          color: #ffffff;
          position: absolute;
          /*font-size: 15px;*/
          right: 5px;
        }
      }
      .list1 {
        position: absolute;
        border: 1px solid #7b9dd4;
        height: 300px;
        width: 490px;
        overflow: auto;
        background-color: #ffffff;

        p {
          overflow: hidden;
          cursor: pointer;
          font-size: 15px;
          padding-left: 5px;
          //font-family: My_Medium;
        }
        p:hover {
          background-color: #1e90ff;
          color: #ffffff;
        }
      }
    }

    .select_right {
      position: relative;
      top: -3px;
      margin-left: 10px;
      button {
        height: 30px;
        width: 200px;
        background-color: #18304a;
        color: #eeeeee;
      }
    }
  }

  .roundCard {
    width: 100%;
    .roundTitle {
      height: 60px;
      line-height: 60px;
      padding-left: 40px;
      //font-family: My_Bold;
      font-size: 25px;
    }
  }
}
</style>