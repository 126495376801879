<template>
  <div id="botton">
    <div class="top">
      <span>友情链接</span>
    </div>
<!--    中间-->
    <div class="center">
      <img src='../../assets/logo/04c267f55026e9eb477bc655d369db5.png' alt="">
<!--      <a href="https://www.pgatour.com/" >-->
<!--        <img style="height: 64px" src="../../assets/tour_logo.png" alt="">-->
<!--      </a>-->
<!--      <a href="http://www.golfbox.cn/" target="_blank">-->
<!--        <img src="../../assets/images/PinDao.jpg" alt="">-->
<!--        <span class="p1" style="color: #c65a57">高尔夫频道</span>-->
<!--      </a>-->

    </div>

<!--    代理商相关信息-->
    <div class="botton01">
<!--      <p>某某版权</p>-->
<!--      <p>123555656</p>-->
    </div>

<!--联系方式-->
    <div class="button02">
      <p>联系我们</p>
      <div class="button02_icon">
        <div class="to_block">
          <a class="block01" href="#">
            <div class="tip">
              <img src="" alt="">
            </div>
            <div>
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-wechat"></use>
              </svg>
            </div>
          </a>
        </div>
        <div class="to_block">
          <a class="block02" href="#">
            <div class="tip">
              <img src="" alt="">
            </div>
            <div>
              <svg class="icon" aria-hidden="true">
                <use  xlink:href="#icon-weibo"></use>
              </svg>
            </div>
          </a>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: "botton"
  }
</script>

<style scoped lang="less">
  #botton{
    overflow: hidden;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    color: #000000;
  }
  .top{
   width: 70%;
   padding: 10px 0 10px 5px ;
   border-bottom:2px solid #eeeeee;
    font-size: 15px;
  }
  .center{
    overflow: hidden;
    height: 64px;
    width: 100%;
    /*padding-left: 240px;*/
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    font-size: 5px;
    color: #000000;
    img{
      width: 100px;
      background-image: none;
    }

  }
  /*.center img{*/
  /*  width: 50px;*/
  /*  height: 50px;*/
  /*  background-color: #ffffff;*/
  /*  !*padding-right: 10px;*!*/
  /*  background-image: none;*/
  /*}*/
  .center a{
    padding: 0 30px 0 30px;
    width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: black;

  }
  .p1{
    display: block;
    font-size: 2px;
   }
  .botton01{
    margin: 50px 0 0 0;
    padding-bottom: 10px;
    font-size: 5px;
    width: 70%;
    text-align: center;
    border-bottom: 2px solid #eeeeee;
  }
  .button02{
    //font-family: My_Bold;
    text-align: center;
    margin-top: 10px;
  }
  .button02 p{
    //font-family: My_Bold;
    font-size: 5px;
  }
  .button02_icon {
    display: flex;
    margin: 20px 0 30px 0;
    position: relative;
  }
  .button02_icon svg {
    height: 30px;
    width: 30px;
    margin: 0 25px 0 25px;
  }
  .tip {
    background-color: rgba(225,225,225,0);
    width: 60px;
    height: 60px;
    border: 2px solid #eeeeee;
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 10px;
    display: none;
    position: absolute;
    bottom: 35px;
  }
  .to_block:hover .tip{
    display: block;
  }
  .tip:after{
    /*border-bottom: ;*/
  }
  .tip img{
    width: 60px;
    height: 60px;
  }
</style>
