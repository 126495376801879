<template>
  <!--美巡top-->
  <div class="rankSchedule">
    <div class="outside">
      <div class="title">
        <span>康卡斯特前十排行榜</span>
      </div>
      <div class="content">
        <div style="display: flex">
          <table border="0" cellpadding="0" >
            <thead>
            <th>排名</th>
            <th>领先者</th>
            <th>排名</th>
            <th>领先者</th>
            </thead>
            <tbody>
            <tr :class="{'bg':index%2}" v-for="(item,index) in fedexList.slice(0,5)" :key="index">
              <td>
                {{item.curRank}}
              </td>
              <td>
                <div class="_father">
                  {{item.player_name}}
                  <p class="plaer_name1">{{item.e_name.slice(0,17)}}</p>
                </div>
              </td>
              <td>
                {{item.curRank2}}
              </td>
              <td>
                <div class="_father">
                  {{item.player_name2}}
                  <p class="plaer_name1">{{item.e_name2.slice(0,17)}}</p>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
<!--          <table border="0" cellpadding="0" >-->
<!--            <thead>-->
<!--            <th>排名</th>-->
<!--            <th>领先者</th>-->
<!--            </thead>-->
<!--            <tbody>-->
<!--            <tr :class="{'bg':index%2}" v-for="(item,index) in fedexList.slice(5,10)" :key="index">-->
<!--              <td>-->
<!--                {{item.curRank}}-->
<!--              </td>-->
<!--              <td>-->
<!--                <div class="_father">-->
<!--                  {{item.player_name}}-->
<!--                  <p class="plaer_name1">{{item.e_name}}</p>-->
<!--                </div>-->
<!--              </td>-->
<!--            </tr>-->
<!--            </tbody>-->
<!--          </table>-->
        </div>

        <div class="btn">
          <a href="/Stats/ScoreBoard">
            查看更多>>
          </a>
        </div>
        <!-- <div class="ad">
          <img src="../../assets/images/04.jpg" alt="">
         <Advertising></Advertising>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
  import Advertising from "../Advertising";
  import pgaApi from "../../request/pgaApi";
  export default {
    name: "rankSchedule",
    components:{
      Advertising
    },
    data(){
      return {
        fedexList:[
        ]
      }
    },
    created() {
      this.getList()
    },
    methods:{
      async getList(){
        let data = await pgaApi({
          url:'/getPgaSta',
          data:{
            year:2023,
            staId:'02671'
          }
        })
        if(data.sta.length>0){
          let _a = data.sta.slice(0,5)
          let _b = data.sta.slice(5,10)
          for (let i = 0; i <= 4 ; i++){
             _a[i].curRank2 =  _b[i].curRank
             _a[i].player_name2 =  _b[i].player_name
             _a[i].e_name2 =  _b[i].e_name
          }
          this.fedexList = _a;
        }else{
          this.fedexList = []
        }
      }
    }
  }
</script>

<style scoped lang="less">
  .rankSchedule{
    width: 100%;
    height: 100%;
  }
  .outside{
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #011d35;
    color: #ffffff;
    .title{
      padding: 10px 10px 10px 10px;
      width: 100%;
      font-size:20px;
      letter-spacing: 1px;
      span{
        //font-family: My_Bold;

      }
    }
    .content{
      padding: 0 10px;
      table{
        /*width: 150px;*/
        padding-bottom: 10px;
        font-size: 14px;
        border-collapse:collapse;
        border-bottom: 1px solid #25415b;
        thead{
          margin: 0;
          padding: 0;
          background-color: #0e2e4b ;
          th{
            padding: 3px;
          }
          th:nth-child(2){
            text-align: left;
            /*width: 119px;*/
            border-right: 1px solid #25415b;
            border-left: 1px solid #25415b;
            padding-left: 10px;
          }
          th:nth-child(1),th:nth-child(3){
            width: 30px;
          }


        }
        tbody{
          tr{

            td{
              padding: 0 0 0 1px;
              text-align: center;
              line-height: 20px;
              height: 20px;
            }
            td:nth-child(2){
              /*overflow: hidden;*/
              text-align: left;
              border-right: 1px solid #25415b;
              border-left: 1px solid #25415b;;
              padding-left: 5px;
              position: relative;
            }
            td:nth-child(4){
              text-align: left;
              border-right: 1px solid #25415b;
              border-left: 1px solid #25415b;;
              padding-left: 5px;
            }
            td:nth-child(3){
              border-right: 1px solid #25415b;
              position: relative;
            }
          }
          ._father{
            position: relative;
            /*padding-bottom: 0;*/
            /*height: 15px;*/
            /*.plaer_name{*/
            /*  width: 100px;*/
            /*  font-size: 10px;*/
            /*  position: absolute;*/
            /*  !*margin-left: -10px;*!*/
            /*  top: -14px;*/
            /*  left: -4px;*/
            /*  // font-family: My_Thin;*/
            /*  font-weight: 600;*/
            /*  transform: scale(0.9,0.9);*/
            /*}*/
            .plaer_name1{
              top: -4px;
              left: -9px;
              /*height: 9px;*/
              position: relative;
              transform: scale(0.83,0.83);

            }
          }

        }
      }
    }
    .btn{
      display: flex;
      align-items: center;
      justify-content: center;
      /*border: 1px solid red;*/
      width: 100%;
      margin-top: 10px;
      a{
        border-radius: 2px;
        width: 100%;
        text-decoration: none;
        background-color: #183d5c;
        color: #ffffff;
        font-size: 8px;
        text-align: center;
        line-height: 35px;
      }
    }
    .ad{
      /*padding:6px;*/
      padding-left: 6px;
      position: absolute;
      bottom: 10px;
      width: 260px;
      height: 30%;
      /*border: 1px solid red;*/
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .bg{
    background-color: #0e2e4b;
  }
</style>
