<template>
<!--联邦快递杯-->
  <div class="rankFedexcup">
    <div class="outside">
      <div class="title">
        <span>联邦快递杯</span>积分榜
      </div>
      <div class="content">
        <table  >
          <thead>
            <th>排名</th>
            <th>领先者</th>
            <th>积分</th>
          </thead>
          <tbody>
<!--            <tr :class="{'bg':index%2}" v-for="(item,index) in fedexList " :key="index">-->
            <tr :class="{'bg':index%2}" v-for="(item,index) in fedexList " :key="index">
              <td>{{item.curRank===undefined?'':item.curRank}}</td>
              <td>
                <p>{{item.player_name}}</p>
                <p class="span_En">{{item.e_name}} </p>
              </td>
              <td>{{item.statValues.statValue1}}</td>
<!--              <td></td>-->
<!--              <td></td>-->
<!--              <td></td>-->
            </tr>
          </tbody>
        </table>
        <div class="btn">
          <a href="/Stats/ScoreBoard">
            查看更多>>
          </a>
        </div>
        <div class="ad">
         <Advert :location="'player'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import pgaApi from "../../request/pgaApi";
  import Advert from "../Advert";
  export default {
    name: "rankFedexcup",
    components:{
      Advert
    },
    data(){
      return {
        fedexList:[]
      }
    },
    created() {
      this.getList()
    },
    methods:{
      async getList(){
        let data = await pgaApi({
          url:'/getPgaSta',
          data:{
            year:2023,
            staId:'02671',
          }
        })
        console.log("data",data.sta);
        if(data.sta.length>0){
           this.fedexList = data.sta.slice(0,5)
           console.log("fez",this.fedexList);
        }else{
          this.fedexList = []
        }
        
        
      },
    
    }

  }
</script>

<style scoped lang="less">
  .rankFedexcup{
    width: 100%;
    height: 100%;
  }
  .outside{
    position: relative;
    height: 100%;
    width: 100%;
    background-color: #011D35;
    color: #ffffff;
    .title{
      padding: 10px 10px 10px 10px;
      width: 100%;
      font-size:20px;
      letter-spacing: 1px;
      span{
        //font-family: My_Bold;

      }
    }
    .content{
      padding: 0 10px;
      table{
        width: 100%;
        padding-bottom: 10px;
        border-collapse:collapse;
        font-size: 14px;
        thead{
          background-color: #0F2E4B ;
          th{
            padding: 3px;
          }
          th:nth-child(2){
            text-align: left;
            border-right: 1px solid #25415b;
            border-left: 1px solid #25415b;
            padding-left: 10px;
          }
          th:nth-child(1){
            width: 15%;
          }
          th:nth-child(3){
            width: 28%;
          }
        }
        tbody{
          tr{

            td{
              padding: 3px;
              text-align: center;
              line-height: 20px;
              height: 20px;
            }
            td:nth-child(2){
              text-align: left;
              border-right: 1px solid #25415b;
              border-left: 1px solid #25415b;
              padding-left: 10px;
            }
            .span_En{
              text-align: left;
              // font-family: My_Thin;
              /*font-size: 2px;*/
              position: relative;
              left: -12px;
              margin-top: -5px;
              transform:scale(0.833, 0.833)
            }
          }
        }
      }
    }
    .btn{
      display: flex;
      align-items: center;
      justify-content: center;
      /*border: 1px solid red;*/
      margin-top: 10px;
      width: 100%;
      a{
        border-radius: 2px;
        width: 100%;
        text-decoration: none;
        background-color: #183d5c;
        color: #ffffff;
        font-size: 8px;
        text-align: center;
        line-height: 35px;
      }
    }
    .ad{
      /*padding:6px;*/
      padding-left: 6px;
      position: absolute;
      bottom: 1%;
      width: 260px;
      /*border: 1px solid red;*/
      img{
        width: 100%;
        height: 100%;
      }
    }
  }

  .bg{
    background-color: #0F2E4B;
  }
</style>
