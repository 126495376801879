<template>
  <div class="ShotTradker" >
    <div class="round">
      <div class="round_left">
        <div class="round_nav">
          <p>轮次</p>
          <button  v-for="(roundItem, roundIndex) in 4" :key="roundIndex"
                  class="round_btn"
                  :class="{'round_btn_active':activeIndex===roundIndex}"
                  @click="changeNav(roundIndex)" >
                  {{roundIndex+1}}
          </button>
<!--          <button class="round_btn " :class="{'round_btn_active':activeIndex===2}" @click="changeNav(2)">2</button>-->
<!--          <button class="round_btn " :class="{'round_btn_active':activeIndex===3}" @click="changeNav(3)">3</button>-->
<!--          <button class="round_btn " :class="{'round_btn_active':activeIndex===4}" @click="changeNav(4)">4</button>-->
        </div>
       <!-- <div class="hotelDetail">
          <span>{{rndnum}} 号洞  </span>
          <span>{{par}} 杆洞， </span>
          <span>{{yardage}} 码，</span> -->
<!--          <span>排名：T3</span>-->
        <!-- </div> -->
      </div>
      <div class="round_right">
      </div>
    </div>
    <!--表格信息-->
    <table >
      <thead>
        <tr>
          <th class="col01">
            球洞
            <!-- <p class="tr_shadow"></p> -->
          </th>
          <th class="col02" :class="{'colBg':activeCol===index}" v-for="(item,index) of hotesItem" :key="index" @click="changeCol(index)">
            <p class="top_on_active iconfont icon-shangjiantou " v-show="activeCol===index" >  </p>
            <p class="pp">{{item}}</p>
            <!-- <p class="tr_shadow"></p> -->
          </th>  
          <th class="col04">
            本轮成绩
            <!-- <p class="tr_shadow3"></p> -->
          </th>
          <!-- <th class="col05">
            <button class="btn_th iconfont icon-rightJiantou" v-show="btn_index" @click="changePre">  </button>
            <button class="btn_th iconfont icon-zuojiantou1" v-show="!btn_index"  @click="changePre">  </button>
          </th> -->
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="col01">标准杆</td>
          <td class="col02" :class="{'colBg':activeCol===index,'bold':item.withoutStyle}" v-for="(item,index) in cardHoles" :key="index"  @click="changeCol(index)">
            <p>{{(item.par)?(item.par):'-'}}</p>
          </td>
          <!-- <td class="col03">
            <span v-show="btn_index" >{{inStatic.par}}</span>
            <span v-show="!btn_index" >{{outStatic.par}}</span>
          </td> -->
          <td class="col04 bold">
            {{Static.par}}
          </td>
        
        </tr>
        <tr class="roundNum">
          <td class="col01">第{{activeIndexTitle}}轮</td>
          <td class="col02" :class="{'colBg':activeCol===index}" v-for="(item,index) in cardHoles" :key="index">
            <div class="_shell">
              <div class=" _shell_child" 
              :class="{
                '_out_round':!item.withoutStyle && (item.strokes-item.par)<=-1&&item.strokes!==0,
                '_out_square':!item.withoutStyle && (item.strokes-item.par)>=1&&item.strokes!==0,
                'bold':item.withoutStyle}"
                >
                <div 
                :class="{
                  '_in_square':!item.withoutStyle && (item.strokes-item.par)>=2&&item.strokes!==0, 
                  '_in_round':!item.withoutStyle && (item.strokes-item.par)<=-2&&item.strokes!==0,
                  'bold':item.withoutStyle}"
                  >
<!--                  {{(item.strokes-item.par)?(item.strokes-item.par):0}}-->
                  {{(item.strokes)?(item.strokes):'-'}}
                </div>
              </div>
            </div>
          </td>
          <!-- <td class="col03">
            <span v-show="btn_index" >{{inStatic.strokes}}</span>
            <span v-show="!btn_index" >{{outStatic.strokes}}</span>
          </td> -->
          <td class="col04 bold" >
            <!-- {{inStatic.strokes+outStatic.strokes}} -->
            {{Static.strokes}}
          </td>
          <!-- <th class="col05">
          </th> -->
        </tr>
        <tr>
          <td class="col01">杆差</td>
          <td class="col02" :class="{'colBg':activeCol===index,'bold':item.withoutStyle}" v-for="(item,index) of cardHoles" :key="index"  @click="changeCol(index)">
            <p>{{(item.status)?(item.status):'-'}}</p>
          </td>
          <td class="col03 bold" >
            {{total}}
          </td>
          <!-- <td class="col04" >
            {{total}}
          </td> -->
          <!-- <th class="col05">
          </th> -->
        </tr>
      </tbody>

    </table>
    <!--备注栏-->
    <div class="explain">
      <div class="_shell">
        <div class=" _shell_child _out_round" >
          <div class=" _in_round">
          </div>
        </div>
        <p>老鹰或者更佳</p>
      </div>
      <div class="_shell">
        <div class=" _shell_child _out_round" >
          <div >
          </div>
        </div>
        <p>小鸟</p>
      </div>
      <div class="_shell">
        <div class=" _shell_child _out_square" >
          
        </div>
        <p>柏忌</p>
      </div>
      <div class="_shell">
        <div class=" _shell_child _out_square" >
          <div class=" _in_square">
          </div>
        </div>
        <p>双柏忌+</p>
      </div>
    </div>
  </div>
</template>

<script>
  import pgaApi from "../../../request/pgaApi";

  export default {
    name: "ShotTradker",
    props:{
      playerid:{
        type:String,
        default:'0'
      }
    },
    data() {
      return {
        cardData: null,//积分卡数据
        cardHoles: [],//球洞数据
        round_total_strokes: [],//轮次
        activeIndex: 0,
        Static:{
          par:0,
          strokes:0,
          status:0
        },//杆数
        // outStatic:{
        //   par:0,
        //   strokes:0,
        //   status:0
        // },//后9轮杆数
        yardage:0,  //该洞的马力
        rndnum:0, //几号洞
        par:0,  // 该洞的标准杆数
        hotes:[
          [1,2,3,4,5,6,7,8,9]
          [10,11,12,13,14,15,16,17,18]
        ],
        hotesItem:[1,2,3,4,5,6,7,8,9,'OUT',10,11,12,13,14,15,16,17,18,'IN'],
        hotelindex:0,//球洞显示列表索引
        btn_index:true, //索引
        activeIndexTitle:'一',//第几轮
        activeCol:0,
        playerId:0,
        total:0, //总成绩
        leaderValue:{
          hole:'',
          par:'',
          yardage:''
        }
      }
    },
    created() {
      this.changeNav(this.activeIndex);
    },
    methods: {
      /**
       * 请求积分卡数据
       * */
     async getCard(playerId){
       if (this.playerid!=0){
         playerId = this.playerid
         this.playerId = playerId
       }
        this.yardage = 0
        this.rndnum = 0
        this.par = 0
        let _this = this
        if (playerId){
          console.log("playerId",playerId);
          let list = await pgaApi({
            url:'/getScoreCard',
            data:{
              playerId: playerId,
              roundNumber: _this.activeIndex+1
            }
          })
        
          console.log("list---------------",list);          
          if (list&&list!==-1){
            _this.cardData = list
            _this.round_total_strokes = list[0].round_total_strokes //轮次
            // _this.cardHoles =  list[0].holes // 球洞数据
            let holes = list[0].holes
            if (holes[0].hole_number!=1) {
                let card1=[]
                let card2 = []
                let OUT = {
                  par:0,
                  strokes:0,
                  status:"-"
                }
                let IN ={
                  par:0,
                  strokes:0,
                  status:"-"                  
                }                
                card1 = holes.slice(0,9)//10-18
                card2 = holes.slice(9,18)//1-9
                for(let item of card2){
                   OUT.par += item.par
                   OUT.strokes +=item.strokes
                   OUT.withoutStyle = true
                }
                for(let item of card1){
                   IN.par += item.par
                   IN.strokes +=item.strokes
                   IN.withoutStyle = true
                } 
                card1.push(IN)
                card2.push(OUT)               
                _this.cardHoles = card2.concat(card1)
                _this.statisticsAll(_this.cardHoles)
            }else{
                let card1=[]
                let card2 = []
                let OUT = {
                  par:0,
                  strokes:0,
                  status:"-"
                }
                let IN ={
                  par:0,
                  strokes:0,
                  status:"-"                  
                }
                card1 = holes.slice(0,9)
                card2 = holes.slice(9,18)
                for(let item of card1){
                  console.log(item);
                   OUT.par += item.par
                   OUT.strokes +=item.strokes
                   OUT.withoutStyle = true
                }
                for(let item of card2){
                  console.log(item);
                   IN.par += item.par
                   IN.strokes +=item.strokes
                   IN.withoutStyle = true
                }
                card1.push(OUT)                          
                card2.push(IN)                       
                _this.cardHoles = card1.concat(card2)
                console.log(_this.cardHoles);
                _this.statisticsAll(_this.cardHoles)
            }
            // if (_this.cardHoles[0].hole_number!=1){
            //   _this.statisticsAll(list[0].holes, 9)
            //   _this.btn_index = false
            //   // _this.hotesItem = [10,11,12,13,14,15,16,17,18]
            // }else {
            //   _this.statisticsAll(list[0].holes, 1)
            // }
            _this.changeCol(0)
            _this.$forceUpdate()
          }else{
            // _this.statisticsAll([], 0)
            console.log("111");
            _this.cardData = null
            _this.Static = {
              par:'-',
              strokes:'-'
            }
            _this.round_total_strokes = []
            _this.total = '-'
            _this.cardHoles = []
            for (let index = 0; index < 20; index++) {
              _this.cardHoles.push({par:'-',strokes:'-',status:'-'}) 
            }
            
          }
        }else{
          // console.log('无球员ID')
        }


      },
      /**
       * 统计前/后9成绩
       * */
      statisticsAll(cardHoles){
        this.Static.par = 0
        this.Static.strokes = 0
        console.log(cardHoles);
        this.Static.par = cardHoles[9].par + cardHoles[19].par
        this.Static.strokes = cardHoles[9].strokes + cardHoles[19].strokes
        this.total = (this.Static.strokes)-(this.Static.par)
        if (this.total>0){
          this.total = '+'+this.total
        }else if (this.total===0){
          this.total = 'E'
        }
       
        // for(let item of cardHoles){
        //   this.Static.par += item.par
        //   this.Static.strokes += item.strokes
        // }    
        // this.total =this.cardHoles[18].status
        // this.inStatic.par = 0
        // this.inStatic.strokes = 0
        // this.outStatic.par = 0
        // this.outStatic.strokes = 0
        // let card1 = []
        // let card2 = []
        // if (rnd !== 0){
        //    card1 = this.cardData[0].holes.slice(0,9)
        //    card2 = this.cardData[0].holes.slice(9,18)
        // }

        // if (rnd === 1){
        //   for (let item of card1){
        //     this.inStatic.par += item.par
        //     this.inStatic.strokes += item.strokes
        //     // this.inStatic.status += item.status
        //   }
        //   for (let item of card2){
        //     this.outStatic.par += item.par
        //     this.outStatic.strokes += item.strokes
        //     // this.inStatic.status += item.status
        //   }
        // }else if (rnd === 9){
        //   for (let item of card2){
        //     this.inStatic.par += item.par
        //     this.inStatic.strokes += item.strokes
        //     // this.inStatic.status += item.status
        //   }
        //   for (let item of card1){
        //     this.outStatic.par += item.par
        //     this.outStatic.strokes += item.strokes
        //     // this.inStatic.status += item.status
        //   }
        // }

      },
      /**
       * 轮次切换事件
       * @param i  索引值
       * **/
      changeNav(i){
        this.activeIndex = i
        this.getCard(this.playerId)
        if (i === 0){
          this.activeIndexTitle = '一'
        }else if (i === 1){
          this.activeIndexTitle = '二'
        }else if (i === 2){
          this.activeIndexTitle = '三'
        }else if (i === 3){
          this.activeIndexTitle = '四'
        }
      },
      /**
       * 前9论和后9论切换事件
       * **/
      changePre(){
        this.btn_index = !this.btn_index
        if (this.btn_index){
          this.hotesItem =  [1,2,3,4,5,6,7,8,9]
          this.cardHoles = this.cardData[0].holes.slice(0,9)
          // for (let i in )
        }else {
          this.hotesItem = [10,11,12,13,14,15,16,17,18]
          this.cardHoles = this.cardData[0].holes.slice(9,18)
        }
      },
      /**
       * 选中背景变黑
       * */
      changeCol(i){
        this.activeCol = i
        this.rndnum = this.cardHoles[i].hole_number
        this.par = this.cardHoles[i].par
        this.leaderValue.hole = this.cardHoles[i].hole_number
        this.leaderValue.yardage = this.cardHoles[i].yardage
        this.leaderValue.par = this.cardHoles[i].par
        this.$emit('getValue',this.leaderValue)
      },


    }
  }
</script>

<style scoped lang="less">
  .ShotTradker{
    width: 779px;
    .round{
      padding: 10px;
      background-color: #424242;
      height: 45px;
      .round_left{
        height: 145px;
        width: 240px;
         /*border-right: 1px solid red;*/
        .round_nav{
          display: flex;
          justify-content: left;
          font-size: 12px;
          width: 100%;
          text-align: center;
          p{
            margin-right: 20px;
            //font-family: My_Bold;
            height: 25px;
            line-height: 25px;

          }
          .round_btn{
            width: 25px;
            height: 25px;
            cursor: pointer;
            outline: none;
            background-color: transparent;
            color: #909090;
            border: 1px solid #909090;
            margin-right: 8px;
            padding: 2px 8px;
            border-radius: 50%;
          }
          .round_btn_active{
            color: #ffffff;
            border: 1px solid #ffffff;
          }

        }
        .hotelDetail{
          width: 100%;
          display: flex;
          justify-content: left;
          span{
            font-size: 12px;
            font-width: 900;;
            //font-family: My_Medium;
            margin-right: 10px;
            margin-top: 10px;
          }
        }
      }
      .round_right{
        height: 145px;
        width: 519px;
      }
    }
    table{
      width: 779px;
      align-items: center;
      font-size: 12px;
      border-spacing: 0;/*把单元格间隙设置为0*/
        th,td{
          position: relative;
          height: 30px;
          line-height: 30px;
          border-right: 2px solid #454545;
          border-bottom: 2px solid #454545;
          border-top: 0 solid #454545;
          /*border: 0;*/
        }
        thead{
          width: 100%;
        }
        .col01{
          width: 100px;
          text-align: left;
          padding-left: 5px;
          font-weight: bold;
        }
        .col02{
          width: 55px;
          font-size: 12px;
          // font-family: My_Thin;
          cursor: pointer;
          position: relative;
          color: #e4e4e4;
          text-align: center;
          .top_on_active{
            position: absolute;
            width: 5px;
            height: 5px;
            top: -7px;
            left: 50%;
            margin-left: -5.5px;
            transform: scale(0.8);
            line-height: 30px;
            font-size: 12px;
            color: #e4e4e4;
          }
          .pp{
            position: relative;
            height: 100%;
          }
        }
        .col03{
          text-align: center;
          width: 60px;
          color: #e4e4e4;
        }
        .col04{
          text-align: center;
          width: 120px;
          border-right: 0px;
          // font-family: My_Thin;
          color: #e4e4e4;
        }
      .col05{
        border-right: none;
        text-align: left;
        width: 20px;
        .btn_th{
        font-size: 12px;
        position: absolute;
        padding: 0;
        width: 25px;
        /*right: -5px;*/
        top: -2px;
        height: 32px;
        outline: none;
        background-color: transparent;
        color: #ffffff;
        //font-family: My_Bold;
        background-image: linear-gradient(#4d4d4d,#333333);
      }
      }
      .tr_shadow,.tr_shadow2,.tr_shadow3{
        position: absolute;
        height: 10px;
        width: 98.5%;
        bottom: 0;

        background-image: linear-gradient(#333333,#262626);
      }
      .tr_shadow2{
        width: 100%;
      }
      .tr_shadow3{
        width: 80px;
      }
      .tr_shadow:nth-child(1){
        position: absolute;
        width: 110px;
        left: -5px;
        bottom: 0;
      }
      .roundNum{
        background-color: #424242;
        position: relative;
        .col02{
          /*display: flex;*/
          /*justify-content: center;*/
          /*align-items: center;*/
        }
        .col04{
          /*border-right: 1px solid #ffffff!important;*/
        }
        .col05{
          position: absolute;
          right: 20px;
        }
      }
      ._shell{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        ._shell_child{
          display: flex;
          justify-content: center;
          align-items: center;
        }
        ._out_square,._out_round{
          width: 21px;
          height: 21px;
          line-height: 23px;
          border: 1px solid rgba(255,255,255,0.8);
          text-align: center;
        }
        ._out_round{
          border-radius: 50%;
        }
        ._in_round,._in_square{
          width: 16px;
          height: 16px;
          line-height:16px;
          border: 1px solid rgba(255,255,255,0.8);
          text-align: center;
          border-radius: 50%;
        }
        ._in_square{
          border-radius: 0;
        }
      }

      }
    .explain{
      width: 779px;
      display: flex;
      padding: 10px;
      position: relative;
      left: -10px;
      ._shell{
        display: flex;
        margin-right: 20px;
        ._shell_child{
          display: flex;
          justify-content: center;
          align-items: center;
        }
        ._out_square,._out_round{
          width: 10px;
          height: 10px;
          border: 1px solid rgba(255,255,255,0.8);
          text-align: center;
        }
        ._out_round{
          border-radius: 50%;
        }
        ._in_round,._in_square{
          width: 6px;
          height: 6px;
          border: 1px solid rgba(255,255,255,0.8);
          text-align: center;
          border-radius: 50%;
        }
        ._in_square{
          border-radius: 0;
        }
        p{
          margin-left: 8px;
          position: relative;
          top: -3.5px;
          font-size: 12px;
        }

      }
    }
  }
  .colBg{
    background-color: #1c1c1c;
    .tr_shadow{
      display: none
    }
  }
  .bold{
    font-weight: bolder;
  }
</style>