<template>
<div class="FedexcupList">
  <div class="shell">
    <div class="shell_content">
      <div class="backTop" @click="gobackTop()">
        <p class="iconfont icon-huidaodingbu"></p>
        <p>回到顶部</p>
      </div>
      <div class="top">
        <span>2021 年联邦快递杯赛季积分榜</span>
      </div>
      <div class="list">
<!--        <h6>年初至今的统计数据：联邦快递杯 2021年 1 月 10日的积分榜</h6>-->
        <table>
          <thead>
            <tr>
              <th class="list01">本周排名</th>
<!--              <th class="list01">上周排名</th>-->
              <th class="list02">球员名称</th>
              <th class="list01">参赛次数</th>
              <th class="list01">积分</th>
              <th>夺冠次数</th>
              <th>前十次数</th>
              <th>落后领先者积分</th>
<!--              <th>重置积分</th>-->
            </tr>
          </thead>
          <tbody>
            <template v-for="(item ,index) in list">
              <tr :class="{'bg':index%2===0}" >
                <td>{{item.curRank}}</td>
<!--                <td></td>-->
                <td>{{item.player_name}}<span class="plaer_nameEn">{{item.e_name}}</span></td>
                <td>{{item.statValues.rndEvents}}</td>
                <td>{{item.statValues.statValue1.length>0?item.statValues.statValue1:'--'}}</td>
                <td>{{item.statValues.statValue2.length>0?item.statValues.statValue2:'--'}}</td>
                <td>{{item.statValues.statValue3.length>0?item.statValues.statValue3:'--'}}</td>
                <td>{{item.statValues.statValue4.length>0?item.statValues.statValue4:'--'}}</td>
<!--                <td>{{ item.statValues.statValue5.length>0?item.statValues.statValue5:'&#45;&#45;'}}</td>-->
              </tr>
              <tr v-if="index===9">
                <td class="space" colspan="9">积分榜 10 强</td>
              </tr>
              <tr v-if="index===29">
                <td class="space2" colspan="9">积分榜 30 强：获得巡回锦标赛资格</td>
              </tr>
            </template>



          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
</template>

<script>
  import pgaApi from "../../request/pgaApi";

  export default {
    name: "FedexcupList",
    data(){
      return{
        list:[]
      }
    },
    created() {
      this.getList()
    },
    methods:{
      gobackTop(){
        let pageXOffsetNum =  window.pageYOffset // 获取右边滚动条距离顶部的距离
        let task = setInterval(function (){  // setInterval循环周期调用
            if (pageXOffsetNum > 0){
                window.scrollTo(0, pageXOffsetNum);
                pageXOffsetNum -=20 // 相比上次位置减10，根据自己需要的速度修改
            }else {
                window.scrollTo(0,0);
                clearInterval(task)  //回到顶部后，清除已执行的的循环事件
            }
        },1)
        // window.scrollTo(0,0)
        // let scroll = window.pageXOffset
        // let tack = setInterval({
        //   if (scroll > 0) {
        //     window.scrollTo(0, scroll)
        //     scroll--
        //   }
        // },1)
      },
      async getList(){
        let data = await pgaApi({
          url:'/getPgaSta',
          data:{
            year:2023,
            staId:'02671'
          }
        })
        this.list = data
        // console.log(this.list);
      }
    }

  }
</script>

<style scoped lang="less">
  .backTop{
    position: fixed;
    bottom: 50px;
    right: -30px;
    font-size: 17px;

    p:nth-child(1){
      text-align: center;
      font-size: 35px;
      display: none;
    }

    p:nth-child(2){
      font-size: 10px;
      width: 15px;
      background-color:#01407e;
      opacity: 0.6;
      color: #ffffff;
      padding: 15px 30px 15px 10px;
      border-bottom-left-radius: 100%;
      border-top-left-radius: 100%;
    }
  }
  .backTop:hover{
    cursor: pointer;
    right: 0;
    p:nth-child(1){
      text-align: center;
      font-size: 35px;
      display: block;
    }
    p:nth-child(2){
      padding: 0 0 20px 0;
      font-size: 10px;
      width: 50px;
      color: #000000;
      background-color:rgba(0,0,0,0);
    }
  }
  .FedexcupList{

    .top{
      padding: 20px 0 20px 10px;
      font-size: 25px;
      /*font-weight: 600;*/
      letter-spacing: 2px;
      span{
        //font-family: My_Bold;
      }


    }
    .list{
      padding-bottom: 50px;
      h6{
        padding-left: 10px;
        font-weight: 500;
      }
      table{
        padding-top: 2px;

        font-size: 14px;
        width: 100%;
        border-collapse:collapse;
        text-align: center;
        th,td{
          border: 1px solid #b3b3b3;
          font-weight: 600;
          padding: 10px 20px 10px 20px;

        }
        tr{
          /*height: 55px;*/
        }
        thead{
          background-image: linear-gradient(#ffffff 10px,#eeeeee);
          .list01{
            width: 100px;
            font-size: 12px;
          }
          .list02{
            width: 400px;
          }
          th:nth-child(2){
            text-align: left;
            padding-left: 30px;
          }

        }
        tbody{

          td{
            font-weight: 600;
          }
          td:nth-child(1){
            //font-family: My_Bold;
          }
          td:nth-child(2){
            text-align: left;
            padding-left: 30px;
          }
          .space,.space2{
            background-color: #0274e3;
            padding: 5px 0 5px 0;
            color: #ffffff;
            font-size: 20px;
          }

          .space2{
            background-color: #8645b9;
          }

        }
      }
    }
  }
  .bg{
    background-color: #eeeeee;
  }
  .plaer_nameEn{
    color: #333333;
    font-weight: 600;
    margin-left: 10px;
  }
</style>
