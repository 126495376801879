<template>
<div id="hole-map" >
  <!-- 球场图片 -->
  <!-- <div class="hole-ground">
    <img :src="ground" alt="">
    <div class="shadow"></div>
    <div class="hole-ground-info">
      <div class="hole-ground-info-name">农夫保险高尔夫公开赛</div>
      <div class="hole-ground-info-date">2022年1月26日至29日加利福尼亚州圣地亚哥托里松树（南部）</div>
      <div class="hole-ground-info-round">第一轮</div>
    </div>
  </div> -->
  <div class="hole-map">
    <div class="carousel-container">
      <div class="carousel-title" style="display: contents;">
        <span>球洞</span>
      </div>
      <div class="carousel-content ">
        <div class="slider carousel-list">
          <div class="slider-frame pr">
            <div class="btn_th iconfont icon-zuojiantou1 pa pa-l" :class="searchId==2?'cps':''" @click="changePre(1)"></div>
            <ul class="slider-list df-sa">
              <!-- <li class="slider-slide slide-visible">
                <div class="carousel-item">
                  <span class="hole empty">
                    3 
                  </span>
                </div>
              </li> -->
              <li class="slider-slide slide-visible" v-for="(item,index) of hotesItem" :key="index">
                <!-- <a :href="'#' + index" class="dib"> -->
                  <div class="carousel-item"  @click="goRegion(item)">
                    <span class="hole empty" :class="item==searchIndex ? 'selected' : ''">
                      {{item}}   
                    </span>
                  </div>
                <!-- </a> -->
              </li>
            </ul>
            <div class="btn_th iconfont icon-rightJiantou pa pa-r" :class="searchId==1?'cps':''" @click="changePre(2)"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="course-overview-body">
    <div ref="viewBox">
      <div class="hole-li" v-for="(item,index) of holeList" :key="index" :class="searchIndex==index?'pt56':''" >
        <div class="dark_anchor" :id="'hole'+item.hole"></div>
        <!-- <div class="h52" v-if="index!=0"></div>
        <div class="h24"></div> -->
        <div class="hole-title">
            <div class="hole-number">
            第 {{item.hole}} 洞
            </div>
          <div class="hole-details">
            <span class="tbefore">标准杆 {{item.par}}</span>
            <span class="tbefore">初始码数 {{item.yard}}</span>
            <!-- <span class="tbefore">排名 {{item.d}}</span> -->
          </div>
        </div>
        <div class="hole-container">
          <!-- <img data-testid="course-overview-hole-image" class="hole-image" alt="" src="https://res.cloudinary.com/pga-tour/image/upload/c_fit,d_holes_2021_r_034_503_overhead_full_1.jpg,f_auto,h_164,q_auto/v1/pgatour/courses/r034/503/tourcast/hole01.jpg"/> -->
          <img data-testid="course-overview-hole-image" class="hole-image" alt="" :src="item.holePic" />
          <!-- <div>
            <span>标准杆 {{item.par}}</span>
          </div>
          <div>
            <span>码数 {{item.yard}}</span>
          </div>           -->
        </div>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
  import { getHole } from '@/api/leaderboard/leaderboard'
  import ground from '../../../assets/images/ground.jpg'
  export default {
    name: "hole-map",
    data() {
      return {
        ground,
        hotesItem:[1,2,3,4,5,6,7,8,9],
        list:[
          {a:'1',b:'4',c:'44',d:'11'},
          {a:'2',b:'2',c:'434',d:'12'},
          {a:'3',b:'3',c:'414',d:'12'},
          {a:'4',b:'4',c:'414',d:'1'},
          {a:'5',b:'5',c:'434',d:'2'},
          {a:'6',b:'6',c:'464',d:'3'},
          {a:'7',b:'7',c:'474',d:'4'},
          {a:'8',b:'8',c:'484',d:'5'},
          {a:'9',b:'9',c:'424',d:'6'},
          {a:'10',b:'0',c:'414',d:'17'},
          {a:'11',b:'12',c:'224',d:'18'},
          {a:'12',b:'34',c:'324',d:'9'},
          {a:'13',b:'45',c:'424',d:'10'},
          {a:'14',b:'45',c:'024',d:'11'},
          {a:'15',b:'45',c:'024',d:'11'},
          {a:'16',b:'45',c:'024',d:'11'},
          {a:'17',b:'45',c:'024',d:'11'},
          {a:'18',b:'45',c:'024',d:'11'},
          ],
        searchIndex:'1',
        searchId:1,
        holeList: []
      }
    },
    mounted(){
      window.addEventListener('scroll', this.scrollToTop)
      this.getHoleList()
    },
    watch: {
      searchIndex(newName, oldName) {
        if(newName <= 9){
          this.hotesItem =  [1,2,3,4,5,6,7,8,9]
        }else{
          this.hotesItem = [10,11,12,13,14,15,16,17,18]
        }
      }
    },
    created() {
      this.$nextTick(() => {

      });
    },
    methods: {
      scrollToTop() {
        　var scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
          if(parseInt((scrollTop-41)/288)>1){
            this.searchIndex = parseInt((scrollTop-41)/288)-1
          }else{
            this.searchIndex = 1
          }

      },
      goRegion(n){
        this.searchIndex = n
        document.querySelector("#hole"+n).scrollIntoView();
      },
      /**
       * 第几轮
       * */
      handleSlectR(i){
        this.roundNum = i
        this.isSelect = false
      },
      /**
       * 前9论和后9论切换事件
       * **/
      changePre(n){
        this.searchId = n
        if (n == 1){
          this.hotesItem =  [1,2,3,4,5,6,7,8,9]
        }else {
          this.hotesItem = [10,11,12,13,14,15,16,17,18]
        }
      },
      //请求球洞图片列表
      getHoleList( ) {
        this.holeList = []
        getHole().then(res => {
          if( res.length>0 ){
            this.holeList = res
            console.log('this.holeList: ', this.holeList);
          } 
        })
      },
      
    }
  }
</script>
<style lang="less">
@import 'holemap.css';
.hole-ground {
  position: relative;
  img {
    width: 100%;
  }
  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(180deg,rgba(0,0,0,.25),rgba(0,0,0,.7));
    z-index: 1;
  }
  .hole-ground-info {
    position: absolute;
    z-index: 2;
    color: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    .hole-ground-info-name { margin-bottom: 10px; font-size: 28px; font-weight: bold;}
    .hole-ground-info-date { margin-bottom: 10px; font-size: 18px;}
    .hole-ground-info-round { font-size: 18px; font-weight: bold;}
  }
}
</style>