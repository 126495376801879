<template>
<!--  实时-->
  <div class="Realtime">
<!--    内容-->
    <div class="content">
<!--      新闻-->
      <div class="all cp" v-if="navIndex == 2">
        <div class="content_01"
             v-for="(NewsList, index) of NewsList"
             :key="index"
             @click="play_new(NewsList.articleId,'article')">
          <img :src="NewsList.articlePostUrl" alt="">
          <p class="content_text">
            {{NewsList.articleTitle}}
          </p>
        </div>
        
      </div>
<!--      视频-->
      <div class="all cp" v-if="navIndex == 1" >
        <div class="content_01"
             v-for="(videosList, index) of videosList"
             :key="index"
             @click="play_new(videosList.videoId,'video')">
          <img src="../assets/default2.png" :src="videosList.videoPostUrl" alt="">
          <svg class="icon icon_blur" aria-hidden="true">
            <use  xlink:href="#icon-bofang"></use>
          </svg>
          <p class="content_text">
            {{videosList.videoName}}
          </p>
        </div>
      </div>
      <p class="none_tip" v-show="NewsList.length == 0 && videosList.length == 0" >暂无相关内容</p>
    </div>
    <div class="get_more">
      <div class="shadow"></div>
      <div class="more cp" @click="getMore()">
        {{moreTxt}}
        <span class="iconfont icon-jiazaigengduo1"></span>
      </div>
    </div>
  </div>
</template>


<script>
  import getAxios from "../request";
  import store from '@/store'
  export default {
    name: "searchResult",
    components:{
    },
    data(){
      return{
        //  视频分类列表
        videosList:[],
        //  新闻分类列表
        NewsList:[],
        moreTxt:'加载更多',
        pageNum:1,
        navIndex:0,
        searchTxt:'',
        pageN:1,
        pageV:1,
        disabled:false

      }
    },
    created() {
      this.navIndex = this.$route.query.select
      this.searchTxt = this.$route.query.searchTxt
      if( this.$route.query.select == 1){
        this.searchDataFn(this.searchTxt,0)
      }else{
        this.findNews(this.searchTxt,0)
      }
    },
    computed: {
      searchRefresh() {
        return store.state.searchResultHasUpdate
      }
    },
    watch: {
      searchRefresh(newVal, oldVal) {
        if(newVal) {
          store.commit('setSearchResultStatus',false)
          if( this.$route.query.select == 1){
            this.searchDataFn(this.searchTxt,0)
          }else{
            this.findNews(this.searchTxt,0)
          }
        }
      },
      navIndex(n,o){
        this.navIndex = n
        if( this.navIndex == 1){
          this.searchDataFn(this.searchTxt,0)
        }else{
          this.findNews(this.searchTxt,0)
        }
      },
      searchTxt(n,o){
        this.searchTxt = n
        if( this.navIndex == 1){
          this.searchDataFn(n,0)
        }else{
          this.findNews(n,0)
        }
      },
      $route(){
        this.navIndex = this.$route.query.select
        this.searchTxt = this.$route.query.searchTxt
      },
      // '$route': {
      //   immediate: true,
      //   handler: function(to, from) {
      //     //拿到目标参数 to.query.id 去再次请求数据接口
      //     console.log('监测参数type：' + to.query.select);
      //     console.log('监测参数deviceType： ' + to.query.searchTxt);
      //     this.searchDataFn(to.query.searchTxt,1)
      //   }
      // }
    },
    methods:{
      play_new(id, type) {
        if(type == 'video') this.$router.push({ name: 'Video', query: {id: id} })
        if(type == 'article') this.$router.push({ name: 'NewsDetails', query: {id: id} })
      },
    //  请求更多数据事件
      getMore(){
        if(!this.disabled){
          if(this.navIndex == 1){
            this.searchDataFn(this.searchTxt,1)
          }else{
            this.findNews(this.searchTxt,1)
          }
        }

      },
      //新闻请求搜索内容数据
      async findNews(n,bo){
        let news = await getAxios({
          url:"/getArticleListByName",
          data:{
            articleTitle:n,
            limit:20,
            page:this.pageN
          }
        })

        if(news.length < 20){
          this.moreTxt='暂无更多数据'
          this.disabled = true
        }else{
          this.moreTxt='加载更多'
          this.disabled = false
        }
        // console.log(list.length);
        if (bo===1 && news.length>0){
          this.NewsList =this.NewsList.concat(news)
        }else if(bo ===0){
          this.NewsList = news
        }else {
          this.pageN = 1
        }

      },
      //搜索视频
      async searchDataFn(n,bo){
        let list =await getAxios({
          url:'/getVideoListByName',
          data:{
            videoName:n,
            page:this.pageNum,
            limit:20
          }
        })

        if(list.length < 20){
          this.disabled = true
          this.moreTxt='暂无更多数据'
        }else{
          this.disabled = false
          this.moreTxt='加载更多'
        }
        // console.log(list.length);
        if (bo===1 && list.length>0){
          this.videosList =this.videosList.concat(list)
        }else if(bo ===0){
          this.videosList = list
        }else {
          this.pageV = 1
        }
      },
    }
  }
</script>

<style scoped lang="less">
.Realtime{
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding-top: 25px;
  align-items: center;
  flex-direction: column;
  // background: #fff;
  margin-bottom: 60px;

}
.none_tip{
  width: 100%;
  text-align: center;
  line-height: 25px;
  // padding:0 15px 15px 15px;
  color: #777777;
  font-size: 17px;
  padding: 20px;
}
  .all{
    z-index: 11;
    /*margin: 10px 0px 0 0px;*/
    display: flex;
    flex-wrap:wrap;
    padding: 2  0px 0px 20px 0px;
    background: #ffffff;
    /*flex-direction: column-reverse;*/

    .content_01{
      position: relative;
      // border: 1px solid #eeeeee;
      padding: 15px 14px 15px 15px;
      border-bottom: 1px solid #E6E6E6;
      border-right: 1px solid #E6E6E6;
      img{
        width: 300px;
        height: 205px;
      }
      .icon_blur{
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -50px;
        margin-left: -20px;
        z-index: 3;
        width: 40px;
        height: 40px;
      }
      .content_text{
        width: 250px;
        line-height: 21px;
        font-size: 15px;
        color: #00284a;
        //font-family: My_Medium;
      }
    }
  }
  .get_more{
    background-color: #eeeeee!important;
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: relative;
    margin-bottom: 60px;
    .shadow{
      position: absolute;
      top: -15px;
      left:50%;
      margin-left: -136px;
      border-bottom-left-radius: 40px;
      border-bottom-right-radius: 40px;
      box-shadow: 0 5px 20px #000000;
      width: 260px;
      height: 40px;
      background-color: red;
    }
    .more{
     margin-left: -10px;
      z-index: 1;
      /*position: absolute;*/
      width: 250px;
      text-align: center;
      padding-left: 10px;
      font-size: 20px;
      line-height: 40px;
      background-color: #ffffff;
      //font-family: My_Bold;
     // box-shadow: 4px -15px 5px 2px #dddddd,-4px -15px 5px 2px #dddddd;
    }
    .icon-jiazaigengduo1{
      // font-family: My_Thin;
      position: relative;
      right:-40px;
      font-size: 20px;

    }
  }
  .cp{
    cursor: pointer;
  }
  .content{
    width: 1320px;
    overflow: hidden;
    display: flex;
    background: #ffffff;
    // justify-content: center;
    // align-items: center;
  }
</style>