<template>
  <div class="tournamentData">
    <table>
      <thead>
        <tr>
          <th class="tour01"></th>
          <th class="tour02">ROUND 1</th>
          <th class="tour02">ROUND 2</th>
          <th class="tour02">ROUND 3</th>
          <th class="tour02">ROUND 4</th>
          <th class="tour02">总成绩</th>
          <th class="tour03">排名 </th>
        </tr>
      </thead>
      <tbody>
      <tr v-for="name in names">
        <th class="tour01">{{name}}</th>
        <th class="tour02"> 1</th>
        <th class="tour02"> 2</th>
        <th class="tour02"> 3</th>
        <th class="tour02"> 4</th>
        <th class="tour02">总成绩</th>
        <th class="tour03">排名 </th>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  export default {
    name: "tournamentData",
    data() {
      return {
        names:[
            '老鹰','小鸟','保帕','柏忌','双柏忌','三柏忌+',
          '开球得分','进攻果岭得分','果岭周围得分','推杆得分',
          '发球台到果岭得分','综合得分','开球精确性','开球距离',
          '最远开球距离','沙坑救球','救球成功率','标准杆上果岭率',
          '标准杆上果岭后推杆次数'
        ]
      }
    },
    methods: {}
  }
</script>

<style scoped lang="less">
  table{
    width: 100%;
    font-size: 16px;
    border-spacing: 0;/*把单元格间隙设置为0,就能去掉白边*/
    thead{
      background-color: #f5f5f5;
      th{
        //font-family: My_Medium;
      }

    }

    tr{
      th{
        height: 45px;
        border-right: 1px solid #e9e9e9;
        border-bottom: 1px solid #eeeeee;
      }
    }
    tbody{
      tr:nth-child(2n){
        background-color: #fbfbfb;
      }
    }
    .tour01{
      width: 255px;
      text-align: left;
      padding-left: 15px;
    }
    .tour02{
      width: 168px;
    }
    .tour03{
      width: 80px;
    }
  }
</style>