<template>
<!--广告插件-->
  <div class="Advertising" :class="{'isclose':closeit}">
    <div class="outside">
      <a href="#">
        <img  :src="imgurl" alt="广告位" :onerror="imgURL">
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Advertising",
    props:{
      imgurl:{
        type:String
      },
      closeit:{
        type: Boolean,
        default:true
      }
    },
    data(){
      return {
        imgURL:'this.src="'+require('../assets/default2.png')+'"',
      }
    }
  }
</script>

<style scoped lang="less">
  .Advertising{
    width: 100%;
    height: 100%;
    .outside{
      width: 100%;
      height: 100%;
      /*border: 1px solid red;*/
      a{
        text-decoration: none;
        color: #ffffff;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img{
          background-color: #eeeeee;
          height: 100%;
          width: 100%;

        }
      }
    }
  }
  .isclose{
    display: none;
  }
</style>
