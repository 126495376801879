<template>
  <div class="shell">
    <!-- 出发表固定图片 -->
<!--    <div style="background: #ffffff;">-->
<!--      <img src="../../../assets/banner.jpg" alt="" style="width: 100%;">-->
<!--      <div style="font-size: 22px;text-align: center;padding: 20px 0;color: rgb(0, 40, 75);cursor: pointer;" @click="goOutside">本周赛事出发表请点击跳转美巡官网查看</div>-->
<!--    </div>-->
    <div class="shell_content2" >
      <div class="top">
        <div class="_left">
          所有开球时间均为当地时间
        </div>
        <div class="_right">
          <div class="_shell">
            <div class="_content" @click="RoundDraw">
              <span>ROUND {{RoundNum}}</span>
              <span class="iconfont icon-xiala1"></span>
            </div>
            <div class="roundList" v-show="RoundShow">
              <div v-for="(item, rd) in ListArray" :key="rd" class="_child" @click="changeRound(rd+1)"><span>ROUND {{rd+1}}</span></div>
<!--              <div class="_child" @click="changeRound(2)"><span>ROUND 2</span></div>-->
            </div>
          </div>

        </div>
      </div>
<!--      <div class="title">-->
<!--        PGA 全国 （冠军）-->
<!--      </div>-->
<!--      列表-->
      <div class="List" v-if="list.length>0">
        <div   v-for="(item01,index01) in list" :key="index01">
          <div class="list_child"
               v-for="(group, index02) in item01.group"
               :key="index02"
               :class="{'bg':(index02+index01)%2!=0}"
          >
            <div class="_time c-004b9 fb">
                <!--  {{index02}}-->
              <span>{{group.groups.TeeTime.slice(11,-3)}}</span>
              <span v-if="group.groups.TeeTime.slice(11,-6)<12">AM</span>
              <span v-else>PM </span>
            </div>
            <div class="child_shell">
              <div class="child_content"  >
                <p>TEE #{{group.hole}}</p>
                <div class="_players">
                  <div class="_player" v-for="(i,index03) in group.groups.players" :key="index03">
                    <div class="_img" >
                      <img 
                         :src="bindIcon(i.PID)"
                         :onerror="imgURL1"
                         alt="">
                    </div>
                    <div class="cell-wrapper ml20">
                        <!-- <span class="USA flag flag-24x24"></span> -->
                    </div>
                    <div class="_name ml10">
                      <p> {{i.playerName}}</p>
                      <p class="_name2 c-004b9 fb">{{i.FName}} {{i.LName}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="empty">
        <p>暂无数据</p>
      </div>
    </div>
  </div>
</template>

<script>
  import pgaApi from "../../../request/pgaApi";

  export default {
    name: "TeeTimes",
    data() {
      return {
        RoundNum:1,
      //  下拉菜单显示与隐藏标志
        RoundShow:false,
      //  默认图片路径
        imgURL1:'this.src="'+require('../../../assets/default.png')+'"',
      //全部数据
        AllList:[],
      //
        list:[],
        num:0,
        ListArray:[],
        Index:[]
      }
    },
    created() {
      this.getList()
    },
    methods: {
      goOutside() {
        window.open('https://www.pgatour.com/tournaments/us-open/tee-times.html', '__blank');
      },
      bindIcon(PID) {
        return "https://pga-tour-res.cloudinary.com/image/upload/c_fill,f_auto,g_face:center,h_160,q_auto,t_headshots_leaderboard_l,w_160/headshots_"+PID+".png"
      },  
      //Round的次数
      changeRound(_i){
        this.RoundNum = _i
        this.list = this.ListArray[_i-1].segments
        this.list.map(item=>{
            item.hole  = item.HoleSequence.split('|')
            item.group = []
        })
        for(let item of this.list){
          for (let i = 0; i < item.groups.length; i++) {
            item.group[i] = {groups:item.groups[i],hole:item.hole[0]}               
          }
        }         
        this.RoundShow = false
        // console.log(this.list );
       },
    //   下拉
      RoundDraw(){
        this.RoundShow = true
      },
      // 请求数据
      async getList(){
        let data = []
        data = await pgaApi({
          url:'/getGroup'
        })
        if (data){
          if (data.length>0){
            this.ListArray = data
            this.list = data[0].segments
            this.list.map(item=>{
               item.hole  = item.HoleSequence.split('|')
               item.group = []
            })
            for(let item of this.list){
              console.log(item.hole[0]);
              for (let i = 0; i < item.groups.length; i++) {
                      item.group[i] = {groups:item.groups[i],hole:item.hole[0]}               
              }
            }       
            console.log('this.list: ', this.list);
          }
        }else{
          console.log(data);
        }

      },

    }
  }
</script>

<style scoped lang="less">
 @import 'teetimes.css';
 .empty{
   font-size: 20px;
   height: 100px;
   padding-top: 20px;
   text-align: center;
 }
</style>