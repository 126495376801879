
 import request from '@/router/axios'

 //获取球洞图
export function getHole(data) {
     return request({
         url: '/match/hole',
         method: 'get',
         query:data
     })
 }
 
//获取赛事播出安排信息
 export function getTVTime() {
    return request({
        url: '/match/tvTime',
        method: 'get'
    })
}

//获取赛事播出安排信息
export function getFlag(query) {
    return request({
        url: '/match/getFlag?round='+query,
        method: 'get'
    })
}
//获取是否显示球洞
export function getSwitch(data) {
    return request({
        url: '/match/hole?value='+data,
        method: 'get',    
    })
}
