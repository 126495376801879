<template>
  <div class="rankHots">
    <div class="ouside">
      <div class="right_top">
        <div class="right_left">
          <span class="right_left">美巡热门</span>
        </div>
        <div class="right_right iconfont icon-remen"></div>
      </div>
        <!--新闻内容部分-->
      <div class="btm" v-if="isR">
        <div 
           href="javascript:;"
           v-for="(item, index) in newsHotsList"
           :key="index"
          >
          <div class="content" v-if="item.pgaVideo!=null" @click="getrabkHots(item.pgaVideo.videoId,'video')">
            <div  class="content_left">
              <div class="left_title">
                <h4>{{item.pgaVideo.videoName.length>28?item.pgaVideo.videoName.slice(0,28)+'. . .':item.pgaVideo.videoName}}</h4>
              </div>
            </div>
            <div class="content_right" >
              <img :src="item.pgaVideo.videoPostUrl">
              <svg  class="icon icon_blur" aria-hidden="true">
                <use  xlink:href="#icon-bofang"></use>
              </svg>
            </div>
          </div>
          <div class="content" v-else @click="getrabkHots(item.pgaArticle.articleId,'article')">
            <div  class="content_left">
              <div class="left_title">
                <h4>{{item.pgaArticle.articleTitle.length>28?item.pgaArticle.articleTitle.slice(0,28)+'. . .':item.pgaArticle.articleTitle}}</h4>
              </div>
            </div>
            <div class="content_right" >
              <img :src="item.pgaArticle.articlePostUrl">
              <svg  class="icon icon_blur" aria-hidden="true">
                <use  xlink:href="#icon-bofang"></use>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <!--            广告部分-->
      <div class="ad cp">
        <img src="../../assets/images/01.jpg" alt="">
<!--        <Advertising :imgurl="'https://pga-tour-res.cloudinary.com/image/upload/c_fill,f_auto,g_north,h_216,q_auto,w_338/v1/pgatour/editorial/2021/03/28/dahmen-stamp-1268-kevinccox.jpg'" :closeit="false"/>-->
      </div>
    </div>

  </div>
</template>

<script>
  import Advertising from "../Advertising";
  import getAxios from "../../request";
  export default {
    name: "rankHots",
    components:{
      Advertising
    },
    data(){
      return {
        newsHotsList:[
          {pgaVideo:{}}
        ],
        isR:false
      }
    },
    created() {
      this.getHots()
    },
    methods:{
      // 发送请求数据
      async getHots(){
          this.newsHotsList = await getAxios({
            url:"/getIndexHotList",
            // data:{
            //   page:1,
            //   limit:4
            // }
          })
        // this.newsHotsList = this.newsHotsList.slice(0,4)
        this.isR = true
      },
      getrabkHots(id, classfy){
        this.$emit('getrabkHots',{id, classfy})
      }
    }
  }
</script>

<style scoped lang="less">
  .rankHots{
    height: 100%;
    width: 100%;
    position: relative;
    .ouside{
      // width: 100%;
      height: 100%;
      border-top: 6px solid #053970;
      padding:15px;
      font-size: 17px;
      font-weight: bold;
      .right_top{
        /*border: 1px solid red;*/
        display: flex;
        line-height: 35px;
        .right_left{
          flex: 6;
        }
        .iconfont{
          flex: 2;
          /*float: right;*/
          font-size: 25px;
          text-align: right;
        }
      }

      .btm::-webkit-scrollbar {
        display:none;
        width:0;
        height:0;
        color:transparent;
      }
      .btm{
        // margin-right: 30px;
        height: 60%;
        padding-bottom: 15px;
        /*border: 2px solid red;*/
        overflow: auto;
        .content{
          text-decoration: none;
          color: black;
          /*border: 2px solid red;*/
          display: flex;
          padding: 15px 0 10px 0;
          border-bottom: 1px solid #cccccc;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          .content_left{
            // flex: 4;
            padding-right: 25px;
            .left_subtitle{
              font-size: 10px;
              font-weight: lighter;
            }
            .left_title{
              /*border: 1px solid red;*/
              width: 150px;
              font-weight: 500;
              font-size: 15px;
              word-break:break-all
            }
          }
          .content_right{
            // flex: 2;
            position: relative;
            img{
              width: 100px;
              height: 60px;
            }
            svg{
              position: absolute;
              z-index: 2;
              width: 26px;
              height: 26px;
              left: 50%;
              top: 50%;
              margin-top: -13px;
              margin-left: -13px;
            }
          }
        }
      }
    }

    .ad{
      /*position: absolute;*/
      margin-top: 0px;
      bottom: 15px;
      width: 316px;
      height: 170px;
      /*border: 1px solid red;*/
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
.cp{
  cursor: pointer;
}
</style>