<template>
  <div class="past-results mb20">
    <div class="past-results-container mt20">
      <div class="past-title f30 mb10">PAST <span class="bold">RESULTS • </span> Travelers Championship</div>
      <div class="past-title f20"><span class="bold">Ending: 6/28/2020 • </span>Purse: $7,400,000  •  PAR: 70  •  Total FedExCup Points: 2,989</div>
      <div class="past-title f20">Cut: 68 professionals at -4 to 136</div>
      <div class="past-title f20">Course: TPC River Highlands  •  Cromwell, CT</div>
    </div>
    <div class="past-results-notes mt20">
      <el-collapse v-model="activeNames" @change="handleChange">
        <el-collapse-item title="Weather" name="1">
          <div>Thursday: Mostly sunny. High of 87. Wind SW 8-16 mph. Friday: Partly cloudy. High of 88. Wind W 6-12 mph. Saturday: Due to the threat of inclement weather in the afternoon, third-round tee times featured threesomes off Nos. 1 and 10 from 7:15 – 9:15 a.m. Mostly cloudy with intermittent showers. High of 83. Wind SSW 6-12 mph. Sunday: Partly cloudy. High of 86. Wind SW 5-10 mph. Due to a dangerous weather situation, the final round was suspended for 58 minutes from 5:17 p.m. until 6:15 p.m.</div>
        </el-collapse-item>
        <el-collapse-item title="Tournament Notes" name="2">
          <div>Dustin Johnson won the Travelers Championship by one stroke over Kevin Streelman, earning his 21st PGA TOUR title and extending his streak of consecutive seasons with at least one win to 13. The win marked Johnson’s first since the 2019 World Golf Championships-Mexico Championship. Dustin Johnson became the fourth player to win in each of his first 13 seasons on TOUR, joining Jack Nicklaus, Arnold Palmer and Tiger Woods, and the 34th player in PGA TOUR history with at least 21 wins. With a third-round 61, Dustin Johnson recorded his first career 18-hole score of 61 or better on TOUR. He led the field in Par-4 Scoring Average (3.71). Kevin Streelman earned his second runner-up of the season with a solo-second. Streelman made birdie on both par 5s in all four rounds, recording a par-5 birdie-or-better percentage of 100 for the first time in his career. Became the first player to accomplish the feat since Rob Oppenheim at the 2020 Sony Open in Hawaii. Playing on a sponsor exemption, Will Gordon finished T3 to qualify for Special Temporary Membership for the remainder of the season. He shot a second-round 62, his first career sub-65 score on TOUR, and led the field in Birdies with 27. Mackenzie Hughes shot a first-round 60, his first career score of 60 or better, en route to a T3. Hughes missed a 40-foot, 8-inch putt at his 18th hole (No. 9) in his attempt to shoot the 12th sub-60 score in PGA TOUR history. Two-time Travelers Championship winner Phil Mickelson (T24) held the 36-hole lead after opening with scores of 64-63. Mickelson had never previously recorded a score of 64 or better in each of the first two rounds on TOUR. Defending champion Chez Reavie finished T46.</div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <el-row class="controls mt20">
      <el-col :span="12" class="dis-f">
        <el-select v-model="years" >
          <el-option
            v-for="item in yearssOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="round" class="pl10">
          <el-option
            v-for="item in roundOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
        <span class="switch pl20">
          <span class="text1">To Par</span>
          <el-switch
            v-model="switchVal"
            active-color="#f33d36"
            inactive-color="#f33d36">
          </el-switch>
           <span class="text2">Total Score</span>
        </span>
      </el-col>
      <el-col :span="12" class="tr">
        <div class="controls-right ">
          <span onclick="javascript:(print());">Print <i class="el-icon-printer"></i></span>
        </div>
      </el-col>
    </el-row>
    <div class="mt20">
      <table class="table-styled" cellpadding="0" cellspacing="0" data-display-rounds="4">
        <thead>
          <tr>
            <th rowspan="2" class="cell" width="30%">PLAYER</th>
            <th class="sortable arrow csp" width="10%" data-sort-by="pos" rowspan="2" @click="dataSort()">
              POS<br/>
              <i :class="{'el-icon-arrow-down':isDown,'el-icon-arrow-up':!isDown}" ></i>
            </th>
            <th class="head-row-top hidden-small" colspan="4" width="30%">ROUNDS</th>
            <th rowspan="2" class="sortable total-score" data-sort-by="total-score" width="10%">TOTAL<br>SCORE</th>
            <th rowspan="2" width="10%" class="sortable to-par" data-sort-by="to-par">TO PAR</th>
            <th rowspan="2" width="10%" class="sortable hidden-small" data-sort-by="official-money">OFFICIAL<br>MONEY</th>
            <th rowspan="2" class="sortable" width="10%" data-sort-by="fedexcup-points">FEDEXCUP<br>POINTS</th>
          </tr>
          <tr>
            <th width="10%" class="sortable hidden-small head-row-bottom" data-sort-by="round-1">1</th>
            <th width="10%" class="sortable hidden-small head-row-bottom" data-sort-by="round-2">2</th>
            <th width="10%" class="sortable hidden-small head-row-bottom" data-sort-by="round-3">3</th>
            <th width="10%" class="sortable hidden-small head-row-bottom" data-sort-by="round-4">4</th>
          </tr>
        </thead>
        <tbody class="table-data">
          <tr>
            <td class="cell">
              <a class="player-link" href="/players/player.30925.dustin-johnson.html">Dustin Johnson</a>
            </td>
            <td>
              <span class="position round-1" data-sort-pos-1="T79">T79</span>
              <span class="position round-2" data-sort-pos-2="T20">T20</span>
              <span class="position round-3" data-sort-pos-3="2">2</span>
              <span class="position round-4" data-sort-pos-4="1">1</span>
            </td>
            <td class="hidden-small round round-1" data-sort-round-1-total="69" data-sort-round-1-par="-1">
              <span class="total-score">69</span>
              <span class="to-par">-1</span>
            </td>
            <td class="hidden-small round round-2" data-sort-round-2-total="64" data-sort-round-2-par="-6">
              <span class="total-score">64</span>
              <span class="to-par">-6</span>
            </td>
            <td class="hidden-small round round-3" data-sort-round-3-total="61" data-sort-round-3-par="-9">
              <span class="total-score">61</span>
              <span class="to-par">-9</span>
            </td>
            <td class="hidden-small round round-4" data-sort-round-4-total="67" data-sort-round-4-par="-3">
              <span class="total-score">67</span>
              <span class="to-par">-3</span>
            </td>
            <td class="total-score" data-sort-total-score="261">261</td>

            <td class="to-par">
              <span class="to-par-value round-1" data-sort-to-par-1="-1">-1</span>
              <span class="to-par-value round-2" data-sort-to-par-2="-7">-7</span>
              <span class="to-par-value round-3" data-sort-to-par-3="-16">-16</span>
              <span class="to-par-value round-4" data-sort-to-par-4="-19">-19</span>
            </td>
            <td class="hidden-small" data-sort-official-money="$1,332,000">$1,332,000</td>
            <td data-sort-fedexcup-points="500.00">500.00</td>
          </tr>
          <tr>
            <td class="cell">
              <a class="player-link" href="/players/player.30925.dustin-johnson.html">Dustin Johnson</a>
            </td>
            <td>
              <span class="position round-1" data-sort-pos-1="T79">T79</span>
              <span class="position round-2" data-sort-pos-2="T20">T20</span>
              <span class="position round-3" data-sort-pos-3="2">2</span>
              <span class="position round-4" data-sort-pos-4="1">1</span>
            </td>
            <td class="hidden-small round round-1" data-sort-round-1-total="69" data-sort-round-1-par="-1">
              <span class="total-score">69</span>
              <span class="to-par">-1</span>
            </td>
            <td class="hidden-small round round-2" data-sort-round-2-total="64" data-sort-round-2-par="-6">
              <span class="total-score">64</span>
              <span class="to-par">-6</span>
            </td>
            <td class="hidden-small round round-3" data-sort-round-3-total="61" data-sort-round-3-par="-9">
              <span class="total-score">61</span>
              <span class="to-par">-9</span>
            </td>
            <td class="hidden-small round round-4" data-sort-round-4-total="67" data-sort-round-4-par="-3">
              <span class="total-score">67</span>
              <span class="to-par">-3</span>
            </td>
            <td class="total-score" data-sort-total-score="261">261</td>

            <td class="to-par">
              <span class="to-par-value round-1" data-sort-to-par-1="-1">-1</span>
              <span class="to-par-value round-2" data-sort-to-par-2="-7">-7</span>
              <span class="to-par-value round-3" data-sort-to-par-3="-16">-16</span>
              <span class="to-par-value round-4" data-sort-to-par-4="-19">-19</span>
            </td>
            <td class="hidden-small" data-sort-official-money="$1,332,000">$1,332,000</td>
            <td data-sort-fedexcup-points="500.00">500.00</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "pastresults",
  data() {
    return {
      activeNames:'',
        yearssOptions: [
          {value: "2020.034",label: '2020'},
          {value: "2019.034",label: '2019'},
          {value: "2018.034",label: '2018'},
          {value: "2017.034",label: '2017'},
          {value: "2016.034",label: '2016'},
          {value: "2015.034",label: '2015'},
          {value: "2014.034", label: '2014'},
          {value: "2013.034", label: '2013'},
          {value: "2012.034", label: '2012'},
          {value: "2011.034", label: '2011'},
          {value: "2010.034", label: '2010'},
          {value: "2009.034", label: '2009'},
          {value: "2008.034", label: '2008'},
          {value: "2007.034", label: '2007'},
          {value: "2006.034", label: '2006'},
          {value: "2005.034", label: '2005'},
          {value: "2004.034", label: '2004'},
          {value: "2003.034", label: '2003'},
          {value: "2002.034", label: '2002'},
          {value: "2001.034", label: '2001'},
          {value: "2000.034", label: '2000'},
          {value: "1999.034", label: '1999'},
          {value: "1998.034", label: '1998'},
          {value: "1997.034", label: '1997'},
          {value: "1996.034", label: '1996'},
          {value: "1995.034", label: '1995'},
          {value: "1994.034", label: '1994'},
          {value: "1993.034", label: '1993'},
          {value: "1992.034", label: '1992'},
          {value: "1991.034", label: '1991'},
          {value: "1990.034", label: '1990'},
          {value: "1989.034", label: '1989'},
          {value: "1988.034", label: '1988'},
          {value: "1987.034", label: '1987'},
          {value: "1986.034", label: '1986'},
          {value: "1985.034", label: '1985'},
          {value: "1984.034", label: '1984'},
          {value: "1983.034", label: '1983'},
          {value: "1982.034", label: '1982'},
          {value: "1981.034", label: '1981'},
          {value: "1980.034", label: '1980'},
          {value: "1979.034", label: '1979'},
          {value: "1978.034", label: '1978'},
          {value: "1977.034", label: '1977'},
          {value: "1976.034", label: '1976'},
          {value: "1975.034", label: '1975'},
          {value: "1974.034", label: '1974'},
          {value: "1973.034", label: '1973'},
          {value: "1972.034", label: '1972'},
          {value: "1971.034", label: '1971'},
          {value: "1970.034", label: '1970'},
          {value: "1969.034", label: '1969'},
          {value: "1968.034", label: '1968'},
          {value: "1967.034", label: '1967'},
          {value: "1966.034", label:  '1966'},
          {value: "1965.034", label: '1965'},
          {value: "1964.034", label: '1964'},
          {value: "1963.034", label: '1963'},
          {value: "1962.034", label: '1962'},
          {value: "1961.034", label: '1961'},
          {value: "1960.034", label: '1960'},
          {value: "1959.034", label: '1959'},
          {value: "1958.034", label: '1958'},
          {value: "1957.034", label: '1957'},
          {value: "1956.034", label: '1956'},
          {value: "1955.034", label: '1955'},
          {value: "1954.034", label: '1954'},
          {value: "1953.034", label: '1953'},
          {value: "1952.034", label: '1952'},
      ],
      roundOptions:[
        {value: "1", label: 'Round 1'},
        {value: "2", label: 'Round 2'},
        {value: "3", label: 'Round 3'},
        {value: "4", label: 'Final Scores'},
      ],
      years: '2020.034',
      round: '4',
      switchVal: true,
      isDown: true,
    };
  },
  methods: {
    handleChange(){

    },
    dataSort(){
      this.isDown = !this.isDown
    }
  },
};
</script>

<style lang="less">
@import 'pastresults.css';
</style>