<template>
<div class="container">
  <div class="t1-trending">
    <div class="float-block" v-if="isS">
      <div class="dl-left-side visible-large">
        <a class="thumb" v-if="JSON.stringify(newVideoList[0])!=='{}'" :href="newVideoList[0].recommendType=='video'?'/Video?id='+newVideoList[0].relationId:'/NewsDetails?id='+newVideoList[0].relationId">
          <img class="thumb-img" data-crop="fill" data-gravity="center" :alt="newVideoList[0].recommendTitle" :src="newVideoList[0].recommendPoster">
          <span class="thumb-info thumb-info-first">
            {{newVideoList[0].recommendTitle}}
          </span>
          <svg  v-if="newVideoList[0].recommendType == 'video'" class="icon icon_blur1" aria-hidden="true">
            <use  xlink:href="#icon-bofang"></use>
          </svg>
        </a>
        <a class="thumb" v-if="JSON.stringify(newVideoList[1])!=='{}'" :href="newVideoList[1].recommendType=='video'?'/Video?id='+newVideoList[1].relationId:'/NewsDetails?id='+newVideoList[1].relationId" >
          <img class="thumb-img" data-crop="fill" data-gravity="center" :alt="newVideoList[1].recommendTitle" :src="newVideoList[1].recommendPoster">
          <span class="thumb-info ">
            {{newVideoList[1].recommendTitle}}
          </span>
          <svg  v-if="newVideoList[1].recommendType == 'video'" class="icon icon_blur2" aria-hidden="true">
            <use  xlink:href="#icon-bofang"></use>
          </svg>
        </a>
        <a class="thumb ml1" v-if="JSON.stringify(newVideoList[2])!=='{}'" :href="newVideoList[2].recommendType=='video'?'/Video?id='+newVideoList[2].relationId:'/NewsDetails?id='+newVideoList[2].relationId" >
          <img class="thumb-img" data-crop="fill" data-gravity="center" :alt="newVideoList[2].recommendTitle" :src="newVideoList[2].recommendPoster">
          <span class="thumb-info ">
            {{newVideoList[2].recommendTitle}}
          </span>
          <svg  v-if="newVideoList[2].recommendType == 'video'" class="icon icon_blur2" aria-hidden="true">
            <use  xlink:href="#icon-bofang"></use>
          </svg>
        </a>
      </div>
      <div class="dl-right-side visible-large">
        <a class="thumb" v-if="JSON.stringify(newVideoList[3])!=='{}'" :href="newVideoList[3].recommendType=='video'?'/Video?id='+newVideoList[3].relationId:'/NewsDetails?id='+newVideoList[3].relationId" >
          <img class="thumb-img" data-crop="fill" data-gravity="center" :alt="newVideoList[3].recommendTitle" :src="newVideoList[3].recommendPoster">
          <span class="thumb-info ">
            {{newVideoList[3].recommendTitle}}
          </span>
          <svg  v-if="newVideoList[3].recommendType == 'video'" class="icon icon_blur2" aria-hidden="true">
            <use  xlink:href="#icon-bofang"></use>
          </svg>
        </a>
        <a class="thumb" v-if="JSON.stringify(newVideoList[4])!=='{}'" :href="newVideoList[4].recommendType=='video'?'/Video?id='+newVideoList[4].relationId:'/NewsDetails?id='+newVideoList[4].relationId" >
          <img class="thumb-img" data-crop="fill" data-gravity="center" :alt="newVideoList[4].recommendTitle" :src="newVideoList[4].recommendPoster">
          <span class="thumb-info ">
            {{newVideoList[4].recommendTitle}}
          </span>
          <svg  v-if="newVideoList[4].recommendType == 'video'" class="icon icon_blur2" aria-hidden="true">
            <use  xlink:href="#icon-bofang"></use>
          </svg>
        </a>
        <a class="thumb" v-if="JSON.stringify(newVideoList[5])!=='{}'" :href="newVideoList[5].recommendType=='video'?'/Video?id='+newVideoList[5].relationId:'/NewsDetails?id='+newVideoList[5].relationId" >
          <img class="thumb-img" data-crop="fill" data-gravity="center" :alt="newVideoList[5].recommendTitle" :src="newVideoList[5].recommendPoster">
          <span class="thumb-info ">
            {{newVideoList[5].recommendTitle}}
          </span>
          <svg  v-if="newVideoList[5].recommendType == 'video'" class="icon icon_blur2" aria-hidden="true">
            <use  xlink:href="#icon-bofang"></use>
          </svg>
        </a>
      </div>
    </div>
    <div class="fixed-block">
       <slot name="girl"></slot>
    </div>
  </div>
  </div>
</template>

<script>
  import getAxios from "../request";

  export default {
    components:{

    },
    name: "PushTop",
    props:{
      topTitle:{
        type:String,
        default:''
      },
      page:{
        type: Number,
        default: 2
      },


    },
    data(){

      return {
        imgshow: true,
        //默认图片
        imgURL:'this.src="'+require('../assets/default2.png')+'"',
        newVideoList:[],
      //  标签
        title:this.topTitle,
        video:{},
        isS:false,
        obj:{
          // recommendTitle: "奥运会男子高尔夫最终排名公布",
          // recommendPoster: "https://image-1253438777.cos.ap-guangzhou.myqcloud.com/pgaArticleImage/162442145445311.925583582697652.jpg",
          // recommendTitle: "奥运会男子高尔夫最终排名公布",
          // recommendType: "article",
          // relationId: 344,
        }
      }
    },
    created() {
      this.getNewVideoList()
      this.getVideo()
    },
    methods:{
      //请求视频播放数据
      async getVideo(videoId){
        this.video={}
        this.video = await getAxios({
          url:"/videoDetail",
          data:{
            videoId:301
          }
        })
        // this.$refs.video.muted = false
      },
      handleLoad(e){
        // e.path[0].src="../assets/default2.png"
        let _this = this
        _this.imgshow = true

      },
      handerr(e){
        e.path[0].src="../assets/default2.png"
        let _this = this
        _this.imgshow = true
      },
    //  发送请求
      async getNewVideoList(){
        this.newVideoList = await getAxios({
          url:"/recommendList",
          data:{
            location:this.title
          }
        })
        this.isS = true
        // console.log( this.newVideoList);
        this.$forceUpdate()
      },
      goOther(id, classfy){
        // 把参数传给父组件
        console.log(id)
		  console.log(classfy)
		  this.$emit('getPushTop',{id,classfy})
		
      },
    }
  }
</script>

<style scoped lang="less">
.container{
  margin-left: auto;
  margin-right: auto;
  max-width: 1300px;
  overflow: hidden;
  pointer-events: all;
  width: 1300px;
  height: 600px;
}
.t1-trending{
  position: relative;
  height: 649px;
  .float-block{
    position: absolute;
    left: 0;
    right: 288px;
    height: 649px;
    // margin-right: 2px;
    .dl-left-side {
      float: left;
      width: 66%;
      margin: 0 1px 0 0;
      overflow: hidden;
      .thumb{
          overflow: hidden;
          position: relative;
          display: block;
          height: 195px;
          width: 49.9%;
          float: left;
          margin-bottom: 1px;
          img{
            
          }
          .icon_blur1{
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -50px;
            margin-left: -50px;
            z-index: 3;
            width: 100px;
            height: 100px;
          }
          .icon_blur2{
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -35px;
            margin-left: -35px;
            z-index: 3;
            width: 70px;
            height: 70px;
          }
          .thumb-img{
            display: block;
            height: 100%;
            width: 100%;
            margin: 0 auto;
            object-fit: cover;
          }
          .thumb-info{
            background-color: rgba(0,0,0,.6);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            color: #E4E4E4;
            padding: 2% 3%;
            display: block;
            z-index: 3;
            cursor: pointer;
            font-size: 18px;
            line-height: 30px;
            text-align: center;
          }
          .thumb-info-first {
            font-size: 33px;
            line-height: 48px;
            text-align: left;
          }
      }
      .thumb:first-child{
          width: 674px;
          height: 396px;
          margin-bottom: 4px;
          float: left;
          position: relative;
          overflow: hidden;
      }
    }
    .dl-right-side {
      margin: 0 0.3% 0 0;
      width: 333px;
      overflow: hidden;
      .thumb{
          overflow: hidden;
          position: relative;
          display: block;
          height: 195px;
          width: 333px;
          float: left;
          margin-bottom: 5px;
          .icon_blur2{
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -35px;
            margin-left: -35px;
            z-index: 3;
            width: 70px;
            height: 70px;
          }
          .thumb-img{
            display: block;
            height: 100%;
            width: 100%;
            margin: 0 auto;
            object-fit: cover;
          }
          .thumb-info{
            background-color: rgba(0,0,0,.6);
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            color: #E4E4E4;
            padding: 2% 3%;
            display: block;
            z-index: 0.5;
            cursor: pointer;
            font-size: 18px;
            line-height: 30px;
            text-align: center;
          }
      }
    }
      
}

  
  .fixed-block{
    position: absolute;
    width: 284px;
    // right: 5px;
    right: 0px;
    height: 596px;
    background: #fff;
  }
}
.ml1{
  margin-left: 1px;
}
</style>
