/**
 * 全站http配置
 *
 * header参数说明
 * serialize是否开启form表单提交
 * isToken是否需要token
 */
import axios from 'axios'
import store from '@/store'

const  baseUrl ='https://pgaadmin.golfbox.cn/api'

const getAxios = (param) =>{
  // console.log(store.state.closeLoading);
  store.state.closeLoading = true
  // store.commit('setLoading',true)
  return  axios({
    method: param.method,
    url:baseUrl + param.url,
    params:param.data,
  }).then(res => {
     // console.log(res)
    store.state.closeLoading = false
    return res.data.data

   }).catch(err => {
     console.log('请求：'+err)
     store.state.closeLoading = false
     return -1
    })

}
export default getAxios
