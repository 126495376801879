/**
 * Copyright (C) 2018-2019
 * All rights reserved, Designed By www.golfbox.cn
 * 注意：
 * 本软件为www.golfbox.cn开发研制，未经购买不得使用
 * 购买后可获得全部源代码（禁止转卖、分享、上传到码云、github等开源平台）
 * 一经发现盗用、分享等行为，将追究法律责任，后果自负
 */
 import request from '@/router/axios'

 export function getPage(query) {
     console.log(query)
     return request({
         url: '/api/articleList',
         method: 'get',
         data: query
     })
 }
 
 export function getArticleListByName(query) {
    console.log(query)
    return request({
        url: '/api/getArticleListByName',
        method: 'get',
        data: query
    })
}
 