import Vue from 'vue'
import axios from "axios";
import store from '../store'
Vue.prototype.$ajax = axios

const  baseUrl ='https://pgaadmin.golfbox.cn/api/pgaApi/'

//const  baseUrl ='http://gs4gnu.natappfree.cc/pgaApi/'

// const  baseUrl ='http://gs4gnu.natappfree.cc/pgaApi'



const service = axios.create({
  timeout: 60000,// request timeout
})
let dates = {}
let getNUm = 5
//拦截器
service.interceptors.response.use(
    response => {
      console.log('请求成功');
      return Promise.resolve(response)
      // console.log(response.data.data===null);
      // if (response.data.data===null){
      //   return Promise.resolve(response)
      // }else if (getNUm>0){
      //   getNUm--
      //   pgaApi(dates)
      // }

    },
    error => {
      console.log('请求超时');

      // pgaApi(dates)

      //pgaApi(dates)

      // if (getNUm>0){
      //   getNUm--
      //   pgaApi(dates)
      // }else{
      //   return error
      // }

    }
)
const pgaApi = (param) =>{
  //console.log(param);
  dates = param
  store.state.closeLoading = true
  return  service({
    method: 'get',
    url:baseUrl + param.url,
    params:param.data,
  }).then(res => {
    // console.log(res)
    store.state.closeLoading = false
    return res.data.data
  }).catch(err => {
    console.log('请求：'+err)
    store.state.closeLoading = false
    return -1
  })

}


export default pgaApi