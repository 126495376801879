<template>
  <div class="Video">
    <div class="shell">
      <div class="shell_content">
        <div class="title" >
          <span>美巡视频</span>
        </div>
        <div class="content">
          <div class="left">
            <div class="top">
              <div class="videoCast">
<!--                :poster="video.videoPostUrl"-->
                <div  :class="{video_shell:P_in_P}">
                  <span @click="closeP" v-show="P_in_P" class="iconfont icon-guanbi1 close_video"> </span>
                  <video class = "top_video"
                         ref = "video01"
                         id = "video01"
                         width = "100%"
                         controlsList="nodownload"
                         controls
                         playsinline = "true"
                         :poster = "video.videoPostUrl"
                         :src = "video.videoUrl" >
                 </video>
                </div>

              </div>
              <div class="top_title">
                <p>{{video.videoName}}</p>
                <p>{{video.createTime}}</p>
                <p>视频简介：{{video.videoDescribe}}</p>
              </div>
            </div>
            <div ref="column" class="column">
              <Column ref="column" @columnVideoid="getVideoId"  @changeHeight="handleChangeHeight"></Column>
            </div>

          </div>
          <div class="right" ref="rankColume" >
            <rankColume :rankColumeChild="rankColumeChild" class="right_col" ref="right_col" @setVideoId='getVideoId'/>
            <!-- 广告 -->
            <!-- <Advert :location="'video'" /> -->
          </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Column from "../components/Column";
  import rankColume from "../components/rankingList/rankVideo";
  import getAxios from "../request";
  import methods_share from "../assets/js/methods_share";
  import Advert from "../components/Advert";

  const Video = {
    name: "Video",
    components:{
      Column,
      rankColume,
      Advert
    },
    data(){
      return{
      //两个变量都是记录组件 rankColume 高度
      //  父组件外壳高度
        rankColumeHeight : 1250,
      //  传给子组件 rankColume 的高度
        rankColumeChild:860,
      //  视频播放器视频源
        video:{},
      //  视频弹出
        P_in_P:false,
        P_long:false,
        newId:''
      }
    },
    mounted() {
      window.addEventListener('scroll', this.scrollEvent,false)
      document.getElementById("video01").oncontextmenu = function(e){
      　　return false;
      }
    },
    created(){
    //  调用请求最新视频事件
      this.getnewVideo()
      if(this.$route.query.id != ''){
        this.getVideoId(this.$route.query.id)
      }
    },
    methods:{
      //初始请求最新的视频
      async getnewVideo(){
        this.video={}
        console.log('this.video: ', this.video);
        this.video = await getAxios({
          url:"/getAVideoByTime"
        })
         console.log(this.video);
          this.video.createTime = methods_share.transformDateDian(this.video.createTime)
         
      },
      //请求视频播放数据
      async getVideo(videoId){
        this.video={}
        this.video = await getAxios({
          url:"/videoDetail",
          data:{
            videoId
          }
        })
        this.video.createTime = methods_share.transformDateDian(this.video.createTime)
        // console.log(this.video);
      },
      handleChangeHeight(e){
        let num = e%3
        let reg = e/3
        if (e > 0){
          reg = parseInt(reg)
          if (num!==0){
            reg++
          }
          this.rankColumeChild = 860 + 219 * reg-1
          this.rankColumeHeight = 868 + 219 * reg-1
          this.$refs.rankColume.style.height = this.rankColumeHeight +'px'
          this.$refs.right_col.changeHeight(this.rankColumeChild)
        }
      },
    //  接收视频id,请求视频源
      getVideoId(e){
        window.scrollTo(0,0)
        this.getVideo(e)
      },
      //监测滚动条状态
      scrollEvent() {
        // 设备屏幕高度
        const scrollTop = window.pageYOffset; // div 到头部的距离
        //滚动条到顶部距离
        if (scrollTop >= 600 && this.P_long === false) {
          this.P_in_P = true
          this.P_long = true
        }
        if (scrollTop < 600 ){
          this.P_in_P = false
          this.P_long = false
        }

      },
    //  关闭视频弹窗
      closeP(){
        this.P_in_P = false
        this.P_long = true
      }
    }
  }
  export default Video
</script>

<style scoped lang="less">
  .title{
    width: 100%;
    // background-color: #eeeeee;
    background-color: #DDDDDD;
    font-size: 40px;
    color: #053970;
    padding-bottom: 25px;
    span{
      //font-family: My_Bold;
      letter-spacing: 2px;
      color: #053970;
      font-weight: bold;
    }
  }
  .column{
    /*border: 1px solid red;*/
    /*background-color: red;*/
  }
  .content{
    display: flex;
    background-color: #eeeeee;
    .left{
      background-color: #eeeeee;
      width: 980px;
      .top{
        background-color: #ffffff;
        padding: 16px 16px 0 16px;
        .videoCast{
          /*width: 600px;*/
          height: 500px;
          .top_video{
            outline: none;
          }
        }

        .top_title{
          p:nth-child(1){
            font-size:25px;
            //font-family: My_Medium;
            /*padding: 0px 0 00;*/
            color: #385883;
          }

          p:nth-child(2),p:nth-child(3){
            font-size: 16px;
            margin-top: 12px;
            //font-family: My_Medium;
          }
          margin-top: 45px;
          padding-bottom: 50px;
          /*border-bottom: 2px solid #eeeeee;*/
        }
        .right{
          overflow: hidden;
        }
      }
    }
    .right{
      width: 325px;
      background-color: #001629;
      //height: 1442px;
      height: inherit;
      overflow: hidden;
      .right_col{
        // height: inherit;
        height: 100%;
        /*height: 50%;*/
        /*overflow: hidden;*/
        /*overflow: auto;*/
      }
    }
  }
  .video_shell{
    position: fixed;
    bottom: 0;
    right: 0;
    outline: none;
    width: 350px;
    z-index: 6;
    .close_video{
      color: #777777;
      font-size: 25px;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 6;
      /*background-color: #053970;*/
    }
  }
  .close_video:hover{
    /*background-color: #eeeeee;*/
    color: #000000;
  }

</style>