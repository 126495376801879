<template>
  <div class="News">
    <div class="shell">
      <div class="shell_content">
        <div class="title" >
          <span>美巡新闻</span>
        </div>
        <div class="news_content">
          <div class="left">
            <News-list :toNewText="toNewText"  v-if="toNewText" @newInter="handleNewInter"></News-list>
            <!--<New-text :toNewText="toNewText" v-if="!toNewText" @newText="handleNewText"></New-text>-->
          </div>
          <div class="right">
            <rankNews></rankNews>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import NewsList from "../components/NewsList";
  import NewText from "../components/NewText";
  import rankNews from "../components/rankingList/rankNews";
  export default {
    name: "News",
    components:{
      NewsList,
      NewText,
      rankNews
    },
    data(){
      return {
        toNewText:true,
        search:"",
        page:{},
        isBack:false

      }
    },
    beforeRouteEnter (to, from, next) {
      next(vm => {
        // 通过 `vm` 访问组件实例,将值传入fromPath
        if (from.name=="NewsDetails") {
            vm.isBack = true
            sessionStorage.setItem("isBack",vm.isBack)
            // vm.search = sessionStorage.getItem("search")
            // vm.page =  JSON.parse(sessionStorage.getItem("page"))
        }
      })
      // ...
    },    
    methods:{

      //接收新闻列表组件传递的数据
      handleNewInter(e){
        console.log(e)
        this.toNewText = e.toNewText
      },
    //  接收新闻详情组件的数据
      handleNewText(e){
        this.toNewText = e.toNewText

      },
    },
    created() {
      // console.log(getAxios({
      //   url:'/articleList',
      //   data:{
      //     page:1,
      //     limit:6
      //   }
      // }));
    }

  }
</script>

<style scoped lang="less">
  .title{
    width: 100%;
    background-color: #eeeeee;
    font-size: 40px;
    color: #053970;
    padding-bottom: 25px;
    span{
      //font-family: My_Bold;
      letter-spacing: 2px;
    }
  }
  .news_content{
    display: flex;
    .left{
      flex: 5;
      padding-top: 20px;
    }
    .right{
      flex: 2;
      background-color: #F9F9F9;
      padding: 10px 20px;
      /*height: 100px;*/
    }
  }
</style>