import Vue from 'vue'
import axios from "axios";
import store from '../store'
Vue.prototype.$ajax = axios
const  baseUrl ='https://pgaadmin.golfbox.cn/api/api'

const getAxios = (param, method) =>{
  // console.log(store.state.closeLoading);
  store.state.closeLoading = true
  // store.commit('setLoading',true)
  let axiosObj = {
    method: method ? method : 'get',
    url:baseUrl + param.url,
  };
  if(method === 'post') {
    axiosObj.params = param.data;
    axiosObj.headers = { 'content-type': 'application/x-www-form-urlencoded' }
  } else {
    axiosObj.params = param.data;
  }
  return axios(axiosObj).then(res => {
     // console.log(res)
    store.state.closeLoading = false
    return res.data.data

   }).catch(err => {
     console.log('请求：'+err)
     store.state.closeLoading = false
     return -1
      })

}


export default getAxios