import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Fedexcup from "../views/Fedexcup";
import Players from "../views/Players";
import FedexcupList from "../views/Detail/FedexcupList";
import Schedule from "../views/Schedule";
import News from "../views/News";
import Video from "../views/Video";
import Stats from "../views/Stats/Stats";
import PlayerInter from "../views/Detail/PlayerInter";
import NewsDetails from "../views/Detail/NewsDetails";
import FullScorecard from '../views/FullScorecard/index';
import Leaderboard from '../views/LeaderBoard/Leaderboard.vue'
import Leader from "../views/LeaderBoard/Leader";
import LeaderMobile from "../views/mobile/LeaderBoard/Leader";
import TeeTimes from "../views/LeaderBoard/TeeTimes";
import TeeTimesMobile from "../views/mobile/LeaderBoard/TeeTimes";
import HoleLocations from "../views/LeaderBoard/HoleLocations";
import Lineup from "../views/LeaderBoard/LineUp";
import PastResults from "../views/LeaderBoard/PastResults";
import HoleMap from "../views/LeaderBoard/HoleMap";
import HoleMapMobile from "../views/mobile/LeaderBoard/HoleMap";
import searchResult from "../views/searchResult";
import FedExCupNew from '../views/FedExCupNew/FedExCupNew.vue'
// import PresidentCup from '../views/PresidentCup/PresidentCup.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: '首页',
      keepAlive: true, // 需要被缓存
    }

  },
  {
    path: '/searchResult',//搜索结果页
    name: 'searchResult',
    component: searchResult
  },
  {
    path: '/Leaderboard',
    name: 'Leaderboard',
    component: Leaderboard,
    meta: {
      title: '领先榜',
      keepAlive: true, // 需要被缓存
    },
    children:[
      {
        path: 'Leader',
        name: 'Leader',
        component: Leader,
      },
      {
        path: 'LeaderMobile',
        name: 'LeaderMobile',
        component: LeaderMobile,
      },
      {
        path: 'TeeTimes',
        name: 'TeeTimes',
        component: TeeTimes,
      },
      {
        path: 'TeeTimesMobile',
        name: 'TeeTimesMobile',
        component: TeeTimesMobile,
      },
      {
        path: 'HoleLocations',
        name: 'HoleLocations',
        component: HoleLocations,
      },
      {
        path: 'HoleMap',
        name: 'HoleMap',
        component: HoleMap,
      },
      {
        path: 'HoleMapMobile',
        name: 'HoleMapMobile',
        component: HoleMapMobile,
      },
      {
        path: 'Lineup',
        name: 'Lineup',
        component: Lineup,
      },
      {
        path: 'PastResults',
        name: 'PastResults',
        component: PastResults,
      },
    ]
  },
  {
    path: '/Players',
    name: 'Players',
    component: Players
  },
  {
    path: '/Fedexcup',
    name: 'Fedexcup',
    component: Fedexcup
  }
  ,
  {
    path: '/Schedule',
    name: 'Schedule',
    component: Schedule
  },
  {
    path: '/News',
    name: 'News',
    component: News
  },
  {
    path: '/Video',
    name: 'Video',
    component: Video
  },
  {
    path: '/Stats',
    name: 'Stats',
    component: Stats,
    children:[
      {
        path: 'dataForm',
        name: 'dataForm',
        component: () => import( '../views/Stats/child/dataForm'),
      },
      {
        path: 'Money',
        name: 'Money',
        component: () => import( '../views/Stats/child/Money'),
      },
      {
        path: 'WorldRanking',
        name: 'WorldRanking',
        component: () => import( '../views/Stats/child/WorldRanking'),
      },
      {
        path: 'Campion',
        name: 'Campion',
        component: () => import( '../views/Stats/child/Campion'),
      },
      {
        path: 'GrandSlam',
        name: 'GrandSlam',
        component: () => import( '../views/Stats/child/GrandSlam'),
      },
      {
        path: 'ScoreBoard',
        name: 'ScoreBoard',
        component: () => import( '../views/Stats/child/ScoreBoard'),
      },
      {
        path: 'DataTemplate',
        name: 'DataTemplate',
        component: () => import( '../views/Stats/child/DataTemplate'),
      },
    ]
  },
  {
    path: '/FedExCupNew',
    name: 'FedExCupNew',
    component: FedExCupNew,
    children:[
      {
        path: 'information',
        name: 'information',
        component: () => import( '../views/FedExCupNew/Information'),
      },
      {
        path: 'overview',
        name: 'overview',
        component: () => import( '../views/FedExCupNew/Overview'),
      },
      {
        path: 'standings',
        name: 'standings',
        component: () => import( '../views/FedExCupNew/Standings'),
      },
      {
        path: 'history',
        name: 'history',
        component: () => import( '../views/FedExCupNew/History'),
      },
    ]
  },
  // {
  //   path: '/PresidentCup',
  //   name: 'PresidentCup',
  //   component: PresidentCup,
  //   children:[
  //     {
  //       path: 'information',
  //       name: 'information',
  //       component: () => import( '../views/PresidentCup/Information'),
  //     },
  //     {
  //       path: 'overview',
  //       name: 'overview',
  //       component: () => import( '../views/PresidentCup/Overview'),
  //     },
  //     {
  //       path: 'rank',
  //       name: 'rank',
  //       component: () => import( '../views/PresidentCup/Rank'),
  //     },
  //   ]
  // },
//  副页路由
  {
    path: '/list/FedexcupList',
    name: 'FedexcupList',
    component: FedexcupList
  },
  {
    path: '/PlayerInter/PlayerInter',
    name: 'PlayerInter',
    component: PlayerInter
  },
  {
    path: '/NewsDetails',
    name: 'NewsDetails',
    component: NewsDetails
  },
  {
    path: '/FullScorecard',
    name: 'FullScorecard',
    component: FullScorecard
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
