<template>
  <div class="Fedexcup">

    <div class="shell">

      <div class="shell_content shell_content-new">
        <div class="title">
          <span>联邦快递杯</span>
        </div>
        <PushTop :page="3" :topTitle="top_title" @getPushTop="handleRealtime">
          <template slot="girl">
            <!-- <rankFedexcup  :fedexList="fedexList"/> -->
            <!-- 广告 -->
            <Advert :location="'cup'" />
          </template>
        </PushTop>
        <Realtime :top_title="all_title" @getRealtime="handleRealtime"/>
      </div>
    </div>

    <!--视频播放插件-->
    <div class="bofang" v-show="bofang">
      <div class="cast">
        <broadcast  ref="broadcast"></broadcast>
        <div class="close iconfont icon-guanbi2" @click="closeIt()"></div>
      </div>
    </div>
  </div>

</template>

<script>
  import PushTop from "../components/PushTop2";
  import rankFedexcup from "../components/rankingList/rankFedexcup";
  import Realtime from "../components/Realtime";
  import broadcast from "../components/broadcast/broadcast";
  import Advert from "../components/Advert";

  export default {
    name: "Fedexcup",
    components:{
      PushTop,
      rankFedexcup,
      Realtime,
      broadcast,
      Advert
    },
    data(){
      return {
        //联邦快递杯排名
        fedexList: [
          {
            "pro": '1',
            'name': 'jack',
            'integration': 400
          },
          {
            "pro": '2',
            'name': 'Hazel',
            'integration': 200
          },
          {
            "pro": '3',
            'name': 'Braden',
            'integration': 140
          }
        ],
        //  中部模块标题 和 传递给子组件PushTop的数据
        top_title: 'fedexcup',
        all_title: '联邦快递杯',
        //  是否播放
        bofang: false,


      }
    },
    methods:{
      //  关闭播放组件
      closeIt(){
        this.bofang = false
        this.$refs.broadcast.videoPlay()
      },
      //  接收Realtime/PushTop组件传递过来的参数
      handleRealtime(e){
        if(e.classfy === 'video'){
          // this.videoId = e.id
          this.bofang = true
          this.$refs.broadcast.getVideo(e.id)
        }else if(e.classfy === 'article'){
          this.$router.push({
            path: '/NewsDetails',
            query: {id :e.id}
          })
          // console.log('ss'+this.$router);
        }
      },
      

    }
  }
</script>

<style scoped lang="less">
  .title{
    width: 100%;
    background-color: #eeeeee;
    font-size: 40px;
    font-weight: 600;
    padding-bottom: 25px;
    span{
      letter-spacing: 2px;
      color: #053970;
      //font-family: My_Bold;
    }
  }
  .shell_content{
    background-color: #eeeeee;
  }
  .shell_content-new{
    width: 1300px;
  }
  .bofang{
    background-color: #ffffff;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 20;
    background-color: rgba(0,0,0,.5);
    .cast{
      background-color: #ffffff;
      width: 60vw;
      position: absolute;
      left: 50%;
      top: 10vh;
      margin-left: -30vw;
      .close{
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        font-size: 25px;
        background-color: #ffffff;
        color: #000000;
        position: absolute;
        top: -40px;
        right: 0;
        border-radius: 50%;
      }
    }
  }
</style>
