<template>
  <div>
    <!-- <div class="titles"> -->
      <!-- <span>美巡新闻</span> -->
    <!-- </div>     -->
    <div class="shell"> 
      <div class="shell_content">
        <div class="news_content">
          <div class="left">
            <New-text :article="article"  @newText="handleNewText"></New-text>
          </div>
          <div class="right">
            <rankNews @getID="getID"></rankNews>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import NewText from "../../components/NewText";
  import rankNews from "../../components/rankingList/rankNews";
  import getAxios from "../../request";
  import methods_share from "../../assets/js/methods_share";

  export default {
    name: "NewsDetails",
   components:{
        NewText,
        rankNews,
     // "child":{
     //
     // }
  },
  data(){
    return {
      toNewText:true,
      article:{},
      newId:1
    }
  },
  created() {
    this.newId = this.$route.query.id || 1
      //发送文章详情数据
      this.getData()
    window.scrollTo(0,0)
    // console.log(article.data);
  },
    mounted() {
      // console.log(this.article.articleContent);
    },
    methods:{
      //右侧列表被点击
      getID(e){
        this.newId = e
        this.getData()
      },
      //请求文章详情数据
      async getData(){
        // console.log(this.$route.query[0])
        // let query = this.$route.query
        // console.log('路由:',typeof query)
        this.article = await getAxios({
           url: '/articleDetail',
           data: {
             articleId: this.newId
           }
         })
        this.article.createTime = methods_share.transformDate(this.article.createTime)
        console.log("this.article",this.article);
        this.article.articleContent = this.article.articleContent.replace(new RegExp("http://","g"),"https://");
        
        // 视频取消通过iframe加载，通过video标签加载，可以控制下载按钮不显示
          // 此写法避免替换了本身是iframe的html视频引入，只修改通过腾讯云点播上传的iframe视频
        if(this.article.articleContent.indexOf('iframe')!=-1) { //判断iframe是否存在
          this.article.articleContent = this.article.articleContent.replace(/<iframe [^>]*src=['"]([^'"]+)[^>]*>/gi, function (match,capture) {
            if(capture.includes('.mp4')) {
              match = match.replace(new RegExp("<iframe","g"),`<video controls controlsList="nodownload"`);
              match = match.replace(new RegExp("</iframe","g"),"</video");
            }
            return match;
          });
        }
          // 此写法无法区分iframe本质
        // this.article.articleContent = this.article.articleContent.replace(new RegExp("<iframe","g"),`<video controls controlsList="nodownload"`);
        // this.article.articleContent = this.article.articleContent.replace(new RegExp("</iframe","g"),"</video");
      },
    //接收新闻列表组件传递的数据
    handleNewInter(e){
      this.toNewText = e.toNewText
    },
    //  接收新闻详情组件的数据
    handleNewText(e){
      this.toNewText = e.toNewText
    }

  }

  }
</script>

<style scoped lang="less">
  .news_content{
    display: flex;
    .left{
      flex: 6;
      padding-top: 20px;
    }
    .right{
      flex: 2;
      padding: 10px 20px;
      background-color: #F9F9F9;
      /*background-color: #053970;*/
      /*height: 100px;*/
    }
  }
   .titles{
    display: inline-block;
    margin-top: 25px;
    margin-left: 300px;
    span{
    width: 185px;
    height: 44px;
    font-size: 45px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #183666;
    line-height: 60px;
    }
  }
</style>
