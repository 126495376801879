<template>
  <!--  实时-->
  <div class="Realtime">
    <!--    导航栏-->
    <Navtime
      :title="top_title"
      :activeArray="activeArray"
      @changeIndex="handlechangeIndex"
    />
    <!--    内容-->
    <div class="content">
      <!--      新闻-->
      <div class="all" v-if="navIndex === 0">
        <div
          class="content_01"
          v-for="(NewsList, index) of NewsList"
          :key="index"
          @click="play_new(NewsList.articleId, 'article')"
        >
          <img :src="NewsList.articlePostUrl" alt="" />
          <p class="content_text">
            {{ NewsList.articleTitle }}
          </p>
        </div>
      </div>
      <!--      视频-->

      <div class="all" v-if="navIndex == 1">
        <div
          class="content_01"
          v-for="(videosList, index) of videosList"
          :key="index"
          @click="play_new(videosList.videoId, 'video')"
        >
          <img :src="videosList.videoPostUrl ? videosList.videoPostUrl: '../assets/default2.png' " alt=""/>
          <svg class="icon icon_blur" aria-hidden="true">
            <use xlink:href="#icon-bofang"></use>
          </svg>
          <p class="content_text">
            {{ videosList.videoName }}
          </p>
        </div>
      </div>
      <div id="card_section">
        <div class="all" v-if="navIndex == 2 || navIndex == 3">
          <div
            class="content_01"
            v-for="(videosList, index) of videosList"
            :key="index"
            @click="play_new(videosList.videoId, 'video')"
          >
            <img  :src=" videosList.videoPostUrl? videosList.videoPostUrl : '../assets/default2.png'" alt="" />
            <svg class="icon icon_blur" aria-hidden="true">
              <use xlink:href="#icon-bofang"></use>
            </svg>
            <p class="content_text">
              {{ videosList.videoName }}
            </p>
          </div>
        </div>
      </div>
      <!-- 联邦快递杯 -->
      <div class="all" v-if="navIndex == 4">
        <div
          class="content_01"
          v-for="(FedexcupList, index) of videosList"
          :key="index"
          @click="play_new(FedexcupList.videoId, 'video')"
        >
          <img :src="FedexcupList.videoPostUrl?FedexcupList.videoPostUrl:'../assets/default2.png'" alt="">
          <svg class="icon icon_blur" aria-hidden="true">
            <use xlink:href="#icon-bofang"></use>
          </svg>
          <p class="content_text">
            {{ FedexcupList.videoName }}
          </p>
        </div>
      </div>
    <div class="btn_more">
      <div class="btn" @click="getMore">
        <span>{{moreTxt}}</span>
        <span class="icon-jiazaigengduo1 iconfont"></span>
      </div>
    </div>
    </div>
    </div>
  </div>
</template>


<script>
import Navtime from "./navigation/navTime";
import getAxios from "../request";
import scroll from "@/util/scroll";
export default {
  name: "Realtime",
  components: {
    Navtime,
  },
  props: {
    top_title: String,
  },
  data() {
    return {
      activeArray: [true, false, false],
      navIndex: 0,
      title: this.top_title,
      //  视频分类列表
      videosList: [],
      //临时视频分类列表
      tempvideosList:[],
      //  新闻分类列表
      NewsList: [],
      //中国球员
      ChinaPlayerList: [],
      //中国球员
      majorPlayerList: [],
      //联邦快递杯
      FedexcupList: [],
      //  全部分类列表
      allList: [],
      //  视频分类页码标志
      videoPage: 1,
      //  新闻分类页码标志
      newsPage: 1,
      //  全部分类页码标志
      allPage: 1,
      moreTxt: "加载更多",
      newB: true,
      allB: true,
      videoB: true,
      isAchiveBottom: false, // 滚动条是否到底部标志
      page: {
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 12, // 每页显示多少条
      },
      pages:{
        total: 0, // 总页数
        currentPage: 1, // 当前页数
        pageSize: 12, // 每页显示多少条          
      },
      throttleLoad: null,
    };
  },
  created() {
    this.NewsHot(this.page);
  },
  mounted() {
    window.addEventListener("scroll", this.scroll, false);
  },
  methods: {
    // scroll() {
    //   let that = this
    //   let scroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;//页面滚动高度
    //   let windowHeight=window.innerHeight;//窗口高度
    //   let card_section = document.getElementById("card_section")
    //   let card_sectionTop=card_section.offsetTop;//card_section距离顶部的偏移高度（card_section为你的照片或div元素ID）
    //   let card_sectionHeight=card_section.offsetHeight;//card_section的高度
    //   if(card_sectionTop+card_sectionHeight<scroll+windowHeight){
    //     //这里开始的你请求操作或者是显示数据
    //     if(that.navIndex == 2||that.navIndex == 3){
    //       that.page.currentPage++
    //       that.Player(that.page)
    //     }
    //   }
    // },
    //接收导航栏状态
    handlechangeIndex(index) {
      let that = this;
      that.navIndex = index;
      that.moreTxt = '加载更多'
      if (index == 0) {
        that.page = JSON.parse(JSON.stringify(that.pages))
        that.NewsHot(that.page); //热门新闻
        that.videosList = [];
      } else if (index == 1) {
        that.page = JSON.parse(JSON.stringify(that.pages))
        that.VideoHot(that.page); //热门视频
        that.NewsList = [];
        that.videosList = [];
      } else if (index == 2) {
        that.page = JSON.parse(JSON.stringify(that.pages))
        that.Player(that.page); //中国球员
        that.videosList = [];
      } else if (index == 3) {
        that.page = JSON.parse(JSON.stringify(that.pages))
        that.Player(that.page); //指定赛事
        that.videosList = [];
      } else if (index == 4) {
        that.page = JSON.parse(JSON.stringify(that.pages))
        that.Player(that.page); //联邦快递杯
        that.videosList = [];//联邦快递杯 
      }
    },
    NewsHot(page) {
      let that = this;
      console.log(page);
      let data = {
        current:page.currentPage,
        size: page.pageSize,
      };      
      getAxios({
        url: "/indexNewsHot",
        data: data,
      })
        .then((response) => {
          that.NewsList =that.NewsList.concat(response.records);
          if (response.records.length<12) {
              this.moreTxt = '暂无更多数据'
          }
        })
        .catch(() => {});
    },
    VideoHot(page) {
      let that = this;
      let data = {
        current:page.currentPage,
        size: page.pageSize,
      };         
      getAxios({
        url: "/indexVideoHot",
        data: data,
      })
        .then((response) => {
          console.log("response",response);
          that.videosList =that.videosList.concat(response.records);
          if (response.records.length<12) {
              this.moreTxt = '暂无更多数据'
          }          
        })
        .catch(() => {});
    },
    //中国球员
    Player(page) {
      let that = this;
      let data = {
        current: page.currentPage,
        size: page.pageSize,
      };
      if (that.navIndex == 2) {
        data.categoryName = "中国球员";
      }
      if (that.navIndex == 3) {
        data.categoryName = "指定赛事";
      }
      if (that.navIndex == 4) {
        data.categoryName = "联邦快递杯";
      }
      getAxios({
        url: "/getVideoListByCategoryName",
        data: data,
      })
        .then((response) => {
          console.log(response);
          that.videosList =that.videosList.concat(response.records);
          if (response.records.length<12) {
              this.moreTxt = '暂无更多数据'
          }
        })
        .catch(() => {});
    },
    // //指定赛事
    // majorPlayer(){
    //   let that = this
    //   getAxios({
    //     url:"/getVideoListByCategoryName",
    //     data:{
    //       categoryName:'指定赛事',
    //       current:1,
    //       size:8
    //     }
    //   }).then(response => {
    //     that.videosList = response.records
    //   }).catch(() => {
    //   })
    // },
    //  给组件传递相关被点击的事件
    play_new(id, classfy) {
      this.$emit("getRealtime", { id, classfy });    
    },
    getMore(){
        if (this.navIndex==0) {
          this.page.currentPage++
          this.NewsHot(this.page)  
        }
        if (this.navIndex==1) {
          this.page.currentPage++
          this.VideoHot(this.page)         
        }
        if (this.navIndex==2||this.navIndex==3||this.navIndex==4) {
          this.page.currentPage++
          this.Player(this.page)
        }
      },
    // scroll() {
    //                 let scroll = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;//页面滚动高度
    //                 let windowHeight=window.innerHeight;//窗口高度
    //                 let card_sectionTop=card_section.offsetTop;//card_section距离顶部的偏移高度（card_section为你的照片或div元素ID）
    //                 let card_sectionHeight=card_section.offsetHeight;//card_section的高度
    //                 if(card_sectionTop+card_sectionHeight<scroll+windowHeight){
    //                         //这里开始的你请求操作或者是显示数据
    //                 }
    //             }

    //  请求 视频分类数据 事件
    // async getVideos(bo){
    //   this.videoPage += bo
    //   let list = await getAxios({
    //     url:"/getVideoListByCategory",
    //     data:{
    //       category:this.top_title,
    //       page:this.videoPage,
    //       limit:20
    //     }
    //   })
    //   if(list < 20){
    //     this.videoB = false
    //     this.moreTxt='暂无更多数据'
    //   }else{
    //     this.videoB = true
    //     this.moreTxt='加载更多'
    //   }
    //   // console.log(list.length);
    //   if (bo===1 && list.length>0){
    //     this.videosList =this.videosList.concat(list)
    //   }else if(bo ===0){
    //     this.videosList = list
    //   }else {
    //     this.videoPage = 1
    //   }
    // },
    //  请求 新闻文章分类数据 事件
    //   async getNews(bo){
    //     let list = await getAxios({
    //       url:"/getArticleListByTag",
    //       data:{
    //         tag:this.top_title,
    //         page:this.newsPage,
    //         limit:20
    //       }
    //     })
    //     if(list < 20){
    //       this.newB = false
    //       this.moreTxt='暂无更多数据'
    //     }else{
    //       this.newB = true
    //       this.moreTxt='加载更多'
    //     }
    //     // console.log(list.length);
    //     if (bo===1 && list.length>0){
    //       this.NewsList =this.NewsList.concat(list)
    //     }else if(bo ===0){
    //       this.NewsList = list
    //     }else {
    //       this.newsPage = 1
    //     }
    //     // console.log(list);
    // },
    //  请求 全部分类数据 事件
    // async getAll(bo){
    //   // console.log(this.videoPage);
    //   let list = []
    //   list = await getAxios({
    //     url:"/categoryInfoList",
    //     data:{
    //       categoryName:this.top_title,
    //       page:this.allPage,
    //       limit:20
    //     }
    //   })
    //   if(list.length < 20){
    //     this.allB = false
    //     this.moreTxt='暂无更多数据'
    //   }else{
    //     this.allB = true
    //     this.moreTxt='加载更多'
    //   }
    //   if (bo===1 && list.length>0){
    //       this.allList = this.allList.concat(list)
    //   }else if(bo ===0){
    //     this.allList = list
    //   } else{
    //     this.allPage = 1
    //   }

    // },
    //  请求更多数据事件



    // getMore(){
    //   // console.log(this.allPage,this.allList,this.NewsList,this.videosList)
    //   if(this.activeArray[0]){
    //     if(this.allB){
    //         this.allPage++
    //         this.getAll(1)
    //     }
    //     // this.getVideos(1)
    //   } else if(this.activeArray[1]){

    //     if(this.newB){
    //       this.newsPage++
    //       this.getNews(1)
    //     }
    //   } else if(this.activeArray[2]){

    //     if(this.videoB){
    //       this.videoPage++
    //       // console.log(this.videoPage);
    //       this.getVideos(1)
    //     }
    //   }

    // },
  },
};
</script>

<style scoped lang="less">
.Realtime {
  width: 100%;
  background-color: #ffffff;
  margin-top: 60px;
  margin-bottom: 60px;
}
.all {
  z-index: 11;
  /*margin: 10px 0px 0 0px;*/
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
  /*flex-direction: column-reverse;*/
  .none_tip {
    width: 100%;
    text-align: center;
    line-height: 25px;
    padding: 0 15px 15px 15px;
    color: #777777;
    font-size: 17px;
  }
  .content_01 {
    width: 295px;
    position: relative;
    // border: 1px solid #eeeeee;
    padding: 15px 13.5px 15px 13.5px;
    border-bottom: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    cursor: pointer;
    img {
      width: 296px;
      height: 174px;
    }
    .icon_blur {
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -50px;
      margin-left: -20px;
      z-index: 3;
      width: 40px;
      height: 40px;
    }
    .content_text {
      width: 300px;
      line-height: 21px;
      font-size: 15px;
      color: #00284a;
      //font-family: My_Medium;
    }
  }
}
.get_more {
  background-color: #eeeeee !important;
  width: 100%;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  .shadow {
    position: absolute;
    top: -15px;
    left: 50%;
    margin-left: -136px;
    border-bottom-left-radius: 40px;
    border-bottom-right-radius: 40px;
    box-shadow: 0 5px 20px #000000;
    width: 260px;
    height: 40px;
    background-color: red;
  }
  .more {
    margin-left: -10px;
    z-index: 1;
    /*position: absolute;*/
    width: 250px;
    text-align: center;
    padding-left: 10px;
    font-size: 20px;
    line-height: 40px;
    background-color: #ffffff;
    //font-family: My_Bold;
    // box-shadow: 4px -15px 5px 2px #dddddd,-4px -15px 5px 2px #dddddd;
  }
  .icon-jiazaigengduo1 {
    // font-family: My_Thin;
    position: relative;
    right: -40px;
    font-size: 20px;
  }
}
  .btn_more{
      width: 100%;
      margin-top: 10px;
      /*border: 2px solid red;*/
      /*padding-top: 50px;*/
      // background-color: #eeeeee;
      // background-color: rgb(212, 218, 223);
      background-color: #DDDDDD;
      display: flex;
      justify-content: center;
      overflow: hidden;
      /*border-top: 1px solid #eeeeee;*/
      .btn{
        cursor: pointer;
        /*width: 200px;*/
        background-color: #ffffff;
        padding-top: 10px;
        font-size: 17px;
        text-align: center;
        color: #385570;
        padding-bottom: 10px;
        box-shadow: 2px -30px 6px #aaaa,-2px -30px 6px #aaaa;
        span{
          //font-family: My_Black;
          font-weight: 900;
          margin-left: 100px;

        }
        .icon-jiazaigengduo1{
          margin-left: 40px;
          margin-right: 50px;
        }
      }
    }
</style>