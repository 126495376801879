<template>
  <div class="tab">
    <div class="navTime">
      <div class="left_title">
        <span>{{title}}</span>热门
      </div>
      <div class="nav">
        <div class="nav_child " :class="{'active':active[0]}" @click="changeStyle(0)">
          热门新闻
        </div>
        <div class="nav_shadow1 nav_shadow" :class="{'nav_shadow_active':active[0]}" ></div>
        <div class="nav_child" :class="{'active':active[1]}" @click="changeStyle(1)">
          热门视频
        </div>
        <div class="nav_shadow nav_shadow2" :class="{'nav_shadow_active':active[1]}"  ></div>
        <div class="nav_child" :class="{'active':active[2]}" @click="changeStyle(2)">
          中国球员
        </div>
        <div class="nav_shadow nav_shadow3" :class="{'nav_shadow_active':active[2]}"  ></div>
        <div class="nav_child" :class="{'active':active[3]}" @click="changeStyle(3)">
          指定赛事
        </div>
        <div class="nav_shadow nav_shadow4" :class="{'nav_shadow_active':active[3]}"  ></div>
        <div class="nav_child" :class="{'active':active[4]}" @click="changeStyle(4)">
          联邦快递杯
        </div>               
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "navTime",
    props:{
      activeArray:Array,
      title:{
        type:String,
        default:''
      }
    },
    data(){
      return{
        active:this.activeArray,
        top_title:this.top_title
      }
    },
    watch: {
     //  activeArray: function(val) {
     //   this.activeArray = val
     // },
     //  conData: function (val) {
     //    console.log(val)
     //    this.$emit('content', val)
     //  }
    },
    methods:{
      changeStyle(index){
        this.$emit('changeIndex',index)
        for (let item in this.active){
          this.active[item] = false
        }
        this.active[index] = true
        // console.log(this.active);
        this.$forceUpdate();
      }
    }

  }
</script>

<style scoped lang="less">
  .tab{
    padding-top: 10px;
    // background-color: #eeeeee;
    // background-color: rgb(212, 218, 223);
    background-color: #DDDDDD;
    .navTime{
      background-image: linear-gradient(#ffffff 2px, #eeeeee);
      display: flex;
      /*overflow: hidden;*/
      border-bottom: 1px solid  #b3b3b3;
      height: 50px;
      .left_title{
        padding:10px 20px 10px 20px;
        flex: 3;
        height: 30px;
        letter-spacing: 2px;
        font-weight: bold;
        span{
          //font-family: My_Bold;
        }
      }
      .nav{
        flex: 11;
        margin-left: 14px;
        display: flex;
        align-items: center;
        font-size: 15px;
        line-height: 50px;
        position: relative;
        /*top: -5px;*/

        .nav_shadow{
          position: absolute;
          background-color: #eeeeee;
          width: 90px;
          height: 20px;
          top: -7px;
          /*z-index: 0;*/
          display: none;
          border-bottom-left-radius: 50px;
          border-bottom-right-radius: 50px;
          box-shadow: 0 10px 20px #000000;
        }
        .nav_shadow1{
          /*margin-left: -90px;*/
        }
        .nav_shadow2{
          margin-left: 94px;
        }
        .nav_shadow3{
          margin-left: 184px;
        }
        .nav_shadow4{
          margin-left: 276px;
        }
        .nav_shadow_active{
          display: block;
        }
        .nav_child{
          cursor: pointer;
          width: 90px;
          height: 100%;
          text-align: center;
          font-weight: bold;
          border-right: 1px solid #dddddd;
          border-left: 1px solid #dddddd;
          /*border: 2px solid red;*/
          z-index: 2;
        }
      }
    }
  }

  .active{
    border-left: 1px solid #b3b3b3;
    border-right: 1px solid #b3b3b3;
    border-top: 8px solid #053970;
    background-color: white;
    border-bottom: none;
    //font-family: My_Bold;
    position: relative;
    top: -3px;
    // left: 2px;
    height: 5px;
    z-index: 2;
    /*overflow: hidden;*/

  }
  /*.active:before{*/
  /*  content: "\5";*/
  /*  width: 20px;*/
  /*  left: -30px;*/
  /*  position: absolute;*/
  /*  background-color: #053970;*/
  /*}*/
</style>