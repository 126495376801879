import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  //  加载效果标志
    closeLoading:false,
    searchResultHasUpdate: false,
    screenWidth: 0,
  },
  mutations: {
    setSearchResultStatus(state, status) {
      this.state.searchResultHasUpdate = status
    },
    setScreenWidth(state, value) {
      this.state.screenWidth = value
    }
  },
  actions: {
  },
  modules: {
    setLoading(is){
      this.state.closeLoading = is
    }
  }
})
