<template>
  <div class="navLeaderBoard">
    <div class="line"></div>
    <div class="nav nav_pc">
      <router-link to="/Leaderboard/Leader">领先榜</router-link>
      <!-- <router-link to="/Leaderboard/HoleLocations">旗杆位置</router-link> -->
      <router-link   to="/Leaderboard/TeeTimes">开球时间</router-link>
      <router-link v-if="isShow" to="/Leaderboard/HoleMap">球洞图</router-link>
      <!-- <router-link to="/Leaderboard/Lineup">参赛阵容</router-link>
      <router-link to="/Leaderboard/PastResults">上一次参赛成绩</router-link> -->
    </div>
    <div class="nav nav_mobile" style="text-align: center;">
      <router-link to="/Leaderboard/LeaderMobile">领先榜</router-link>
      <router-link   to="/Leaderboard/TeeTimesMobile">开球时间</router-link>
      <router-link v-if="isShow" to="/Leaderboard/HoleMapMobile">球洞图</router-link>
    </div>

  </div>
</template>

<script>
  import { getHole,getSwitch } from '@/api/leaderboard/leaderboard'
  export default {
    name: "navLeaderBoard",
    data() {
      return {
          isShow:true
      }
    },
    created(){
      this.getSwitch()
    },
    methods: {
      getSwitch( ) {
        getSwitch('PGA').then(res => {
           this.isShow = res != null ? true:false 
        })
      },
    }
  }
</script>

<style scoped lang="less">
  .line{
    width: 100%;
    height: 20px;
    background-color: #eeeeee;
  }
  .nav{
    border-bottom: 1px solid #eeeeee;
    a{
      font-size: 16px;
      color: #000000;
      text-decoration: none;
      margin-right: 30px;
      margin-left: 30px;
      line-height: 60px;
      padding-top: 10px;
      padding-bottom: 10px;

      line-height: 36px;
      height: 100%;
      display: inline-block;

    }
    /*导航栏激活样式*/
    .router-link-active{
      border-bottom: 5px solid #013d7c;
      color: #013d7c;
      //font-family: My_Bold;
    }
  }

  @media screen and (max-width: 1200px) {
    .nav_pc {
      display: none;
    }
    .nav_mobile {
      display: block;
    }
  }
  @media screen and (min-width: 1200px) {
    .nav_pc {
      display: block;
    }
    .nav_mobile {
      display: none;
    }
  }
</style>