<template>
  <div class="navFedExCupNew">
    <div class="nav">
      <router-link to="/FedExCupNew/Standings">积分榜</router-link>
      <router-link to="/FedExCupNew/Information">资讯</router-link>
      <router-link to="/FedExCupNew/Overview">概况</router-link>
      <router-link to="/FedExCupNew/History">历届冠军</router-link>
    </div>
    <router-link to="/Players" class="ranking">康卡斯特前十排行榜 ></router-link>
  </div>
</template>

<script>
  export default {
    name: "navFedExCupNew",
    data() {
      return {
        
      }
    },
    created() {
      
    },
    methods: {
      
    }
  }
</script>

<style scoped lang="less">
  .navFedExCupNew {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 30px 0 0;
    border-bottom: 1px solid #eeeeee;
  }
  .nav{
    flex: 1;
    a{
      font-size: 16px;
      color: #000000;
      text-decoration: none;
      margin-right: 30px;
      margin-left: 30px;
      line-height: 60px;
      padding-top: 10px;
      padding-bottom: 10px;

      line-height: 36px;
      height: 100%;
      display: inline-block;

    }
    /*导航栏激活样式*/
    .router-link-active{
      border-bottom: 2px solid #013d7c;
      color: #013d7c;
      //font-family: My_Bold;
    }
  }
  .ranking {
    font-weight: bold;
    color: #013d7c;
    font-size: 18px;
  }
</style>