<template>
  <div id="foot">
    <div class="top">
      <span class="bold">友情链接</span>
      <span class="ml20"><a href="https://www.pgatour.com/" target="_blank">PGATOUR</a></span>
      <span class="ml20"><a href="https://www.golfbox.cn/" target="_blank">高尔夫频道</a></span>
      <span class="ml20"><a href="https://www.itouchtv.cn/" target="_blank">触电传媒</a></span>
    </div>
    <!-- 中间 -->
    <el-row class="bot">
      <el-col :span="6">
        <div>高尔夫几何（广州）信息科技有限公司</div>
        <div>地址：中国广东省广州市天河区华明路四号  </div>
        <div>电话：4008811010<span class="ml10">邮编：510630</span></div>
        <div>违法和不良信息举报电话：020-32300521</div>
      </el-col>
      <el-col :span="8">
        <div>粤 ICP备09113169号</div>
        <div>信息网络传播视听节目许可证号：1905119</div>
        <div>广播电视节目制作经营许可证：（粤）字第04466号</div>
        <div>网上有害信息举报专区：<a href="https://www.12377.cn/" target="_blank">中国互联网违法和不良信息举报中心</a></div>
      </el-col>
      <el-col :span="10">
        <div>版权：高尔夫频道（golfbox.cn）版权归属高尔夫几何(广州)信息科技有限公司所有</div>
        <div>合作机构：广东网络广播电视台<span class="ml10">广东触电传媒科技有限公司</span></div>
      </el-col>
    </el-row>
    <el-row class="bot">
      <el-col :span="24">本网站除去使用条款中所特别提及的部分之外，其它任何内容都不得以任何形式被复制、再传播，或篡改。</el-col>
    </el-row>

    <!-- 新版底部 -->
    <div class="footNav" v-if="false">
      <div>合作伙伴：    
        <span class="ml20"><a class="textWhite" href="https://www.itouchtv.cn/" target="_blank">触电新闻</a></span>
        <span class="ml20"><a class="textWhite" href="https://www.snm.gd.cn/" target="_blank">南方新媒体</a></span>
        <span class="ml20"><a class="textWhite" href="https://www.gdtv.cn/" target="_blank">荔枝网</a></span>
        <span class="ml20"><a class="textWhite" href="http://pgatour.golfbox.cn/" target="_blank">美巡中文网</a></span>
      </div>
      <br>
      <div>友情链接：    
        <span class="ml20"><a class="textWhite" href="https://sj.qq.com/" target="_blank">应用宝</a></span> 
        <span class="ml20"><a class="textWhite" href="https://developer.huawei.com/consumer/cn/" target="_blank">华为开发联盟</a></span> 
        <span class="ml20"><a class="textWhite" href="https://dev.mi.com/console/" target="_blank">小米开放平台</a></span> 
        <span class="ml20"><a class="textWhite" href="https://www.oppo.com/cn/" target="_blank">OPPO</a></span> 
        <span class="ml20"><a class="textWhite" href="https://www.vivo.com.cn/" target="_blank">vivo</a></span> 
        <span class="ml20"><a class="textWhite" href="https://www.lenovo.com.cn/" target="_blank">联想</a></span> 
        <span class="ml20"><a class="textWhite" href="https://mobile.baidu.com/" target="_blank">百度手机助手</a></span> 
        <span class="ml20"><a class="textWhite" href="http://ext.se.360.cn/" target="_blank">360应用市场</a></span> 
        <span class="ml20"><a class="textWhite" href="https://app.meizu.com/" target="_blank">魅族应用中心</a></span>     
        <span class="ml20"><a class="textWhite" href="http://www.dangbei.com/" target="_blank">当贝市场</a></span> 
        <span class="ml20"><a class="textWhite" href="http://www.shafa.com/" target="_blank">沙发管家</a></span> 
        <span class="ml20"><a class="textWhite" href="http://so.7po.com/" target="_blank">奇珀网</a></span> 
        <span class="ml20"><a class="textWhite" href="https://www.coocaa.com/" target="_blank">酷开</a></span> 
        <span class="ml20"><a class="textWhite" href="http://www.tvhuan.com/" target="_blank">欢视网</a></span> 
        <span class="ml20"><a class="textWhite" href="http://www.fengmanginfo.com/" target="_blank">蜜蜂市场</a></span>
      </div>
      <div class="footerLogo">
        <img src="../../assets/images/footerLogo1.png" alt="" class="footerLogoItem">
        <img src="../../assets/images/footerLogo2.png" alt="" class="footerLogoItem">
        <img src="../../assets/images/footerLogo3.png" alt="" class="footerLogoItem">
      </div>
      <div class="aboutUs">
        <span>关于我们 | </span>  
        <span>联系我们 | </span>  
        <span>产品介绍 | </span>  
        <span>用户服务协议 | </span>  
        <span>隐私政策  </span>
        <span class="ml20">© 2021 高尔夫几何（广州）信息科技有限公司 <a class="textWhite" href="https://beian.miit.gov.cn/" target="_blank">粤ICP备09113169号</a></span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "foot"
  }
</script>

<style scoped lang="less">
  #foot{
    overflow: hidden;
    width: 100%;
    // height: 200px;
    // padding: 40px 0;
    padding: 90px 0 160px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #ffffff;
    color: #101010;
    // background: #344758;
    // color: #ffffff;
    font-size: 14px;
  }
  .footNav {
    // display: flex;
    text-align: center;
  }
  .footerLogo {
    margin: 45px 0 32px 0;
  }
  .footerLogoItem {
    background-image: none;
    margin: 0 16px;
  }
  .aboutUs {
    font-size: 12px;
  }

  .top{
    // width: 70%;
    width: 1300px;
    padding: 0 0 20px 5px ;
    border-bottom:2px solid #D0D0D0;
    font-size: 12px;
  }
  .bot{
    padding-top: 20px;
    // width: 70%;
    width: 1300px;
    font-size: 12px;
  }
  .bot a,
  .top a{
    color: #101010;
  }
  .bot .el-col{
    line-height: 23px;
  }
  .ml10{
    margin-left: 10px;
  }
  .ml20{
    margin-left: 20px;
  }
  .bold{
    font-weight: bold;
  }
  .textWhite {
    color: white;
  }
  .textWhite:hover {
    text-decoration: underline;
  }
  // 手机端时样式更改
  @media screen and (max-width: 1200px) {
    #foot{
      font-size: 12px;
      padding: 90px 0;
    }
  }
</style>
