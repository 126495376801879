<template>
<!-- 视频也的栏目组件 -->
  <div class="Column" >
    <div class="nav">
      <div class="nav_left">
        <!-- <div class="nav_col csp" v-for="(item, index) of CategoryType" :key="index" @click="changeNav(item.videoCategoryId,item.videoCategoryName)" :class="{'bg':num_index==item.videoCategoryId}">
          <span >{{item.videoCategoryName}}</span>
        </div> -->
        
        <div class="nav_col csp" v-for="(item, index) of categoryList" :key="index" @click="changeCategory(index,item)" :class="{'bg':num_index==index}">
          <span >{{item}}</span>
        </div>
      </div>
      <div class="nav_right">
        <input class="iconfont icon-icon-search" v-model="searchVideo"  placeholder="搜索视频" @keyup.enter="search()"/>
        <span v-if="searchVideo!==''" class="iconfont icon-guanbi1" @click="delete_in_search"></span>
        <span @click="search()">搜索</span>
      </div>
    </div>
    <div class="content" ref="Column">
      <!-- <div class="content_title" v-show="searching">
        {{num_txt}}
      </div> -->
      <div class="content_video">
        <div class="video_num" v-for="(item, index) of videosColumn" :key="index" @click="selectVideo(item.videoId)">
          <a href="javascript:;" >
            <div class="img_span">
              <img  :src="item.videoPostUrl" alt="">
              <span class="iconfont icon-bofang video_text"></span>
            </div>
            <p>{{item.videoName.slice(0,30)}}</p>
          </a>
        </div>
      </div>


    </div>
    <div class="btn_more">
      <div class="btn" @click="getMore()">
        <span>{{moreTxt}}</span>
        <span class="icon-jiazaigengduo1 iconfont" style="visibility: hidden;"></span>
      </div>
    </div>
  </div>


</template>

<script>
  import Botton from "./botton/botton";
  import getAxios from "../request";
  export default {
    name: "Column",
    components: {
      Botton
    },
    data(){
      return {
        //导航栏状态
        isSelect:[
          true,false,false,false
        ],
        //  栏目标志
        num_index:0,
        num_txt:'',
        moreTxt:'加载更多',
        isMore:true,
        // 搜素数据
        searchVideo:'',
      //  栏目视频
        videosColumn:[],
      //  搜索的情况下是否隐藏导航标志
        searching:true,
      //  栏目请求页数
        pages:[1,1,1,1],
        pageNum:1,
        CategoryType:[],
        categoryList:[ '比赛', '采访', '花絮', '专题'],
        categoryName: '',
        tempCategoryName: ''
      }
    },
    mounted: function () {
      this.videoCategoryFn()
      this.changeCategory( 0, '比赛')
    },
    created() {
      // this.getVideosCol()
      console.log("created");
      getAxios({
          url:'/getVideoListByName',
          data:{
            videoName:this.searchVideo,
            page:this.pageNum,
            limit:9
          }
        }).then(response=>{
          this.videosColumn = response
        })
      
    },
    watch:{
      categoryName( newVal ){
        if( newVal !== '' ){
          this.videosColumn = []
          this.tempCategoryName = newVal //每次点击存一个值和下一个值做对比
        }
      }
    },
    methods:{
      //请求数据
      async getVideosCol(index){
        // let num = this.pages[index]
        // let list = await getAxios({
        //   url:'/getVideoListByCategory',
        //   data:{
        //     page: this.pageNum,
        //     limit: 12, 
        //     category: this.num_index
        //   }
        // })
        // if(this.pageNum>1){
        //   this.videosColumn = this.videosColumn.concat(list)
        // } else{
        //   this.videosColumn = list
        // }
        // if (list.length>0){
        //   return list.length
        // }else if (list.length<0){
        //   return 0
        // }
        let num = this.pages[index]
        let list = await getAxios({
          url:'/getVideoListByCategory',
          data:{
            page: this.pageNum,
            limit: 12,
            category: this.num_index
          }
        })
        if(this.pageNum>1){
          this.videosColumn = this.videosColumn.concat(list)
        } else{
          this.videosColumn = list
        }
        if (list.length>0){
          return list.length
        }else if (list.length<0){
          return 0
        }

      },
      async videoCategoryFn(){
        let Res = await getAxios({
          url:'/videoCategory',
          data:{}
        })
        //this.num_index = Res[0].videoCategoryId
        //this.num_txt = Res[0].videoCategoryName
        this.CategoryType = Res
      },
      //导航栏事件
      changeNav(index,name){
        console.log("index",index)
        this.num_txt = name
        this.num_index = index
        this.getVideosCol(index)
        this.pages[index] = 1
        //可以请求栏目数据后，记得改这里哦
        this.videosColumn = []
        this.searchVideo = ''
        this.searching = true
        this.$forceUpdate()
      },
    //  加载更多事件
      getMore(){
        if(this.isMore && this.searchVideo == '' ){
          this.pageNum++
          this.changeCategory( this.num_index,this.categoryName )
        }
        if (this.isMore && this.searchVideo!="") {
          this.pageNum ++
          this.searchDataFn()
        }
        // for (let i=0 ; this.pages.length>i;i++){
        //   if (this.pages[i]){
        //     this.pages[i]++
        //   }
        // }
        // // console.log(this.getVideosCol())
        // if(this.getVideosCol()>0){
        //   this.$emit('changeHeight',this.videosColumn.length)
        //   // this.$emit('changeHeight',6)
        // }else {
        //   this.pageNum--
        //   this.$emit('changeHeight',1)
        // }

      },
      
    //  把要播放的视频id传给父组件
      selectVideo(id){
        this.$emit('columnVideoid', id)
      },
    //  触发搜索数据事件
      search(){
        // this.findAll()
        this.pageNum = 1
        this.searchDataFn()
      },
      //清除搜索蓝数据
      delete_in_search(){
        this.searchVideo = ''
        this.searching = true
        this.videosColumn = []
        this.changeCategory( 0, '比赛')
        // this.getVideosCol()
      },
    //  搜索请求数据事件
      // async findAll(){
      //   console.log(32)
      //   console.log(this.page,this.isSelect)
      //   let pageNum = this.page[this.isSelect.indexOf(true)]
      //   console.log(pageNum);
      //   this.videosColumn =await getAxios({
      //     url:'/getVideoListByName',
      //     data:{
      //       videoName:this.searchVideo,
      //       page:pageNum,
      //       limit:20
      //     }
      //   })
      //   this.searching = false
      // },
       //搜索请求数据事件
      async searchDataFn(){
        let videos =await getAxios({
          url:'/getVideoListByName',
          data:{
            videoName:this.searchVideo,
            page:this.pageNum,
            limit:9
          }
        })
        
        console.log('videos: ', videos);
        if(this.pageNum>1){
          this.videosColumn = this.videosColumn.concat(videos)
        } else{
          console.log(videos)
          this.videosColumn = videos
        }

        if(videos.length < 9){
          this.moreTxt='暂无更多数据'
          this.isMore = false
        }else{
          this.moreTxt='加载更多'
          this.isMore = true
        }
        this.searching = false//是否隐藏导航
      },
      async changeCategory( index, val ){
        this.categoryName = val
        if(this.categoryName !== this.tempCategoryName  ){
          this.pageNum = 1
        }
        this.num_index = index
        let list = await getAxios({
          url:'/getVideoListByCategoryName',
          data:{
            current: this.pageNum,
            size: 9,
            categoryName: val,
          }
        })
        if(this.pageNum!=1){
          this.videosColumn = this.videosColumn.concat(list.records)
        } else{
          this.videosColumn = []
          this.videosColumn = list.records
        }
        if(list.records.length < 9){
          this.moreTxt='暂无更多数据'
          this.isMore = false
        }else{
          this.moreTxt='加载更多'
          this.isMore = true
        }
      }
    }
  }
</script>

<style scoped lang="less">
  .Column{
    width: 100%;
    padding: 40px 0 0 0 ;
    position: relative;
    background-color: #ffffff;
    /*border: 1px solid red;*/
    .nav{
      display: flex;
      overflow: hidden;
      /*height: 35px;*/
      margin-right: 20px;
      margin-left: 10px;
      border-bottom: 1px solid #dddddd;
      padding-bottom: 30px;
      .nav_left{
        flex: 4;
        display: flex;
        /*width: 100%;*/
        .nav_col{
          
          font-size: 24px;
          margin-right: 60px;
          padding-bottom: 3px;
          span{
            //font-family: My_Medium;
            font-size: 16px;
          }
          /*padding: 0 0 5px 0;*/
        }
        /*四个栏目确定后把下面的使用*/
        /*.nav_col:nth-last-child(-n+3){*/
        /*  margin-left: 50px;*/
        /*}*/
      }
      .nav_right{
        flex: 2;
        overflow: hidden;
        border: 1px solid #777777;
        display: flex;
        margin-right: 30px;
        font-size: 17px;
        input{
          flex: 7;
          height: 100%;
          outline: none;
          padding-left: 5px;
        }
        span{
          flex: 2;
          float: right;
          text-align: center;
          line-height: 40px;
          border-left: 1px solid #777777;
        }
        .icon-guanbi1{
          width: 10px;
          border-left: none;
          font-size: 10px;
        }
      }
    }
    .content{
      .content_title{
        margin-top: 10px;
        margin-left: 10px;
        font-size: 28px;
        //font-family: My_Bold;
        color: #0b366a;
      }
      .content_video{
        margin-right: 20px;
        /*padding-top: 20px;*/
        color: #011D35 ;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        /*border: 2px solid red;*/
        .video_num{
          width: 271px;
          /*height: 153px;*/
          padding: 13px;
          // border: 1px solid #dddddd;
          border-top: none;
          a{
            color: #000000;
            text-decoration: none;
            .img_span{
              position: relative;
              /*border: 2px solid red;*/
              img{
                background-color: #777777;
                width: 100%;
                height: 153px;
              }
              .video_text{
                color: #eeeeee;
                left: 50%;
                font-size: 40px;
                margin-left: -25px;
                position: absolute;
                line-height: 160px;
                height: 160px;
              }
            }
            p{
              /*border: 1px solid red;*/
              color: #153858;
              height: 40px;
              //font-family: My_Medium;
              font-size: 15px;
              margin-top: 5px;
              letter-spacing: 1px;
            }

          }
        }
      }

    }


  }
  .btn_more{
      width: 100%;
      margin-top: 10px;
      padding-bottom: 10px;
      /*border: 2px solid red;*/
      /*padding-top: 50px;*/
      // background-color: #eeeeee;
      display: flex;
      justify-content: center;
      overflow: hidden;
      /*border-top: 1px solid #eeeeee;*/
      .btn{
        cursor: pointer;
        /*width: 200px;*/
        background-color: #ffffff;
        padding-top: 10px;
        font-size: 17px;
        text-align: center;
        color: #385570;
        padding-bottom: 10px;
        // box-shadow: 2px -30px 6px #aaaa,-2px -30px 6px #aaaa;
        span{
          //font-family: My_Black;
          font-weight: 900;
          // margin-left: 100px;

        }
        .icon-jiazaigengduo1{
          margin-left: 40px;
          margin-right: 50px;
        }
      }
    }
  .bg{
    border-bottom: 4px solid #0b366a;
    span{
      //font-family: My_Bold!important;
      color: #0b366a;
      font-weight: bold;
    }

  }
</style>