import { render, staticRenderFns } from "./Fedexcup.vue?vue&type=template&id=53e77cfc&scoped=true&"
import script from "./Fedexcup.vue?vue&type=script&lang=js&"
export * from "./Fedexcup.vue?vue&type=script&lang=js&"
import style0 from "./Fedexcup.vue?vue&type=style&index=0&id=53e77cfc&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53e77cfc",
  null
  
)

export default component.exports