<template>
  <div class="leaderCard">
    <div class="line_shadow"></div>
    <div class="shell">
      <div class="left_header">
        <img :src="headImg" alt="">
        <!-- <p>{{playerName}}</p> -->
        <p class="playerEname">{{playerEname}}</p>
        <div class="allCard">
          <!-- <a  :href="url">完整成绩卡</a> -->
          <router-link :to="{path:'/FullScorecard',query:{playerName,playerEname,country,playerId,headImg}}" >完整成绩卡</router-link>
        </div>
      </div>
      <div class="line_cut"></div>
      <div class="center_detail">
        <div class="leaderCard_nav">
          <div class="nav_child" :class="{'active':cardIndex===1}" @click="changeNav(1)" >记分卡</div>
          <div style="margin-top: 20px">HOLE {{leaderValue.hole}} Par {{leaderValue.par}}, {{leaderValue.yardage}}yds</div>
          <!-- <div class="nav_child" :class="{'active':cardIndex===2}" @click="changeNav(2)" >数据</div> -->
        </div>
        <div class="leaderCard_content">
          <!--击球追踪页-->
          <Shot-tradker ref="Shot_tradker" :playerid="playerId?playerId:'0'" v-if="cardIndex===1" @getValue="getValue"  ></Shot-tradker>
          <!--数据页-->
          <Statstics v-else-if="cardIndex===2"></Statstics>
        </div>
      </div>


      <div class="line_cut"></div>
      <div class="right_other">
        <advert :location="'scorecard'"></advert>
      </div>
    </div>
    <div class="line_shadow line_shadow2"></div>
  </div>
</template>

<script>
  import ShotTradker from "./child/ShotTradker";
  import Statstics from "./child/Statstics";
  import pgaApi from "../../request/pgaApi";
  import FullScorecard from "/src/views/FullScorecard/index.vue";
  import Advert from '../Advert.vue';
  export default {
    name: "index",
    components:{
      ShotTradker,
      Statstics,
      FullScorecard,
      Advert,
    },
    props:{
      playerName:{
        type:String,
        default:''
      },
      playerEname:{
        type:String,
        default:''
      },
      country:{
        type:String,
        default:''
      }
    },
    data() {
      return {
        cardIndex:1,//导航条索引
        playerId:0,//用户ID
        activeIndex:1,//第几轮比赛索引
        headImg: '',
		    url:'/FullScorecard',
        leaderValue:{
          hole:'',
          par:'',
          yardage:''
        }
      }
    },
    created() {
      this.changeNav(this.cardIndex)
    },
    methods: {
      /**
       * 请求积分卡数据
       * */
      getCardFather(playerId){
        this.playerId = playerId
		    this.url = this.url+"?playerId="+playerId
        this.$refs.Shot_tradker.getCard(playerId)
        this.getCardData()
      },
      /**
       * 导航条切换事件
      * */
      changeNav(i){
        this.cardIndex = i
      },
      /**
       * 拿头像
       * */
      async getCardData(){
        let list = await pgaApi({
          url:'/getScoreCard',
          data:{
            playerId: this.playerId,
            roundNumber: 1
          }
        })
        if( list.length>0 ){
          this.headImg = "https://pga-tour-res.cloudinary.com/image/upload/c_fill,f_auto,g_face:center,h_160,q_auto,t_headshots_leaderboard_l,w_160/headshots_"+this.playerId+".png"
          
        }
      },
      getValue(payload){
        console.log('payload: ', payload);
        this.leaderValue = payload
      }
    }
  }
</script>

<style scoped lang="less">
  .leaderCard{
    position: relative;
    width: 1223px;;
    height: 310px;
    border: none;
    margin: 0;
    /*overflow: hidden;*/
    .line_shadow{
      position: absolute;
      width: 1226px;
      height: 8px;
      top: -2px;
      left: -1px;
      z-index: 2;
      background-image: linear-gradient(#111111,#323232);
    }
    .line_shadow2{
      left: -1px;
      top: auto;
      bottom: -2px;
      background-image: linear-gradient(#323232,#111111);
    }
    .shell{
      background-color: #333333;
      position: absolute;
      width: 1226px;
      height: 310px;
      left: -1px;
      bottom:-1px;
      display: flex;
      color: #ffffff;
      .left_header{
        width: 140px;
        height: 100%;
        padding: 6px;
        img{
          width: 128px;
          height: 128px;
          background-color:#cbcbcb;
          border-radius: 50%;
          margin-top: 10px;
        }
        p{
          width: 128px;
          color: #dddddd;
          text-align: center;
          font-size: 13.5px;
        }
        .playerEname{
          font-size: 12px;
        }
         .allCard{
           cursor: pointer;
           margin-top: 10px;
           background-color: #525252;
           display: flex;
           width: 100%;
           justify-content: center;
           a{
             height: 27px;
             line-height: 27px;
             width: 100px;
             text-decoration: none;
             color: #ffffff;
             font-size: 12px;
             //font-family: My_Medium;
           }
         }
      }
      .line_cut{
        width: 1px;
        height: 300px;
        position: relative;
        top: 0;
        z-index: 4;
        background-color: #404040;
      }
      .center_detail{
        width: 790px;
        .leaderCard_nav{
           display: flex;
          justify-content: left;
          .nav_child{
            margin: 15px 10px 0 10px;
            padding: 0 10px 10px 10px;
            color: #9c9d98;
            cursor:pointer;
            border-bottom: 4px solid transparent;
          }
          .active{
            color: #ffffff;
            //font-family: My_Medium;
            border-bottom: 4px solid #696969;

          }
        }
        .leaderCard_content{}
      }
      .right_other{
        width: 295px;
        padding: 20px;
      }
    }
  }
</style>