<template>
  <div>

    <div class="shell">
      <div class="shell_content">
        <!-- 顶部 -->
        <div class="top">
          <div class="banner">
            <div class="df-sb bg-fff" :class="screenWidth > 900 ? ['pt30','pb30','pl30','pr30'] : ['pt10','pb10','pl10','pr10']">
              <div class="info">
                  <h2 :class="screenWidth > 900 ? 'name' : 'name_mobile'">
                    {{match.matchName}}
                  </h2>
                  <div :class="screenWidth > 900 ? 'info-data-container' : 'info-data-container-mobile'">
                    <div class="info-data">
                        <span class="dates">{{match.matchTime}}</span>
                    </div>
                </div>
              </div>
              <div class="img-r">
                <img :src="match.matchPic" alt="" :class="screenWidth > 900 ? 'img_r_imgpc' : 'img_r_imgmobile'">
              </div>
            </div>
            <div class="mt2 time-table bg-fff df" :class="screenWidth > 900 ? ['pt30','pb30','pl30','pr30'] : ['pt10','pb10','pl10','pr10']">
              <img class="img" src="../../assets/images/PinDao.jpg" alt="">
              <div class="df ml10 b-eee csp" @click="OpenDialog">
                <p class="txt2">高尔夫频道直播时间表 ></p>
              </div>
              <!-- <a style="flex: 1;text-align: right;padding-right: 35px;" href="https://www.pgatour.com/tournaments/2023/barbasol-championship/R2023518" target="_blank">请点击这里查看巴巴索锦标赛成绩榜</a> -->
            </div>
          </div>
          <div class="content" v-show="OpenDialogVisible">
              <playtime ref="playtime"  class="play_time" :playtime="playtime" :title="title" :subTitle="subTitle" :isDisplay="isDisplay" @changeData="changeData"/>
          </div>
        </div>
        <!-- 导航栏 -->
        <navLeaderBoard></navLeaderBoard>
        <router-view/>
        <!-- 底部 -->
      </div>
    </div> 
  </div>
</template>

<script>
  import playtime from "../../components/PlayTime"
  import navLeaderBoard from "../../components/navigation/navLeaderBoard";
  import getAxios from "../../request";
  import { getTVTime } from '@/api/leaderboard/leaderboard'
  import store from '@/store'

  export default {
    name: "Leaderboard",
    components:{
      playtime,navLeaderBoard
    },
    data(){
      return{
        // 传给子组件的播出时间表数据
        playtime:[],
        title: '',
        subTitle: '',
        isDisplay:false,
        OpenDialogVisible:false,
        tableLoading:true,
        match:{
          matchName:'',
          matchPic:'',
          matchTime:''
        },

      }
    },
    created() {
      this.MatchInfo()
    },
    computed: {
      screenWidth() {
        return store.state.screenWidth
      }
    },
    methods:{
      openPlay(){
        this.isDisplay = true
        // console.log(this.playtime.length);
        if (this.playtime.length<=3){
          this.$refs.playtime.iscloseDowm()
        }
      },
      OpenDialog(){
        this.OpenDialogVisible = true
        this.getMatchTvTime()
      },
      changeData(e){
        this.isDisplay = e
        this.OpenDialogVisible = e
        console.log(e);
      },
      MatchInfo(){
        let that = this
        getAxios({
          url:"/leaderboardMatchInfo",
          data:{}
        }).then(response => {
          that.match = response
          // that.NewsList = response
        }).catch(() => {
        })
      },
      //请求球洞图片列表
      getMatchTvTime( ) {
        this.tvObj = {}
        getTVTime().then(res => {
			console.log(res)
          //console.log( res )
          if( res.list.length>0 ){
            this.playtime = res.list
            this.title = res.title
            this.subTitle = res.subTitle
          } 
        })
      },
    }
  }
</script>

<style scoped lang="less">

 .top{
   background-color: #eeeeee;
   img{

     width: 100%;
     height: 100%;
   }
    /*margin: 30px 40px 0 40px ;*/
    .top_title{
      padding-bottom: 15px;
      border-bottom: 2px solid #eeeeee;
      display: flex;
      .left{
        flex: 6;
        .head_title{
          font-size: 25px;
          font-weight: 600;
          letter-spacing: 1px;

        }
        .head_subtitle{
          padding-top: 5px;
          font-size: 15px;
        }
      }
      .right{
        background-color: #eeeeee;
        flex: 1;
        line-height: 80px;
        text-align: center;
        height: 80px;
        img{
         width: 100%;
         height: 80px;
        }
      }
    }
    .top_photo{
      margin-top: 30px ;
      padding-bottom: 20px;
      border-bottom: 1px solid #eeeeee;
      display: flex;
      .top_photo_shell{
        margin-right: 50px;
        display: flex;
        .head_logo{
          height: 40px;
          img{
            height: 100%;
          }
        }
        .btn{
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          font-size: 10px;
          margin-left: 15px;
          padding: 0;

          .btn_left{
            border: 2px solid #cccccc;
            padding: 5px;

          }
          .btn_right{
            padding: 5px;
            border: 2px solid #cccccc;
            border-left: 0;
            background-color: #053970;
            color: white;
          }
        }
      }

    }
  }
.banner{
  border-bottom: 1px solid #eeeeee;
  // padding: 30px;
  // background: #fff;
  .bg-fff{
    background: #fff;
  }
  .mt2{
    margin-top: 2px;
  }
  .name{
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 1px;
    a{
      color: #000;
      // font-family: My_Thin;
    }
  }
  .name_mobile{
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 1px;
    a{
      color: #000;
      // font-family: My_Thin;
    }
  }
  .info-data-container {
    font-size: 16px;
  }
  .info-data-container-mobile {
    font-size: 13px;
  }
  .img-r{
    .img_r_imgpc{
      width: auto;
      max-width: 220px;
      max-height: 75px;
      background-image:none;
    }
    .img_r_imgmobile{
      width: auto;
      max-width: 125px;
      max-height: 43px;
      background-image:none;
    }
  }
  .time-table{
      .img{
        height: 50px;
        width: 50px;
        border-radius: 50px;
      }
      .b-eee{
        border:1px solid #eeeeee;
        border-radius: 7px;
      }
      .txt1{
         padding: 5px 10px;
         background: #fff;
         font-size: 14px;
      }
      .txt2{
         padding: 5px 10px;
         color: #fff;
         background-color: #00284b;
         font-size: 14px;
         border-radius: 0 7px 7px 0;
      }
  }
}
.match{
  .el-dialog{
    background: red;
    .el-dialog__body{
      background: red;
      padding: 0px 20px!important;
    }
  }
}
.content{
  position: fixed;
    z-index: 1000;
    overflow: auto;
    background-color: rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.play_time{
    position: absolute;
    z-index: 100;
    // width: 500px;
    height: 500px;
    background-color: #fff;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    line-height: 1.5;
    // border-radius: 5px;
}

@media screen and (max-width: 1200px) {
  .shell_content {
    width: 100%;
  }
}
</style>
