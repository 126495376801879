<template>
  <div class="AllSchedules">
    <div class="space">
    </div>
    <div class="content">
      <div class="tap">
        <div class="nav">
          <div class="nav_child" :class="{'active':naIndex===1}" @click="changeNav(1)">
            <span>美巡赛</span>
          </div>
          <div class="nav_child" :class="{'active':naIndex===2}" @click="changeNav(2)">
            <span>美巡长青赛</span>
          </div>
          <div class="nav_child" :class="{'active':naIndex===3}" @click="changeNav(3)">
            <span>光辉国际巡回赛</span>
          </div>
          <div class="nav_child" :class="{'active':naIndex===4}" @click="changeNav(4)">
            <span>美巡中国系列赛</span>
          </div>
        </div>

      </div>

      <div class="btm">
        <div class="btm_content">
          <div class="left">
            <div class="left_left">
              <p>{{subTitle[naIndex-1]}}</p>
              <h3>这是赛事名称</h3>
              <p class="p_span">
                <span>日期</span>
                <span>奖金：</span>
              </p>
            </div>
            <div class="left_right">
              <img src="" alt="">
            </div>

          </div>
<!--          右边-->
          <div class="right">
            <p>主要内容</p>
            <div class="news">
              <div class="new">
                <img src="" alt="">
                <p>这是个标题</p>
              </div>
              <div class="new">
                <img src="" alt="">
                <p>这是个标题</p>
              </div>
              <div class="new">
                <img src="" alt="">
                <p>这是个标题</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  export default {
    name: "AllSchedules",
    data(){
      return{
        naIndex:1,
        //  美巡赛事栏（非实时栏）
        subTitle:[
            '本周赛事',
            '上个赛事',
            '未来赛事',
            '本周赛事'
        ]
      }
    },
    methods:{
    //  导航栏事件
      changeNav(i){
        this.naIndex = i
      }


    }

  }
</script>

<style scoped lang="less">
  .AllSchedules{
    .space{
      height: 40px;
    }
    .content{
      background-color: #ffffff;
      .tap{
        display: flex;
        align-items: center;
        height: 50px;
        border-bottom: 1px solid  #b3b3b3;
        background-image: linear-gradient(#ffffff 2px, #eeeeee);
        .nav{
          display: flex;
          align-items: center;
          font-size: 15px;
          line-height: 50px;
          /*position: relative;*/
          /*top: -5px;*/
          .nav_child{
            padding: 0 20px 0 20px;
            /*width: 90px;*/
            /*height: 100%;*/
            text-align: center;
            /*border: 2px solid red;*/
          }
        }
      }
      .btm{
        padding: 20px 30px 20px 30px;
        img{
          width: 100%;
          height: 100%;
        }
        .btm_content{
          display: flex;
          .left{
            flex: 2;
            display: flex;
            overflow: hidden;
            .left_left{
              flex: 2;
              p{
                font-size: 5px;
                padding-bottom: 10px;
              }
              .p_span{
                padding-top: 10px;
                span:nth-child(2){
                  padding-left: 5px;
                }
              }
            }
            .left_right{
              flex: 1;
              /*float: right;*/
              background-color: #777777;
              width: 100px;
              height: 60px;
              margin: 20px 20px 0 30px;
            }
          }
          .right{
            flex: 5;
            padding-left: 10px;
            p{
              font-size: 5px;
            }
            .news{
              display: flex;
              padding: 20px 0 30px 0;
              .new{
                flex: 1;
                width: 160px;
                height: 150px;
                margin: 0 30px 0 0;
                img{
                  /*padding: 0 30px 0 20px;*/
                  background-color: #777777;
                }
                p{
                  padding-top: 10px;
                }
              }
            }
          }
        }
      }
    }

  }
  .active{
    position: relative;
    height: 55px;
    border-bottom: none;
    bottom: 5px;
    font-weight: 600;
    border-top: 8px solid #053970;
    background-color: #ffffff;
  }
</style>