<template>
  <div class="advert">
    <!-- 广告图 -->
    <div class="ad cp" v-if="advert.picUrl">
      <img :src="advert.picUrl" alt="">
    </div>
  </div>
</template>

<script>
  import getAxios from "../request";
  export default {
    name: "advert",
    data(){
      return {
        advert: {}
      }
    },
    props: ['location'],
    mounted(){
      // console.log( this.location )
      this.getAdvert()
    },
    methods:{
      // 发送请求数据
      async getAdvert(){
        this.advert = await getAxios({
          url:"/ad?location=" + this.location,
        })
        // console.log(this.advert)
      },
    }
  }
</script>

<style scoped lang="less">
  .advert{
    height: 100%;
    width: 100%;
    position: relative;
    .ad{
      margin-top: 0px;
      bottom: 15px;
      width: 100%;
      height: 100%;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .cp{
    cursor: pointer;
  }
</style>