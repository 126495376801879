<template>
  <div class="Statstics">
    <div class="round">
      <div class="round_left">
        <div class="round_nav">
          <p>轮次</p>
          <!--      <button-->
          <!--              v-for="(roundItem, roundIndex) in 4"-->
          <!--              class="round_btn"-->
          <!--              :class="{'round_btn_active':activeIndex===roundIndex}"-->
          <!--              @click="changeNav(roundIndex)" >-->
          <!--        {{roundIndex+1}}-->
          <!--      </button>-->
          <button class="round_btn " :class="{'round_btn_active':activeIndex===0}" @click="changeNav(0)">1</button>
          <button class="round_btn " :class="{'round_btn_active':activeIndex===1}" @click="changeNav(1)">2</button>
          <button class="round_btn " :class="{'round_btn_active':activeIndex===2}" @click="changeNav(2)">3</button>
          <button class="round_btn " :class="{'round_btn_active':activeIndex===3}" @click="changeNav(3)">4</button>
        </div>

        <!--数据数字化-->
        <div class="data01">
          <div class="md">
            <div class="md_child">
              <p>开球距离</p>
              <div class="num">
                <div class="dataPercent">
                  313.9
                </div>
                <div class="num_right">
                  <span>周平均</span>
                  <span>300</span>
                </div>
              </div>
            </div>
            <div class="md_child">
              <p>标准杆上果岭率</p>
              <div class="num">
                <div class="dataPercent">
                  66.67%
                </div>
                <div class="num_right">
                  <span>周平均</span>
                  <span>0</span>
                </div>
              </div>
            </div>
          </div>
          <div class="md">
            <div class="md_child">
              <p>开球精确度</p>
              <div class="num">
                <div class="dataPercent">
                  313.9
                </div>
                <div class="num_right">
                  <span>周平均</span>
                  <span>300</span>
                </div>
              </div>
            </div>
            <div class="md_child">
              <p>推杆得分</p>
              <div class="num">
                <div class="dataPercent">
                  66.67%
                </div>
                <div class="num_right">
                  <span>周平均</span>
                  <span>0</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--数字图形化-->
        <div class="data02">
          <p>保帕情况</p>
          <div class="data_view">
            <div class="percent_view1" style="width: 10%">7</div>
            <div class="percent_view2" style="width: 10%">7</div>
            <div class="percent_view3" style="width: 20%">7</div>
            <div class="percent_view4" style="width: 10%">7</div>
            <div class="percent_view5" style="width: 10%">7</div>
            <div class="percent_view6" style="width: 30%">7</div>
            <div class="percent_view7" style="width: 10%">7</div>
          </div>
        </div>
      </div>
    </div>
    <div class="instructions">
       <div class="inst">
         <div class="co"></div>
         <span>双老鹰</span>
       </div>
      <div class="inst">
        <div class="co"></div>
        <span>老鹰</span>
      </div>
      <div class="inst">
        <div class="co"></div>
        <span>小鸟</span>
      </div>
      <div class="inst">
        <div class="co"></div>
        <span>标准杆</span>
      </div>
      <div class="inst">
        <div class="co"></div>
        <span>柏忌+</span>
      </div>
      <div class="inst">
        <div class="co"></div>
        <span>双柏忌+</span>
      </div>
      <div class="inst">
        <div class="co"></div>
        <span>三柏忌+</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Statstics",
    data() {
      return {
        activeIndex: 0,//第几轮
      }
    },
    methods: {
      /**
       * 轮次切换事件
       * @param i  索引值
       * **/
      changeNav(i){
        this.activeIndex = i
        // this.getCard(this.playerId)
      },
    }
  }
</script>

<style scoped lang="less">
  .round{
    padding: 10px;
    background-color: #424242;
    height: 225px;
    .round_left{
      height: 225px;
      // width: 240px;
      /*border-right: 1px solid red;*/
      .round_nav {
        display: flex;
        justify-content: left;
        font-size: 12px;
        width: 100%;
        text-align: center;
        p {
          margin-right: 20px;
          //font-family: My_Bold;
          height: 25px;
          line-height: 25px;

        }
        .round_btn {
          width: 25px;
          height: 25px;
          cursor: pointer;
          outline: none;
          background-color: transparent;
          color: #909090;
          border: 1px solid #909090;
          margin-right: 8px;
          padding: 2px 8px;
          border-radius: 50%;
        }

        .round_btn_active {
          color: #ffffff;
          border: 1px solid #ffffff;
        }

      }
      .data01{
        width: 750px;
        .md{
          width: 100%;
          /*border: 1px solid red;*/
          margin-top: 15px;
          display: flex;
          .md_child{
            flex: 1;
            text-align: left;
            p{
              font-size: 13px;
              line-height: 12px;
            }
            .num{
              display: flex;
              .dataPercent{
                font-size: 30px;
                font-weight: 600;
                height: 30px;
                display: inline-block;
              }
              .num_right{
                margin-top: 7px;
                margin-left: 5px;
                span{
                  line-height: 15px;
                  display: block;
                  font-size: 12px;
                  color: #aaaa;
                  font-weight: 600;
                }

              }
            }

          }
        }
        .md:nth-child(2){
          margin-top: 20px;
        }
      }

      .data02{
        // width: 755px;
        width: 100%;
         margin-top: 15px;
        p{
          font-size: 12px;
          text-align: left;
          line-height: 20px;
        }
        .data_view{
          margin-top: 2px;
          display: flex;
          height: 33px;
          width: 100%;
          /*background-color: red;*/
          line-height: 33px;
          text-align: center;
          color: #000000;
          .percent_view1{
            background-color: #6d98bb;
          }
          .percent_view2{
            background-color:  #32b1e4;
          }
          .percent_view3{
            background-color: #aed4f6;
          }
          .percent_view4{
            background-color:  #a2a7ab;;
          }
          .percent_view5{
            background-color: #f7b942;
          }
          .percent_view6{
            background-color: #ee5a00;
          }
          .percent_view7{
            background-color: #9f5924;
          }
        }
      }
    }
  }
  .instructions{
    width: 100%;
    height: 82px;
    /*border: 1px solid red;*/
    display: flex;
    align-items: center;
    .inst{
      display: flex;
      height: 12px;
      padding-left: 8px;
      .co{
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #6d98bb;
        /*background-color: #32b1e4;*/
      }
      span{
        margin-left: 2px;
        font-size: 10px;
        line-height: 8px;
        color: #eeeeee;
        transform: scale(.7);
      }
    }
    .inst:nth-child(2){
      margin-left: 85px;
      .co{
        background-color:  #32b1e4;
      }
    }
    .inst:nth-child(3){
      margin-left: 30px;
      .co{
        background-color: #aed4f6;
      }
    }
    .inst:nth-child(4){
      margin-left: 30px;
      .co{
        background-color: #a2a7ab;
      }
    }
    .inst:nth-child(5){
      margin-left: 6px;
      .co{
        background-color: #f7b942;
      }
    }
    .inst:nth-child(6){
      margin-left:27px;
      .co{
        background-color: #ee5a00;
      }
    }
    .inst:nth-child(7){
      margin-left:88px;
      .co{
        background-color: #9f5924;
      }
    }
  }
</style>