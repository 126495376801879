<template>
  <div class="rankColume">
    <div class="outside">
      <div class="ad">
        <!-- 广告 -->
         <Advert :location="'video'" />
      </div>
      <div class="title">
        <span>
          精彩击球
        </span>
      </div>
      <div class="content" ref="rankColumeContent">

        <div class="content_video" v-for="(videos,index) of videosHot" :key="index" @click="selectVideo(videos.videoId)">
          <div class="left">
            <p>精选</p>
            <p>{{videos.videoName.length>20?videos.videoName.slice(0,17)+'...':videos.videoName}}</p>
          </div>
          <div class="right">
            <img :src="videos.videoPostUrl" alt="">
            <svg  class="icon icon_blur" aria-hidden="true">
              <use  xlink:href="#icon-bofang"></use>
            </svg>
          </div>
        </div>


      </div>
    </div>

  </div>
</template>

<script>
  import Advertising from "../Advertising";
  import getAxios from "../../request";
  import Advert from "../../components/Advert";

  const vm = {
    name: "rankColume",
    components:{
      Advertising,
      Advert
    },
    props:{
      rankColumeChild:Number,
    },
    data(){
      return {
        videosHot:[]
      }
    },
    created(){
    //  调用数据请求事件
      this.init()
    },
    methods:{
      //请求数据事件
      init(){
        //  this.videosHot = []
        let that = this
        let data = {
            current: 1,
            size: 100,
            categoryName: '精彩击球'
          };
          getAxios({
            url: "/getVideoListByCategoryName",
            data: data,
          }).then((response) => {
              that.videosHot = response.records;
              console.log()
          }).catch(() => {});
      },
      //高度事件
      changeHeight(height){
        this.$refs.rankColumeContent.style.height = height + 'px'
        console.log();
      },
    //  触发播放事件（把参数传递给父组件）
      selectVideo(videoId){
        this.$emit('setVideoId',videoId)
      }
    }
  }


  export default vm
</script>

<style scoped lang="less">
  .rankColume{
    height: inherit;
    .outside{
      height: inherit;
      /*overflow: hidden;*/
      width: 100%;
      color: #ffffff;
      position: relative;
      .ad{
        width: 272px;
        margin: 25px;
        /*height: 205px;*/
        /*border: 1px solid red;*/
        img{
          width: 100%;
          height: 100%;
        }
      }
      .title{
        font-size: 20px;
        margin:0 25px 0 25px;
        padding-bottom: 25px;
        width: 84%;
        border-bottom: 3px solid #25415B ;
        span{
          //font-family: My_Bold;
          letter-spacing: 8px;
        }
      }
      .content{
        /*height: inherit;*/
        /*height: 180vh;*/
        /*min-height: 880px;*/
        height: 880px;
        overflow: auto;
        margin: 25px 0 10px 25px;
        border-top: 1px solid #25415B;
        border-bottom: 30px;
        position: absolute;
        height: calc(100% - 230px);
        /*background-color: red;*/
        .content_video:nth-last-child(1){
          margin-bottom: 200px;
        }
        .content_video{
          padding-top: 20px;
          padding-bottom: 20px;
          display: flex;
          border-bottom: 1px solid #25415B;
          .left{
            flex: 3;
            /*width: 160px;*/
            padding-right: 10px;
            /*padding-left: 20px;*/
            p:nth-child(1){
              font-size: 13px;
              padding-bottom: 2px;
              letter-spacing: 6px;
              color: #4a5a68;
              //font-family: My_Medium;
            }
            p:nth-child(2){
              font-size: 14px;
              font-weight: 400;
            }
          }
          .right{
            flex: 2;
            height: 54px;
            width: 90px;
            color: #000000;
            /*padding-left: 25px;*/
            position: relative;
            padding-top: 8px;
            /*border: 1px solid red;*/
            img{
              background-color: #eeeeee;
              height: 54px;
              width: 96px;
            }
            svg{
              position: absolute;
              z-index: 2;
              width: 26px;
              height: 26px;
              left: 45%;
              top: 50%;
              margin-top: -10px;
              margin-left: -15px;
            }
            /*.icon-bofang{*/
            /*  position: absolute;*/
            /*  color: #000000;*/
            /*  font-size: 17px;*/
            /*  top: 18px;*/
            /*  left: 37px;*/
            /*}*/
          }
        }
      }
      .content::-webkit-scrollbar-track-piece { //滚动条凹槽的颜色，还可以设置边框属性
        background-color:#25415B;
      }
      .content::-webkit-scrollbar {//滚动条的宽度
        width:9px;
        height:100%;
      }
    }

  }
</style>