<template>
  <div id="app" v-cloak>
    <div class="all_loading" v-if="this.$store.state.closeLoading">
      <Loading/>
    </div>

    <div id="nav" class="nav_mobile" @click="goTop">
      <!-- <div class="navLogo">
        <img src="../src/assets/images/navLogo1.png" alt="" class="navLogoItem" @click="gotoGolfbox">
        <img src="../src/assets/images/navLogo2.png" alt="" class="navLogoItem">
      </div> -->
      <div id="navImg" class="navImg_mobile">
        <img src="../src/assets/images/headerBgIcon.png" alt="">
      </div>
      <div class="navList">
        <div class="w1320 d-f w1320_mobile">
          <!-- logo图标-->
          <div class="logo"><a href="/"></a></div>
          <div class="menu menu_mobile">
            <div class="menu_flex menu_flex_mobile">
              <router-link to="/">首页</router-link>
              <a href="http://www.golfbox.cn/" class="GolfTV" target="_blank">
                高尔夫频道
              </a>
              <!-- pc端 -->
              <router-link v-show="screenWidth > 900" to="/Leaderboard/Leader">领先榜</router-link>
              <!-- 移动端 -->
              <router-link v-show="screenWidth <= 900" to="/Leaderboard/LeaderMobile">领先榜</router-link>
              <router-link to="/Schedule">赛程</router-link>
              <router-link to="/Players">球员</router-link>
              <!-- <router-link to="/Fedexcup">联邦快递杯</router-link> -->
              <router-link to="/Video">视频</router-link>
              <router-link to="/News">新闻</router-link>
              <router-link to="/Stats/dataForm">数据</router-link>
              <router-link to="/FedExCupNew/Standings">联邦快递杯</router-link>
              <!-- <router-link to="/PresidentCup/Rank">总统杯</router-link> -->
            </div>
          </div>
          <div class="mask" v-if="isShowB" @click="isShowB=false"></div>
          <div class="nav-search" :class="isShowB?'bg021c3d':''" >
            <div class="area">
                <button class="icon-search" aria-label="搜索" @click="isShowSearch"></button>
            </div>
            <div class="search-dropdown" v-show="isShowB">
              <div>
                <el-input placeholder="请输入内容" v-model="searchTxt" class="input-with-select" @keyup.enter.native="btnSearch()">
                  <el-select v-model="select" slot="prepend" placeholder="请选择">
                  <el-option label="视频" value="1"></el-option>
                  <el-option label="文章" value="2"></el-option>
                </el-select>
                  <el-button slot="append" icon="el-icon-search" @click="btnSearch"></el-button>
                </el-input>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- <div class="app__btn"></div> -->
    <router-view class="view_nav"/>
    <!-- <botton/> -->
    <foot/>
  </div>
</template>
<script>
  // 导入header.vue文件
  import botton from "./components/botton/botton";
  import foot from "./components/footer/footer";
  import Loading from './components/loading/Loading'
  import store from '@/store'
  // 重新定义 _czc ,因为通过script引入了友盟统计，但是可能存在加载问题，导致友盟的全局属性没有挂载到Window上，所以做了判断
  let _czc = window._czc || '';
  export default {
    components: {
      botton,
      Loading,
      foot
    },
    data(){
      return {
        select:'1',
        searchTxt:'',
        isShowB:false,
        // closeLoading:this.$store
        screenWidth: null,
      }
    },
    mounted () {
      const script = document.createElement('script')
      script.src = 'https://v1.cnzz.com/z_stat.php?id=1281126566&web_id=1281126566'
      script.language = 'JavaScript'
      document.body.appendChild(script)
    },
    watch: {
      //监控 路由的变化 ，统计路由的
      $route() {
        // 判断 _czc 有没有挂载，且当前环境是开发还是生产，测试的时候，使用开发环境，上线之前记得改成生产，避免消息发送错误
        if (_czc ) {
          // 如果使用_trackPageview改写了已有页面的URL，建议在CNZZ的JS统计代码执行前先调用_setAutoPageview，将该页面的自动PV统计关闭，防止页面的流量被统计双倍。
          _czc.push(['_trackPageview', contentUrl, refererUrl]);

          // 获取当前页面的信息
          let location = window.location;

          // 代表当前路由地址
          // let contentUrl = location.pathname + location.hash;
          let contentUrl = 'pgatour.golfbox.cn'
          // let contentUrl = 'pgatest.golfbox.cn'
          // 代表当前页面的母页面，不填，按母页面的来路计算，但是实际上是取不到母页面的
          let refererUrl = '/';

          // 发送统计事件，
          _czc.push(['_trackPageview', contentUrl, refererUrl]);
        }
      },

      screenWidth: {
        handler(val, oldVal) {
          store.commit('setScreenWidth',val)
          if(val < 900) {
            if(this.$route.path == '/Leaderboard/Leader') {
              this.$router.replace({ path: '/Leaderboard/LeaderMobile' })
            }
            if(this.$route.path == '/Leaderboard/TeeTimes') {
              this.$router.replace({ path: '/Leaderboard/TeeTimesMobile' })
            }
            if(this.$route.path == '/Leaderboard/HoleMap') {
              this.$router.replace({ path: '/Leaderboard/HoleMapMobile' })
            }
          } else {
            if(this.$route.path == '/Leaderboard/LeaderMobile') {
              this.$router.replace({ path: '/Leaderboard/Leader' })
            }
            if(this.$route.path == '/Leaderboard/TeeTimesMobile') {
              this.$router.replace({ path: '/Leaderboard/TeeTimes' })
            }
            if(this.$route.path == '/Leaderboard/HoleMapMobile') {
              this.$router.replace({ path: '/Leaderboard/HoleMap' })
            }
          }
        },
        deep: true,
      }
    },
    created () {
      let that = this
    },
    mounted(){
      let that = this
      window.onresize = () => {
        return (() => {
          this.screenWidth = document.body.clientWidth
        }) ()
      }
      window.onload = () => {
        return (() => {
          this.screenWidth = document.body.clientWidth
        }) ()
      }
    },
    methods:{
      gotoGolfbox() {
        window.open('https://www.golfbox.cn/', '_blank');
      },
      goTop(){
        window.scrollTo(0,0)
      },
      isShowSearch(){
        if(this.isShowB){
          this.isShowB = false
        }else{
          this.isShowB = true
        }
      },
      btnSearch(){
        if(this.searchTxt !=''){
          let query = {select:this.select,searchTxt:this.searchTxt}
          if(query.select == this.$route.query.select && query.searchTxt == this.$route.query.searchTxt) store.commit('setSearchResultStatus',true)
          else this.$router.push({path:'/searchResult',query: query})
        }
      }
    }
  }
</script>

<style lang="less">
@import "assets/css/font.css";
@import "assets/css/font2.css";
@import "assets/css/flag.css";
  [v-cloak] {
    display: none;
  }
  *{
    padding: 0;
    margin: 0;
    // border: 0;
  }
  input{
    border: 0;
  }
  body{
    font-family: 'Microsoft YaHei';
    
  }
  input {font-family: "Microsoft YaHei";}
  img{
    background-image: url("./assets/default2.png");
    background-size: cover;
    background-position: center;
  }
  .shell{
    display: flex;
    justify-content: center;
    // background-color: #eeeeee;
    // background-color: rgb(212, 218, 223);
    background-color: #DDDDDD;

  }
  .shell_content{
    background-color: #ffffff;
    width: 1300px;
    margin-top: 25px;
    margin-bottom: 20px;
  }

  .all_loading{
    position: fixed;
    width: 80px;
    height: 80px;
    top: 50%;
    left: 50%;
    z-index: 100;
    margin-left: -40px;
  }

  #nav{
    z-index: 100;
    // background-color: #053970;
    display: flex;
    justify-content: center;
    // background: url('../src/assets/images/headerBg.png') no-repeat center center;
    // background-size: cover;
    background-color: rgb(52,71,88);
    position: relative;
    // height: auto;
    height: 19.5625rem;
  }
  #navImg {
    height: 100%;
    width: 1150px;
    img {
      height: 100%;
      transform: translateX(-23%);
    }
  }
  .navLogo {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin: 0 auto;
    width: 1320px;
    display: flex;
    justify-content: flex-end;
    padding-right: 190px;
  }
  .navLogoItem {
    background-image: none;
    width: 270px;
  }
  .navLogoItem:nth-child(1) {
    margin-right: 86px;
    cursor: pointer;
  }
  .navList {
    background: rgba(0,0,0,0.5);
    width: 100%;
    display: flex;
    justify-content: center;
    // height: 84px;
    height: 70px;
    position: absolute;
    bottom: 0;
  }
  .view_nav{
    // background-color: #eeeeee;
    // background-color: rgb(212, 218, 223);
    background-color: #DDDDDD;
  }
  .logo{
    width:155px;
    height: 100%;
    padding: 10px;
    overflow: hidden;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    a{
      width: 43px;
      height: 60px;
      background-image: url("./assets/tour_logo.png");
      background-size: cover;
      background-repeat: no-repeat;
    }

  }
  .d-f{
    display: flex;
  }
  .menu{
    width: 1050px;
    height: 100%;
    padding-left: 25px;
    display: flex;
    justify-content: left;
    box-sizing: border-box;
    a:nth-child(1){
      letter-spacing: 6px;
    }
    a:nth-child(4){
      letter-spacing: 6px;
    }
    a:nth-child(5){
      letter-spacing: 6px;
    }
    a:nth-child(7){
      letter-spacing: 6px;
    }
    a:nth-child(8){
      letter-spacing: 6px;
    }
    a:nth-child(9){
      letter-spacing: 6px;
    }
  }


  .menu, .menu a{
    //font-family: My_Tab;
    background-color: rgba(225,225,225,0);
    text-align: center;
    // line-height: 84px;
    line-height: 70px;
  }


  .menu a {
    // font-size: 24.44px;
    font-size: 16px;
    font-family: 'SimHei';
    padding: 0 30px 0 20px;
    font-weight: 400;
    color: white;
    text-align: center;
    text-decoration: none;
    position: relative;
  }
  .menu a:nth-child(1){
    padding-left: 1px;
  }
  #nav a.router-link-exact-active {
    font-weight: 900;
  }
  .GolfTV{
    //font-family: Golfbox!important;
    /*font-style: oblique;*/
    font-weight: 900;
    width: 110px;
    position: relative;
  }
  .app__btn{
    width: 100%;
    height: 8px;
    background-color: #ffffff;
    box-shadow: 0 6px 5px 1px #dddddd;
    z-index: 101;
    margin-bottom: 6px;
  }
  .w1320{
    width: 1320px;
  }
  .nav-search{
    display: flex;
    position: relative;
    z-index: 400;
    // height: 80px;
    height: 70px;
      .area{
    position: relative;
    width: 35px;
    height: 30px;
    // padding: 25px 10px;
    padding: 20px 10px;
    cursor: pointer;
    .icon-search{
      cursor: inherit;
      display: block;
      width: 35px;
      height: 30px;
      padding: 0;
      margin: 0;
      border: 0;
      background: url(./assets/images/sprite-3.png) -262px -159px no-repeat;
    }
  }
  }

.search-dropdown{
    opacity: 1;
    display: block;
    min-width: 300px;
    padding: 15px;
    position: absolute;
    top: 100%;
    right: 0;
    background-color: #021C3D;
    .yxt-SearchBar-container{
      position: relative;
      overflow: hidden;
      z-index: 1;
    }
    .el-select{
      width: 100px;
      .el-input__inner{
        width: 100px;
      }
    }
    .el-input-group__append, .el-input-group__prepend{

    }
}
.bg021c3d{
  background-color: #021C3D;
}
.mask {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1
}


// 媒体查询，兼容移动端
@media screen and (max-width: 1200px) {
  .nav_mobile {
    height: auto!important;
    .navImg_mobile {
      width: 100%!important;
      img {
        transform: unset!important;
        width: 100%;
      }
    }
  }
  .w1320_mobile {
    width: 100%!important;
  }
  .menu_mobile {
    width: 100%!important;
    overflow-x: hidden;
    white-space: nowrap;
  }
  .menu_flex_mobile {
    overflow-x: auto;
    overflow-y: hidden;
  }
}
</style>
