<template>
<!--  -->
    <div class="PlayTime "   :class="{'is_none':isDisplay}">
      <div class="view_shell">
        <!--顶部-->
        <div class="top">
          <div class="emptyContent">暂无直播安排</div>
          <div class="left none">
            <p class="head_tip"> </p>
            <p class="head_title">{{title}} </p>
            <p  class="head_subtitle">{{subTitle}} </p>
          </div>
          <!--关闭按钮-->
          <div class="right csp rightTop" @click="isClose()">
            <svg class="icon icon_blur" aria-hidden="true">
              <use  xlink:href="#icon-guanbi"></use>
            </svg>
          </div>
        </div>

        <!--列表-->
        <div class="list none" ref="list">
          <table cellspacing="0" class="list_table" >
            <thead class="list_thead">
            <tr class="list_tr">
              <th>日期</th>
              <th>北京时间</th>
            </tr>
            </thead>
            <tbody class="list_tbody">
            <tr class="list_tr" v-for="(item, index) in playtime" :key="index">
              <td class="list_td">{{item.date}}</td>
              <td class="list_td">{{item.time}}</td>
            </tr>
            </tbody>
          </table>
        </div>
        <!-- <div class="more" ref="more">
          <span class="iconfont icon-jiazaigengduo"></span>
        </div> -->
      </div>

    </div>


</template>

<script>
  export default {
    name: "PlayTime",
    props:{
      isDisplay:{
        type:Boolean,
        default:false
      },
      playtime:{
        type: Array,
        default: []
      },
      title:{
         type: String,
      },
      subTitle:{
        type: String,
      },
    },
    data(){
      return {
        is_none: false
      }
    },
    mounted() {
      console.log(1111)
    },
    methods:{
      //关闭组件
      isClose(){
        // console.log(this.is_none);
        this.$emit('changeData', this.is_none)
        this.$forceUpdate();
      },
    //  控制提示下拉样式
      iscloseDowm(){
        this.$refs.list.style.height = '25vh'
        this.$refs.more.style.display = 'none'
      }
    }

  }
</script>

<style scoped lang="less">
  .hidden {
    visibility: hidden;
  }
  .none {
    display: none;
  }
  .emptyContent {
    text-align: center;
    flex: 8;
    padding: 10px;
    font-weight: bold;
    font-size: 25px;
  }
  .rightTop {
    top: -330px!important;
  }

  .PlayTime{
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(77, 77, 77,.7);
    .view_shell{
      background-color: #ffffff;
      width: 100%;
      position: relative;
      /*margin-top: -20px;*/
      .top{
        display: flex;
        padding: 10px;
        position: relative;
        .left{
          flex: 8;
          padding: 10px;
          .head_tip{
            font-size: 15px;
            margin-bottom: 10px;
            font-weight: lighter;
          }
          .head_title{
            font-size: 25px;
            font-weight: 600;
            letter-spacing: 1px;
          }
          .head_subtitle{
            padding-top: 5px;
            font-size: 17px;
          }
        }
        .right{
          position: absolute;
          top: -110px;
          right: -50px;
          flex: 1;
          svg{
            width: 35px;
          }
        }
      }

      .list{
        height: 35vh;
        overflow: auto;
        width: 100%;
        text-align: center;
        margin-bottom: 30px;
        position: relative;
        .list_table{
          width: 100%;
          /*height: 50px;*/
          border-bottom: 1px solid #777777;
          .list_tr{
            line-height: 45px;
            font-size: 17px;
            font-weight: 500;
          }
          .list_thead{
            .list_tr{
              margin: 0;
              /*border: 1px solid red;*/
              background-color:  #737373;
              color: #ffffff;
              th{
                width: 50%;
              }
              th:first-child{ 
              border-right: 1px solid #eeeeee;
              }
            }
          }
          .list_tbody{
            .list_td{

              border-top: 1px solid #777777;
              border-left: 1px solid #777777;
            }
          }
        }

        }
      .more{
        position: absolute;
        bottom: 20px;
        width: 15px;
        margin-left: -7.5px;
        left: 50%;
        animation:mymove 1s infinite;
        -webkit-animation:mymove 1s infinite; /*Safari and Chrome*/
        .icon-jiazaigengduo{
          color: black;
          font-size: 15px;

        }

      }
      .list::-webkit-scrollbar {
        display:none;
        width:0;
        height:100%;
        color: transparent;
      }
    }
  }
  @keyframes mymove {
    0%{padding-bottom:5px}
    25%{padding-bottom:7.5px}
    50% {padding-bottom:10px}
    75%{padding-bottom:7.5px}
    100% {padding-bottom:5px}
  }
  @-ms-keyframes mymove {
    0%{padding-bottom:5px}
    25%{padding-bottom:7.5px}
    50% {padding-bottom:10px}
    75%{padding-bottom:7.5px}
    100% {padding-bottom:5px}
  }
  /*@-webkit-keyframe shadow {*/
  /*50%{*/
  /*  padding-top: 10px;*/
  /*}*/
  /*100%{*/
  /*  padding-top: 0;*/
  /*}*/
  /*}*/
  /*.is_none{*/
  /*  display: none;*/
  /*}*/
</style>
