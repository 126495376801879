<template>
<!--  新闻详情-->
  <div class="NewText">
    <div class="top">
      <p @click="goBack()"><< 返回</p>
    </div>
    <div class="content">
      <div class="title">
        <h3>{{article.articleTitle}}</h3>
        <div class="subTitle">{{article.secondTitle}}</div>
      </div>
      <p class="p_time">
<!--        时间: {{article.createTime==null?'':article.createTime}}-->
<!--        <span>阅读:{{article.articleClickTimes==null?'':article.articleClickTimes}}</span>-->
      </p>
<!--      <div class="content_img">-->
<!--        <img :src="article.articlePostUrl" alt="">-->
<!--      </div>-->
      <div @click="goProhibit()" oncontextmenu = "return false" class="article articleCard" v-html="article.articleContent?article.articleContent:'暂无数据'">

      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "NewsDText",
    props:{
      toNewText:Boolean,
      article:{
        type:Object,
        default:null
      },
    },
    mounted() {
      var v=document.getElementsByTagName('video');
      for(var i=0;i<v.length;i++){
          v[i].controlslist='nodownload'
      }  
      // this.$nextTick(()=>{     
              
      // }) 
    },
    methods:{
      goBack(){
        this.$router.back()
      },
      goProhibit($event){
        console.log($event)

      },
      
    }
  }
</script>

<style scoped lang="less">
  .NewText{
    /*//font-family: My_Bold;*/
    .top{
      cursor: pointer;
      width: 97%;
      padding: 0 0 15px 20px;
      border-bottom: 1px solid #777777;
      p{
        font-size: 15px;
        font-weight: 300;
      }

    }
    .content{
       padding: 20px 25px 30px 40px;
      .title{
        h3{
          //font-family: My_Bold;
          font-size: 45px;
		      font-weight: bold;
        }
        .subTitle{
          font-size: 20px;
          line-height: 26px;
          margin: 18px 0;
        }

      }
       .article{
         margin-top: 20px;
         font-size: 18px;
         // font-weight: bolder;
       }
       .p_time{
         padding: 5px 0 15px 0;
         font-size: 10px;
         font-weight: 300;
         /*border: 1px solid red;*/
         span{
           padding-left: 10px;
         }
       }

      /*.content_img{*/
      /*  width: 80%;*/
      /*  !*background-color: #777777;*!*/
      /*  height: 400px;*/
      /*  margin-bottom: 20px;*/
      /*  img{*/
      /*    width: 100%;*/
      /*    height: 100%;*/
      /*  }*/
      /*}*/
     }
  }

</style>

<style lang="less">
  // 处理新闻详情页面，富文本css失效问题
  .articleCard {
    p {
      line-height: 2;
    }
    /* table 样式 */
    table {
      border-top: 1px solid #000 !important;
      border-left: 1px solid #000 !important;
    }
    table td,
    table th {
      border-bottom: 1px solid #000 !important ;
      border-right: 1px solid #000 !important;
      padding: 3px 5px;
      height: 32px;
    }
    table th {
      border-bottom: 1px solid #000 !important;
      text-align: center;
    }

    /* blockquote 样式 */
    blockquote {
      display: block;
      border-left: 8px solid #d0e5f2;
      padding: 5px 10px;
      margin: 10px 0;
      line-height: 1.4;
      font-size: 100%;
      background-color: #f1f1f1;
    }

    /* code 样式 */
    code {
      display: inline-block;
      *display: inline;
      *zoom: 1;
      background-color: #f1f1f1;
      border-radius: 3px;
      padding: 3px 5px;
      margin: 0 3px;
    }
    pre, code {
      display: block;
    }

    /* ul ol 样式 */
    ul,
    ol {
      margin: 10px 0 10px 20px;
    }
  }
</style>