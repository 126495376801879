<template>
  <div class="Schedule">
    <div class="shell">
      <div class="shell_content">
        <div class="title">
          <h3>2022-23&nbsp;<strong>赛季美巡赛程表</strong></h3>
        </div>
        <div class="top" >
          <div v-if="false">
            <div class="top_span">
              <span>即将</span>开赛
            </div>
            <div class="center">
              <div class="matching">
                <a href="">
                 <img src="" alt="">
                  <div class="center_title">
                    <p>x月xx日-x月x日</p>
                    <span>赛事名称</span>
                 </div>
               </a>
              </div>

             <div class="matching">
               <a href="">
                 <img src="" alt="">
                 <div class="center_title">
                   <p>x月xx日-x月x日</p>
                   <span>赛事名称</span>
                 </div>
               </a>
             </div>

             <div class="matching">
               <a href="">
                 <img src="" alt="">
                 <div class="center_title">
                   <p>x月xx日-x月x日</p>
                   <span>赛事名称</span>
                 </div>
                </a>
              </div>
           </div>
          </div>

          <div class="space"></div>
          <div class="list">
            <!-- <div class="list_title">
              <span>{{startYear}}-{{startYear+1}}</span>
              <span>赛程表</span>
            </div> -->
            <div>
              <table border="1" cellpadding="0" >
                <thead>
                  <th style="cursor: pointer;" @click="sort()">
                    <span>日期</span>
                    <span class="iconfont n hidden" :class="{'icon-xiala1':chang_icon,'icon-Down':!chang_icon}"></span>
                  </th>
                  <th>赛事信息</th>
<!--                  <th>播出渠道</th>-->
                  <th>卫冕冠军</th>
                  <th>联邦快递杯积分</th>
                </thead>
                <tbody>
                  <tr v-if="true" :class="{'bg':index%2===0}" v-for="(item ,index) in macths" :key="index">
                    <!-- <td>
                     {{item.startYear}} .{{item.startMonth }} .{{item.startDay }} - {{item.endDay>9?item.endDay:item.endDay}}
                    </td> -->
                    <td>
                     {{item.startDatetime}} - {{item.endDatetime.substring(8,10)}}
                    </td>
                    <td>
<!--                      <p> <strong>{{// item.matchName}}</strong></p>-->
                      <p class="td_p"> {{item.matchName}}</p>
                      <p>
                        <span class="td_span">{{item.matchCourt}} ，{{item.matchPlace}} <span v-if="item.matchPrize">，奖金{{item.matchPrize}}</span></span>
                      </p>
                    </td>
<!--                    <td>-->
<!--                      {{item.matchWatchChannel}}-->
<!--                    </td>-->
                    <td class="wspl">
                      <p>{{item.cName !== ''?item.cName:""}}</p>
                      <p>{{item.eName !== ''?item.eName:""}}</p>
                    </td>
                    <td>{{item.matchFedexcupPoints}}</td>
                  </tr>
                </tbody>
              </table>
			  <div class="tip">
				  备注：# 非美巡赛协办之比赛
			  </div>
			  <div class="tip newtip">
				  <span style="margin-right: 5px">*</span>  2023年升级赛事
			  </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

  import getAxios from "../request";
  import methods_share from "../assets/js/methods_share";

  export default {
    name: "Schedule",
    components:{

    },
    data(){
     return {
       //  赛事数据
       macths:[],
      //  下拉按钮状态(由大到小)
       chang_icon:true,
     //  赛程开始年份
        startYear:new Date().getFullYear() - 1,
        normalList: [
          {startDatetime: '2023.09.11', endDatetime: '2023.09.17' ,matchName: '飞塔锦标赛（Fortinet Championship）',matchCourt: '希尔维拉多度假村（北球场）',matchPlace: '加州纳帕',matchPrize:'8,400,000美元',cName: '马克斯·霍马',eName: 'Max Homa',matchFedexcupPoints: '500'},
          {startDatetime: '2023.09.29', endDatetime: '2023.10.01' ,matchName: '莱德杯（Ryder Cup）',matchCourt: '马克西蒙高尔夫俱乐部',matchPlace: '意大利罗马',matchPrize:'',cName: '',eName: '',matchFedexcupPoints: ''},
          {startDatetime: '2023.10.02', endDatetime: '2023.10.08' ,matchName: '桑德森农场锦标赛（Sanderson Farms Championship）',matchCourt: '杰克逊乡村俱乐部',matchPlace: '密西西比州杰克逊市',matchPrize:'8,200,000美元',cName: '麦肯锡·休斯',eName: 'Mackenzie Hughes',matchFedexcupPoints: '500'},
          {startDatetime: '2023.10.09', endDatetime: '2023.10.15' ,matchName: "圣地兄弟会儿童医院公开赛（Shriners Children's Open）",matchCourt: 'TPC萨默林',matchPlace: '内华达州拉斯维加斯',matchPrize:'8,400,000美元',cName: '金柱亨',eName: 'Tom Kim',matchFedexcupPoints: '500'},
          {startDatetime: '2023.10.16', endDatetime: '2023.10.22' ,matchName: 'ZOZO锦标赛（ZOZO Championship）',matchCourt: '阿卡迪亚高尔夫习志野乡村俱乐部',matchPlace: '日本千叶',matchPrize:'8,500,000美元',cName: '基根·布拉德利',eName: 'Keegan Bradley',matchFedexcupPoints: '500'},
          {startDatetime: '2023.10.30', endDatetime: '2023.11.05' ,matchName: '全球科技锦标赛（World Wide Technology Championship）',matchCourt: '埃尔卡多纳迪亚曼特',matchPlace: '墨西哥洛斯卡沃斯',matchPrize:'8,200,000美元',cName: '罗素·亨利',eName: 'Russell Henley',matchFedexcupPoints: '500'},
          {startDatetime: '2023.11.06', endDatetime: '2023.11.12' ,matchName: '巴特菲尔德百慕大锦标赛（Butterfield Bermuda Championship）',matchCourt: '皇家港高尔夫球场',matchPlace: '百慕大 南安普敦',matchPrize:'6,500,000美元',cName: '希莫斯·帕沃尔',eName: 'Seamus Power',matchFedexcupPoints: '500'},
          {startDatetime: '2023.11.13', endDatetime: '2023.11.19' ,matchName: 'RSM精英赛（The RSM Classic）',matchCourt: '海岛高尔夫俱乐部',matchPlace: '乔治亚州圣西蒙斯岛',matchPrize:'8,400,000美元',cName: '亚当·斯文森',eName: 'Adam Svensson',matchFedexcupPoints: '500'},
          {startDatetime: '2023.11.27', endDatetime: '2023.11.03' ,matchName: '英雄世界挑战赛（Hero World Challenge）',matchCourt: '阿尔巴尼',matchPlace: '巴哈马 新普罗维登斯',matchPrize:'3,500,000美元',cName: '维克多·霍夫兰',eName: 'Viktor Hovland',matchFedexcupPoints: ''},
          {startDatetime: '2023.12.04', endDatetime: '2023.12.10' ,matchName: '致同邀请赛（Grant Thornton Invitational）',matchCourt: '蒂布隆高尔夫俱乐部和那不勒斯丽思卡尔顿酒店',matchPlace: '佛罗里达州那不勒斯',matchPrize:'4,000,000美元',cName: '',eName: '',matchFedexcupPoints: ''},
          {startDatetime: '2023.12.11', endDatetime: '2023.12.17' ,matchName: 'PNC锦标赛（PNC Championship）',matchCourt: '奥兰多丽思卡尔顿高尔夫俱乐部',matchPlace: '佛罗里达州奥兰多',matchPrize:'1,000,000美元',cName: '维杰·辛格',eName: 'Vijay Singh',matchFedexcupPoints: ''},
        ],
      }
    },
    created() {
      this.getScheduleList()
    },
    methods:{
    //  请求 赛程列表 事件
      async getScheduleList(){
        let that  = this
        that.macths = await getAxios({
          url:"/getSchedule",
          data:{
            startYear:2024,
            endYear:2024
          }
        })
        that.macths.forEach((item)=>{
          item.startDatetime = that.dateFormat("YYYY.mm.dd",new Date(item.startDatetime))
          item.endDatetime = that.dateFormat("YYYY.mm.dd",new Date(item.endDatetime))
          if (item.matchDefendingChampion&&item.matchDefendingChampion!="") {
            // let l = item.matchDefendingChampion.substring(0, item.matchDefendingChampion.indexOf(' '))
            // let r = item.matchDefendingChampion.substring(item.matchDefendingChampion.indexOf(' ')+1, item.matchDefendingChampion.length - item.matchDefendingChampion.indexOf(' ')-1)
            let letterArray = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
            let str = item.matchDefendingChampion
            let indexArray = [];
            for(let i = 0; i < letterArray.length;i++) {
              if(str.indexOf(letterArray[i]) > 0) {
                indexArray.push(str.indexOf(letterArray[i]))
              }
            }
            let firstLetter = Math.min.apply(null, indexArray);
            item.cName = str.substring(0, firstLetter);
            item.eName = str.substring(firstLetter, str.length);
            // console.log(cName.trim(),'--',eName.trim());
            // let Name = item.matchDefendingChampion.split(/\s+/g)
            // cName = Name[0]
            // item.eName = Name[1]+" "+Name[2]
          }



          //console.log(item.startDatetime,item.endDatetime)
        })
        this.normalList.forEach(item => {
          this.macths.push(item);
        })
        methods_share.writeLocal('ScheduleList', this.macths)//将赛事数组缓存到localStorage
        
      },
    //  赛程进行排序
      sort(){
        this.chang_icon = !this.chang_icon
        if (this.chang_icon){
          this.macths = methods_share.getLocal('ScheduleList')//拿到localStorage缓存的数据
          console.log('this.macths: ', this.macths);

        }else{
          let list = this.macths
          console.log('this.macths: ', this.macths);
          for (let i = 0 ,max = list.length-1; (list.length-1)/2 > i; i++){
            let item = list[i]
            list[i] = list[max]
            list[max] = item
          }
          this.macths = list
          //按照每项的endDatetime值对数组进行重新排序
          // list.sort(methods_share.sortBy('endDatetime',this.chang_icon))
          // this.macths = list
        }
      },
      dateFormat(fmt, date) {
        let ret;
        const opt = {
            "Y+": date.getFullYear().toString(),        // 年
            "m+": (date.getMonth() + 1).toString(),     // 月
            "d+": date.getDate().toString(),            // 日
            "H+": date.getHours().toString(),           // 时
            "M+": date.getMinutes().toString(),         // 分
            "S+": date.getSeconds().toString()          // 秒
            // 有其他格式化字符需求可以继续添加，必须转化成字符串
        };
        for (let k in opt) {
            ret = new RegExp("(" + k + ")").exec(fmt);
            if (ret) {
                fmt = fmt.replace(ret[1], (ret[1].length == 1) ? (opt[k]) : (opt[k].padStart(ret[1].length, "0")))
            };
        };
        return fmt;
      }

    }
  }
</script>

<style scoped lang="less">
  .title{
    width: 100%;
    background-color: #eeeeee;
    font-size: 28px;
    color: #00182e;
    // padding-bottom: 25px;
    padding: 15px 0 15px 15px;
    box-sizing: border-box;
    span{
      //font-family: My_Bold;
      letter-spacing: 2px;
    }
  }
  .top{
    background-color: #ffffff;
    .top_span{
      font-size: 25px;
      margin: 25px 25px 25px 20px;
      span{
        letter-spacing: 2px;
        font-weight: 600;
      }
    }
    .center{
      display: flex;
      padding-right: 40px;
      padding-bottom: 50px;
      .matching{
        margin-left: 45px;
        flex:1;
        a{
          text-decoration: none;
          color: black;
          img{
            background-color: #777777;
            width: 350px;
            height: 200px;
          }
          .center_title{
            margin-top: 5px;
            p{
              font-size: 10px;
              font-weight: 300;
            }
            span{
              font-size: 17px;
              /*font-weight: 500;*/
            }
          }
        }
      }
    }
    .space{
      height: 10px;
      background-color: #eeeeee;
    }
    .list{
      margin-bottom: 30px;
      .list_title{
        padding: 15px;
        span{
          font-size: 24px;
          //font-family: My_Bold;
        }
        span:nth-child(2){
          margin-left: 10px;
          letter-spacing: 2px;
        }
      }
      .hidden {
        visibility: hidden;
      }
      table{
        width: 100%;
        border-collapse:collapse;
        thead{
          font-size: 15px ;
          background-image: linear-gradient(#ffffff,#eeeeee);
          /*color: #ffffff;*/
          text-align: left;
          border: 0;
          th{
            border-right: 1px solid #cccccc;
            border-top: 1px solid #cccccc;
            padding-left: 20px;
            height: 60px;
            width: 130px;
            //font-family: My_Bold;
          }
          .icon-xiala1,.icon-Down{
            float: right;
            padding-right: 15px;
          }
          th:nth-child(2){
            width: 280px;
            padding-right: 40px;
          }
          th:nth-child(1){
            width: 100px;
          }
          th:nth-last-child(1){
            /*width: 100px;*/
            text-align: center;
            padding: 0;
          }
        }
      }
      tbody{
        border-right: 1px solid #cccccc;
        tr {
          border: 1px solid #cccccc;
        }
        td{
          /*//font-family: My_Medium;*/
          border-left: 1px solid #cccccc;
          padding-left: 20px;
          font-size: 15px;
          padding-top: 10px;
          padding-bottom: 10px;
        }
        td:nth-child(1){
          // border-left: none;
          font-size: 14px;
        }

        td:nth-last-child(1){
          padding: 0;
          text-align: center;
        }
        .td_p{
          color: #00284b;
          font-weight: 700;
          //font-family: My_Bold;
        }
        .td_span {
          font-size: 14px;
        }
      }
      /*th,td{*/
      /*  border-right: 1px solid #eeeeee;*/
      /*}*/
    }


  }
  .bg{
     background-color: #F2F2F2;
   }
   .wspl{
   }
   .tip{
	   margin-left: 20px;
	   margin-top: 20px;
   }
   .newtip {
	  text-indent: 47px;
   }
</style>
