<template>
  <div class="home">
    <div class="outside">
      <div class="content content-new">
<!--        顶部部分-->
        <div class="top_content">
          <PushTop :topTitle="top_title" @getPushTop="handleRealtime">
            <template slot="girl">  
              <div class="ouside">
                <div class="right_top">
                  <div class="right_left">
                    <span class="right_left">直播信息</span>
                  </div>
                  <div class="right_right iconfont icon-remen"></div>
                </div>
                <!-- 广告图 -->
                <Advert :location="'index'" />
              </div>
            </template>
          </PushTop>
        </div>
      </div>
      <div class="home_banner" v-if="false">
        <img src="../assets/images/home_logo.jpg" alt="">
      </div>
      <all-schedules v-if="false" :top_title="top_title" class="all-schedules"/>
      <Realtime :top_title="all_title" class="Realtime" @getRealtime="handleRealtime"/>
    </div>
<!--    视频播放插件-->
    <div class="bofang" v-show="bofang">
      <div class="cast">
        <broadcast :videoId="videoId" ref="broadcast"></broadcast>
        <div class="close iconfont icon-guanbi2" @click="closeIt()"></div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Advert from "../components/Advert";
import rankHots from "../components/rankingList/rankHots";
import Realtime from "../components/Realtime";
import allSchedules from "../components/AllSchedules";
import broadcast from "../components/broadcast/broadcast";
import Advertising from "../components/Advertising";
import PushTop from "../components/PushTop";
import getAxios from "../request";

export default {
  name: 'Home',
  components: {
    Advert,
    rankHots,
    Realtime,
    allSchedules,
    broadcast,
    Advertising,
    PushTop,

  },
  data(){
    return {
      top_title:"index",
      all_title:"美巡赛",
    //  是否播放
      bofang:false,
    //  视频ID
      videoId:Number,

    }
  },
  created() {
  },
  methods:{
    // //请求头部新闻数据
    // async getNewVideoList(){
    //   this.newVideoList = await getAxios({
    //     url:"http://golfljj.test.utools.club/api/getVideoListByCategory",
    //     data:{
    //       category:this.title,
    //       page:1,
    //       limit:6
    //     }
    //   })
    // },

    //新闻是否跳转控制事件
    goOther(classfy){
      //跳转到新闻详情
      if (classfy === 'article'){
        this.$refs.a_text.href = '/NewsDetails'
      }
      //打开播放组件
      if (classfy === 'video'){
        this.bofang = true
        this.$refs.broadcast.getVideo(this.videoId)
      }

    },
  //  关闭播放组件
    closeIt(){
      this.bofang = false
      this.$refs.broadcast.videoPlay()
    },
  //  接收Realtime组件传递过来的参数
    handleRealtime(e){
		console.log(e)
      if(e.classfy == 'video'){
        // this.videoId = e.id
        // this.bofang = true
        // this.$refs.broadcast.getVideo(e.id)
        this.$router.push({
          path: '/Video',
          query: {id:e.id}
        })

      }else if(e.classfy == 'article'){
        this.$router.push({
          path: '/NewsDetails',
          query: {id:e.id}
        })
      }
    },

  }
}
</script>

<style scoped lang="less">
  .outside{
    overflow: hidden;
    display: flex;
    justify-content: center;
    padding-top: 25px;
    align-items: center;
    flex-direction: column;

  }
  .content{
    overflow: hidden;
    width: 1225px;
    display: flex;
    // background-color: white;
    /*margin-bottom: 40px;;*/
  }
  .content-new{
    width: 1300px;
    // width: 67.7%;
  }
  .Realtime,.all-schedules{
    width: 1300px;
  }
  .top_content{
    overflow: hidden;
    display: flex;
  }

  .left{
    width: 600px;
  }

  .first{
    height: 400px;
    width: 600px;
    /*border: 2px solid red;*/
    position: relative;
  }
  img{
    width: 100%;
    height: 100%;
  }
  .first_span,.second_span,.center_span{
    position: absolute;
    bottom: 0;
    background-color: rgba(0,0,0,.7);
    color: #ffffff;
    padding: 20px;
    text-align: center;
    z-index: 3;
  }
  .first .first_span{
    font-size: 25px;
    width: 560px;
  }
  .center_span,.second_span{
    font-size: 17px;
  }
  .second_span{
    width: 259px;
  }
  .center_span{
    width: 250px;

  }
  .second{
    overflow: hidden;
    height: 200px;
    width: 600px;
    margin-top: 1px;
  }
  .second_content{
    position: relative;
    width: 299px;
    height: 100%;
    display: inline-block;
  }
  .second_content:nth-child(1){
    margin-right: 2px;
  }
  .center{
    overflow: hidden;
    width: 300px;
    height: 600px;
    margin: 0 2px 0 2px ;

  }
  .center_content{
    position: relative;
    width: 100%;
    height: 200px;
  }
  .center_content:nth-child(2),.center_content:nth-child(3){
    margin-top: 1px;
  }
  .right{
    overflow: hidden;
    border-top: 6px solid #053970;
    width: 310px;
    height: 565px;
    /*border-bottom: 6px solid #053970;*/
    padding:15px;
    font-size: 17px;
    font-weight: bold;
    position: relative;
  }
  .ouside{
    width: 251px;
    height: 536px;
    border-top: 6px solid #053970;
    padding-right:15px;
    padding-left:16px;
    font-size: 17px;
    font-weight: bold;
    .right_top{
      display: flex;
      line-height: 30px;
      margin-bottom: 5px;
      .right_left{
        flex: 6;
      }
      .iconfont{
        flex: 2;
        font-size: 25px;
        text-align: right;
      }
    }
  }

  .right_left{
    overflow: hidden;
    // padding-top: 1  px;
    flex: 8;
    position: relative;
  }
  a{
    text-decoration: none;
    color: black;
  }
  .right_right{
    font-size: 25px;
    position: relative;
    flex: 1;
  }
  .right_btm{
    height: 330px;
    overflow: auto;
    .right_content{
      margin-top: 10px;
      display: flex;
      padding-bottom: 18px;
      border-bottom: 2px solid #eeeeee;
    }
  }
  .right_btm::-webkit-scrollbar {
    display:none;
    width:0;
    height:0;
    color:transparent;
  }

  .right_content_left{
    flex: 4;
  }
  .left_subtitle{
    font-weight: 100;
    font-size: 10px
  }
  .left_title{
    margin-top: 2px;
    font-size: 13px;
  }
  .right_content_right{
    flex: 2;
    padding-left: 55px;
  }
  .right_content_right{
    height: 55px;
  }
  .right_bottom{
    position: absolute;
    bottom: 15px;
    width: 295px;
    height: 200px;
  }
  .icon_blur{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -35px;
    margin-left: -25px;
    z-index: 3;
    width: 50px;
    height: 50px;
  }
  .bofang{
    background-color: #ffffff;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 20;
    background-color: rgba(213, 214, 215,.8);
    .cast{
      background-color: #ffffff;
      width: 60vw;
      position: absolute;
      left: 50%;
      top: 10vh;
      margin-left: -30vw;
      .close{
        width: 30px;
        height: 30px;
        text-align: center;
        line-height: 30px;
        font-size: 25px;
        background-color: #ffffff;
        color: #000000;
        position: absolute;
        top: -40px;
        right: 0;
        border-radius: 50%;
      }
    }
  }

  // 首页临时加图片
  .home_banner {
    width: 1300px;
    margin-top: 60px;
  }
</style>
