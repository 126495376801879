<template>
<!--  新闻列表-->
  <div class="NewsList">
    <div class="top">
      <div class="date">
        {{date.getFullYear()}}.{{date.getMonth()+1}}.{{date.getDate()}}
        <span>星期{{Day}}</span>
      </div>
      <div class="search">
        <input placeholder="搜索新闻" v-model="searchInter" @keyup.enter="search()" />
        <span v-if="searchInter!==''" class="iconfont icon-guanbi1" @click="delete_in_search"></span>
        <span class="iconfont icon-icon-search" @click="search()"></span>
      </div>
    </div>
    <div class="content_list">
      <p v-if="!showA" style="width: 100%;text-align: center;margin-bottom: 55px">暂时没有你要的新闻</p>
      <div v-if="showA"> 
        <el-row class="new"
                v-for="(item, index) of news"
                :key="index"
                @click.native="goDetail(item.articleId)"
                >
          <el-col :span="9">
            <div class="left" style="width:280px;">
              <img :src="item.articlePostUrl" alt="">
            </div>
          </el-col>
          <el-col :span="15">
            <div class="right">
              <!-- <p>{{item.subtitle}}</p> -->
              <h4 >{{item.articleTitle.slice(0,34)}}</h4>
              <!-- 差一个新闻副标题-->
              <!-- <div v-html="item.articleContent" class="Content"></div> -->
              <!-- <p style="height: 60px;" v-if="item.articleTitle.length<17">{{item.articleContent.length>100?item.articleContent.slice(0,100)+'...':item.articleContent}}</p>
              <p style="height: 60px;" v-else>{{item.articleContent.length>38?item.articleContent.slice(0,35)+'...':item.articleContent}}</p> -->
              <div class="read_more" @click="tonew()">
                <p>查看更多</p>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>

      <div class="page_leg">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="page.total"
          :current-page="page.currentPage"
          :page-size="page.pageSize"
          @current-change="handCurrentChange"
          >
        </el-pagination>
        <!-- <button
                class="btn"
                :class="{'bg':pageNum===1}"
                @click="changPage('-')">
         <上一页
        </button> -->
        <!-- <div class="page_num " :class="{'active':pageNum===1}"
              @click="changPage(1)">
          1</div>
        <div class="page_num" :class="{'active':pageNum===2}"
             @click="changPage(2)">2</div>
        <div class="page_num" :class="{'active':pageNum===3}"
             @click="changPage(3)">3</div>
        <div class="page_num" :class="{'active':pageNum===4}"
             @click="changPage(4)">4</div>
        <div class="page_num" :class="{'active':pageNum===5}"
             @click="changPage(5)">5</div> -->
        <!-- <button
                ref="nextPage"
                class="btn"
                :class="{'bg':pageNum===5}"
             @click="changPage('+')">
          下一页>
        </button> -->
      </div>

    </div>
  </div>

</template>

<script>
  import getAxios from "../request";
  import { getPage,getArticleListByName} from '@/api/new/new'

  export default {
    name: "NewsList",
    props:{
      toNewText:Boolean,
      // news:Array
    },
    data(){
      return {
        date:new Date(),
        page: {
            total: 0, // 总页数
            currentPage: 1, // 当前页数
            pageSize: 7, // 每页显示多少条
        },
        Day:'',
        //新闻列表
        news:[],
      // 显示新闻列表标识
        showA:true,
      //  搜索框数据
        searchInter:''
      }
    },
    created() {
      this.tranDay()  
      //请求文章数据
      // this.getNewsList()
      // this.transformDate(1580093421)
    },
    // activated(){
    //   this.getPage(this.page)      
    // },
    mounted: function () {    
      // var v=document.getElementsByTagName('video')[0];
      if (sessionStorage.getItem("isBack") == 'true') {      
        this.searchInter = sessionStorage.getItem("search")
        let page = {}    
        if (sessionStorage.getItem("page") && sessionStorage.getItem("page") != "" ) {
          page =  JSON.parse(sessionStorage.getItem("page")) 
        } else {
          page = this.page
        }
        let obj = {
          articleTitle:this.searchInter
        }          
        this.getPage(page,obj)
        sessionStorage.setItem("isBack",false)
        sessionStorage.setItem("search","")
        sessionStorage.setItem("page","")
      }else{
        this.getPage(this.page)
      } 
    },
    methods:{
      //转换日期
      tranDay(){
        let d = new Date()
        d = d.getDay()
        switch (d) {
           case 1: this.Day = '一';break;
           case 2: this.Day = '二';break;
           case 3: this.Day = '三';break;
           case 4: this.Day = '四';break;
           case 5: this.Day = '五';break;
           case 6: this.Day = '六';break;
           case 0: this.Day = '日';break;
        }
      },
      //跳转到详情
      goDetail(id){
        this.$router.push({
          path: '/NewsDetails',
          query: {id : id}
        })
        sessionStorage.setItem("search",this.searchInter)
        sessionStorage.setItem('page', JSON.stringify(this.page));
      },
      //请求新闻列表
      getPage(page, params) {
        console.log(page)
          getPage(Object.assign({
              current: page.currentPage,
              size: page.pageSize,
          }, params)).then(response => {
            console.log(response.records)
              this.news = response.records
              this.page.total = response.total
              this.page.currentPage = page.currentPage
              this.page.pageSize = page.pageSize
              if (this.news === -1 || this.news.length === 0){
                this.showA = false
              }else{
                this.showA = true
              }

              // for (let item of this.news){
              //   // console.log(item);
              //   item.createTime = this.transformDate(item.createTime)
              // }
          }).catch(() => {

          })
      },
      // async getNewsList(){
      //   this.news = []
      //   this.news = await getAxios({
      //     url: "/articleList",
      //     data: {
      //       size: this.size,
      //       current: this.current
      //     }
      //   })
      //   console.log(888)
      //   console.log(this.news)
      //   // console.log('news'+this.news);
      //   if (this.news === -1 || this.news.length === 0){
      //     this.showA = false
      //   }else{
      //     this.showA = true
      //   }
      //   for (let item of this.news){
      //     // console.log(item);
      //     item.createTime = this.transformDate(item.createTime)
      //   }
      // },
      //请求搜索内容数据

      //  findNews(page,params){
      //   this.news = []
      //     getArticleListByName(Object.assign({
      //         current: page.currentPage,
      //         size: page.pageSize,
      //         articleTitle:this.searchInter
      //     }, params)).then(response => {
      //         this.news = response.data.data.records
      //         this.page.total = response.data.data.total
      //         this.page.currentPage = page.currentPage
      //         this.page.pageSize = page.pageSize

      //         if (this.news === -1 || this.news.length === 0){
      //           this.showA = false
      //         }else{
      //           this.showA = true
      //         }

      //         for (let item of this.news){
      //           // console.log(item);
      //           item.createTime = this.transformDate(item.createTime)
      //         }
      //     }).catch(() => {

      //     })
        // this.news = await getAxios({
        //   url:"/getArticleListByName",
        //   data:{
        //     articleTitle:this.searchInter,
        //     limit:8,
        //     page:this.pageNum
        //   }
        // })
        // console.log('news'+news.length);
        // for (let item of this.news){
        //   item.createTime = this.transformDate(item.createTime)
        // }
      // },
      handCurrentChange(val){
        // this.pageNum = val
        this.page.currentPage = val
        let obj = {
          articleTitle:this.searchInter
        }        
        this.getPage(this.page,obj)
      },
      //封装一个函数——时间戳转日期
      transformDate(timestamp){
        let date = new Date(timestamp);
        let Y = date.getFullYear() + '-';
        let M = (date.getMonth() + 1) <10 ? '0' + (date.getMonth() + 1) + '-':  date.getMonth() + 1 + '-';
        let D = date.getDate()
        return Y + M + D
      },
      //分页事件
      // changPage(i){
      //   if (i === '-'){
      //     this.pageNum!=1?this.pageNum--:1
      //   }else if(i === '+'){
      //     this.pageNum!=5?this.pageNum++:5
      //   }else {
      //     this.pageNum = i
      //   }
      //   if(this.searchInter === '') {
      //     // console.log(this.pageNum)
      //     window.scrollTo(0,0)
      //     // this.getNewsList()
      //   }
      //   else{
      //     this.findNews(this.page)
      //   }
      // },
    //  查看更多事件
      tonew(id){
        this.toNewText = false //原始为false
        this.$emit('newInter',{
          toNewText:this.toNewText,
        })
      },
    //搜索事件
      search(){
        this.page.currentPage = 1
        let obj = {
          articleTitle:this.searchInter
        }
        this.getPage(this.page,obj)
        // console.log(this.searchInter);
      },
    // 消除搜索数据
      delete_in_search(){
        this.searchInter = ''
        this.getPage(this.page)
        // this.getNewsList()
      }
    }
  }
</script>

<style lang="less">
  .NewsList{
    .top{
      width: 100%;
      /*overflow: hidden;*/
      display: flex;
      margin-top: 10px;
      .date{
        //font-family: My_Bold;
        flex: 3;
        font-size: 28px;
        padding-left: 20px;
        span{
          padding-left: 10px;
          //font-family: My_Black;
        }
      }
      .search{
        /*height: 20px;*/
        flex: 2;
        border: 1px solid #999999;
        float: right;
        display: flex;
        justify-content: center;
        width: 300px;
        height: 30px;
        margin-right: 10px;
        font-size: 17px;
        input{
          flex: 8;
          padding-left: 8px;
          outline: none;
          border: 0;
        }
        span{
          /*line-height: 25px;*/
          padding: 5px;
          flex: 1;
          border-left: 1px solid #777777;
          text-align: center;
          margin-top: 0.4%;
        }
        .icon-guanbi1{
          border: none;
          font-size: 10px;
        }
      }
    }

    .content_list{
      margin-top: 10px;
      border-top: 1px solid #eeeeee;
      padding-top: 40px;
      width: 942px;
      .new{
        text-decoration: none;
        color: #000000;
        margin: 10px 50px 30px 20px;
        display: flex;
        padding: 20px 0 30px 0;
        border-bottom: 1px solid #eeeeee ;
        cursor: pointer;
        .newItem {
          width: 100%;
        }
        .Content{
          overflow:hidden;
          text-overflow:ellipsis;
          white-space:nowrap;
        }
        .left{
          width: 280px;
          img{
            // width: 280px;
            height: 157.5px;
            /*background-image: url("../assets/default2.png");*/
            /*background-size: cover;*/
            background-color: #eeeeee;
          }
        }
        .right{
          margin-left: 20px;
          padding-right: 50px;
          /*width: 600px;*/
          /*border: 1px solid red;*/
          position: relative;

          h4{
            //font-family: My_Bold;
            font-size: 25px;
            padding: 2px 30px 5px 0 ;
            word-wrap: break-word;
            white-space: normal;
            word-break: break-all;
            color: #000000;
          }
          p{
            //font-family: My_Medium;
            font-size: 14px;
            padding-top: 5px;
          }
          p:nth-child(2){
            padding-bottom: 10px;
            border: 1px solid red;
          }

          .read_more{
            margin-top: 10px;
            width: 80px;
            height: 20px;
            background-color: #053970;
            padding:2px;
            // position: absolute;
            bottom: 5px;
            color: #ffffff;
            /*border: 1px solid red;*/
            /*border-radius: 15px;*/
            p{
              font-size: 10px;
              width: 100%;
              height: 100%;
              line-height: 10px;
              /*border: 1px solid red;*/
              text-align: center;
            }
          }



        }
      }
      .page_leg{
        display: flex;
        justify-content: center;
        font-size: 16px;
        margin-bottom: 40px;
        div{
          margin: 0 20px 0 20px;
        }
        div:hover{
          // border-bottom: 2px solid #053970;
        }
        .btn{
          outline: none;
        }
        /*.btn:hover{*/
        /*  border-bottom:none;*/
        /*  color: #01407e;*/
        /*  font-weight: 600;*/
        /*  background-color: #ffffff;*/
        /*}*/
        .page_num{
          cursor: pointer;
        }
      }
    }
  }
  // .bg{
  //   color: #cccccc;
  // }
  .active{
    border-bottom: 2px solid #053970;
  }
  .el-pagination.is-background .el-pager li:not(.disabled).active{
    background-color: #053970!important;
  }
</style>