<template>
  <div class="img">
    <img :src="url" alt="" :onerror="imgURL">
  </div>
</template>

<script>
  export default {
    name: "div-img",
    props:{
      url:String,
      default:'../../assets/default.png'
    },
    data() {
      return {
        imgURL:'this.src="'+require('../../assets/default.png')+'"',
      }
    },
    methods: {}
  }
</script>

<style scoped>
  img{
    background-image: none;
    width: 100%;
    height: 100%;
  }
</style>