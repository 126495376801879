import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import less from 'less'

import './assets/css/iconfont.css'
import './assets/js/iconfont'
import './assets/js/flages'
import './assets/js/flages2'
import getAxios from "./request";
import pgaApi from "./request/pgaApi";
import div_img from "./components/div-img/div-img"
import './styles/common.css'; /*引入公共样式*/
//ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
//注册全局组件
Vue.use(ElementUI);
Vue.component('div-img', div_img)
Vue.config.productionTip = false
Vue.use(less)


new Vue({
  router,
  store,
  getAxios,pgaApi,
  render: h => h(App)
}).$mount('#app')



