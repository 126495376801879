<template>
  <div class="hole-locations">
    <div class="hole_shell">
      <div class="hole_top">
        <div class="hole_left">
          <p>Silverado度假村和北水疗中心(比赛场地)</p>
          <p>球洞位置</p>
        </div>
        <div class="rh_select">
          <div class="rh_content" @click="isSelect=!isSelect">
            Round {{roundNum}}
            <span class="iconfont icon-xiala1"></span>
          </div>
          <div class="select_content"  v-show="isSelect">
            <p v-for="i in 4" @click="handleSlectR(i)" :key="i">Round {{i}}</p>
          </div>
        </div>
      </div>
      <div class="hole_photo">
        <!--球洞图 SVG-->
        <div class="stracka-holes">
          <ul class="stracka-body" id="module-1619662186918-463955-26">
            <li class="stracka-item" v-for="(item,i) in flagList" :key="i">
              <div class="stracka-map" id="module-1619662186922-687802-27">
                <!-- div :width: 282px;  -->
                <div id="shottracker-1619662186924-427504-28" class="canvas" style="left: 0px; top: 2px; width: 92%; height: 127px;">
                  <svg height="100%" version="1.1" width="100%" xmlns="http://www.w3.org/2000/svg"
                       style="overflow: hidden; position: relative; left: -0.05px; top: -0.2px;" v
                       iewBox="0 0 265 120" preserveAspectRatio="xMinYMin">
                    <desc style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">
                      Created with Raphaël 2.1.2
                    </desc>
                    <defs style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);">

                    </defs>
                    <!-- <image x="0" y="0" width="260" height="120" preserveAspectRatio="none"
                           xlink:href="//pga-tour-res.cloudinary.com/image/upload/c_fill,h_120/holes_2021_r_475_665_stracka_green_1.png"
                           style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);" stroke-width="0.9397163120567376">
                    </image> -->
                    <image x="0" y="0" width="100%" height="100%" 
                          preserveAspectRatio="none"
                          :href="item.flagPic"
                          style="-webkit-tap-highlight-color: rgba(0, 0, 0, 0);" 
                          stroke-width="0.9397163120567376">
                    </image>
                  </svg>
                </div>
                <div class="marker-tool-tip" style="display: none;">
                 </div>
              </div>
              <div class="stracka-info ">
                <div class="stracka-info-number">
                  {{i+1}}
                </div>
                <div class="stracka-info-par">
                  <p> Par {{item.par}}</p>
                 <p>{{item.yard}} yds</p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
  import { getFlag } from '@/api/leaderboard/leaderboard'
  export default {
    name: "hole-locations",
    data() {
      return {
        roundNum: 1,
        isSelect:false,
        flagList: []
      }
    },
    mounted(){
      this.getFlagList()
    },
    methods: {
      /**
       * 第几轮
       * */
      handleSlectR(i){
        this.roundNum = i
        this.isSelect = false
        this.getFlagList()
      },
      //请求球洞图片列表
      getFlagList( ) {
        this.flagList = []
        getFlag( this.roundNum ).then(res => {
          if( res.length>0 ){
            this.flagList = res
          } 
        })
      },
    }
  }
</script>

<style scoped lang="less">
  .hole-locations{
    padding-top: 30px;
    background-color: #ffffff;
    .hole_shell{
      width: 100%;
      .hole_top{
        padding-left: 30px;
        display:flex;
        align-items: center;
        .hole_left{
          width: 60%;
          p{
            //font-family: My_Medium;
            color: #00182e;
          }
          p:nth-child(1){
            font-size: 12px;
          }
          p:nth-child(2){
            font-size: 25px;
          }
        }
        .rh_select{
          width: 290px;
          border: 1px solid #dddddd;
          position: relative;
          margin-left:150px;
          height: 40px;
          padding-left: 10px;
          .rh_content{
            padding-left: 10px;
            font-size: 18px;
            line-height: 40px;
            //font-family: My_Medium;
            display: flex;
            align-items: center;
            cursor: pointer;
            span{
              position: absolute;
              height: 15px;
              line-height: 15px;
              font-size: 15px;
              background-color: #aaaaaa;
              color: #ffffff;
              right: 10px;
            }
          }
          .select_content{
            width: 300px;
            background-color: #ffffff;
            border: 1px solid #7a9cd3;
            z-index: 222;
            position: absolute;
            p{
              cursor: pointer;
              //font-family: My_Medium;
              margin-left: 10px;
            }
            p:hover{
              background-color: #1e90ff;;
              color: #ffffff;
            }
          }

        }
      }

      .hole_photo{
        /*display: flex;*/
        /*flex-wrap: wrap;*/
        margin-top: 30px;
        padding-bottom: 20px;
        .stracka-item {
          display: inline-block;
          width: 29%;
          margin-left: 35px;
          margin-bottom: 4%;
          font-size: 20px;
          line-height: 1;
          vertical-align: top;
        }
        .stracka-body {
          padding: 0;
          margin: 0 0 0 -0%;
          width: 103%;
          font-size: 0;
          line-height: 0;
          margin-top: 30px;
        }
        .stracka-map {
          /*margin-right: 10px;*/
          margin-bottom: 20px;
          position: relative;
        }
        .stracka-info{
          //font-family: My_Medium;
          display: flex;
          padding-left: 20px;
          .stracka-info-number{
             font-size: 40px;
            //font-family: My_Medium;
          }
          .stracka-info-par{
            padding-left: 10px;
            p{
              font-size: 17px;
              line-height: 20px;
              //font-family: My_Medium;
            }
          }
        }
      }
    }

  }
</style>