<template>
  <div class="shell">
    <div class="shell_content">
      <div class="FedExCupNew">
        <div class="title">
          <img src="../../assets/images/FedExCup.png" alt="" class="logo">
          <div>
            <div class="boldTitle">联邦快递杯排名</div>
            <div>2022-2023赛季排名</div>
          </div>
        </div>
        <div class="line"></div>
        <!-- 导航栏 -->
        <navFedExCupNew></navFedExCupNew>
        <!-- 底部 -->
        <router-view style="padding: 30px 20px" />
      </div>
    </div>
  </div>
</template>

<script>
  import navFedExCupNew from "../../components/navigation/navFedExCupNew";
  export default {
    components:{
      navFedExCupNew
    },
    data() {
      return {
        
      }
    },
    created(){
      
    },
    methods: {
      
    }
  }
</script>

<style scoped lang="less">
  .FedExCupNew {

  }
  .title {
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    padding: 30px 20px;
    .logo {
      width: 100px;
      height: 100px;
      border-radius: 74px;
      margin-right: 20px;
      padding: 0;
      border: 1px solid #00284B;
    }
    .boldTitle {
      font-weight: bold;
      font-size: 26px;
      margin-bottom: 16px;
    }
  }
  .line {
    width: 100%;
    height: 20px;
    background: #DDDDDD;
  }
</style>