<template>
  <div class="Leader">
    <!-- 领先榜固定图片 -->
<!--    <div>-->
<!--      <img src="../../../assets/banner.jpg" alt="" style="width: 100%;">-->
<!--      <div style="font-size: 22px;text-align: center;padding: 20px 0;color: rgb(0, 40, 75);cursor: pointer;" @click="goOutside">本周赛事领先榜请点击跳转美巡官网查看</div>-->
<!--    </div>-->
    <div class="shell">
      <div class="shell_content2">
        <!--列表-->
        <div class="center">
          <div class="line"></div>
          <div class="title">
            <p>领先榜</p>
          </div>
          <div class="center_list">
            <table border="0" cellspacing="0" style="width: 95.22%" v-if="playoffShow">
              <thead class="playoff">
                <div class="playoffDiv">延长赛</div>
              </thead>
              <tbody>
                <tr v-for="(item,index) in playOffData" :key="item.title" :class="{'gruy':(index+1)%2, 'playoffBlue': index == 0 || index == 2, 'playoffWhite': index == 1}">
                  <td style="text-align: left;padding-left: 10px;">{{item.title}}</td>
                  <td v-for="i in 18" :key="i">{{item[`data${i-1}`]}}</td>
                </tr>
              </tbody>
            </table>
            <table border="0" cellspacing="0">
              <thead>
              <!--               <tr>-->
              <th class="list01" :class="{'sort-desc':currentIndex===1&&sortB,'sort-asc':currentIndex===1&&!sortB}" @click="clickIndex(1)">排名</th>
              <th class="list01" :class="{'sort-desc':currentIndex===2}" @click="clickIndex(2)">
                <div class="iconfont icon-shengjiang"></div>
              </th>
              <th class="list01" :class="{'sort-desc':currentIndex===3}" @click="clickIndex(3)" style="width: 65px">国家或地区</th>
              <td class="list_td" :class="{'sort-desc':currentIndex===4}" @click="clickIndex(4)">球员名字</td>
              <th class="list02" :class="{'sort-desc':currentIndex===5}" @click="clickIndex(5)">总成绩</th>
              <th class="list03" :class="{'sort-desc':currentIndex===6}" @click="clickIndex(6)">完成球洞</th>
              <th class="list03" :class="{'sort-desc':currentIndex===7}" @click="clickIndex(7)">当轮成绩</th>
              <th class="list04" :class="{'sort-desc':currentIndex===8}" @click="clickIndex(8)">R1</th>
              <th class="list04" :class="{'sort-desc':currentIndex===9}" @click="clickIndex(9)">R2</th>
              <th class="list04" :class="{'sort-desc':currentIndex===10}" @click="clickIndex(10)">R3</th>
              <th class="list04" :class="{'sort-desc':currentIndex===11}" @click="clickIndex(11)">R4</th>
              <th class="list09" :class="{'sort-desc':currentIndex===12}" @click="clickIndex(12)">总杆数</th>
              <th class="list05">
                <tr>
                  <th class="list06" colspan="3">联邦快递杯</th>
                </tr>
                <tr>
                  <th class="list08" style="border-right: 1px solid #405e78;" :class="{'sort-desc':currentIndex===13}" @click="clickIndex(13)">赛后</th>
                  <th class="list08" style="border-right: 1px solid #405e78;" :class="{'sort-desc':currentIndex===14}" @click="clickIndex(14)">赛前</th>
                  <th class="list08" :class="{'sort-desc':currentIndex===15}" @click="clickIndex(15)"><div class="iconfont icon-shengjiang" style="font-size: 12px"></div></th>
                </tr>
              </th>
              <!--                </tr>-->
              </thead>
              <template v-for="(item ,index) in ListArray">
                <tbody class="cutLineTbody" v-if="cutLine.cutLineisHave && cutLine.cutLineIndex == item.p_id">
                  <div class="cutLineDiv">以下球手未能达到晋级线：{{cutLine.cutLineScore}}</div>
                </tbody>
                <tbody :key="index">
                  <tr :class="{'gruy':index%2,'trBg':item.trBgActive}" >
                    <td class="list01">{{item.position}}</td>
                    <td class="list01">
                      <!--  v-if="item.movement!=0" -->
                    <div>
                      <p class="arrowParent">
                        <img v-if="item.movementArrow == 1" :src="arrowTop" class="arrowItem" />
                        <img v-else-if="item.movementArrow == 2" :src="arrowBottom" class="arrowItem" />
                        <span v-if="item.movement!=0">{{item.movement}}</span>
                        <span v-else>-</span>
                      </p>
                      <!--                    <p style="width:10px;display: inline-block;; position:relative;"  :class="item.movement>0?'up':'dowm'">→</p>-->
                      <!--                    <p style="display: inline-block;  "  >{{item.movement<0?-item.movement:item.movement}}</p>-->
                    </div>
                    <!-- <div v-if="item.movement==0">
                      -
                    </div> -->
                  </td>
                    <td class="list01">
                      <span class="tc dis-bi vab flag flag-24x24" :class="item.country"></span>
                    <!-- <svg class="icon" aria-hidden="true">
                      <use v-bind:xlink:href="'#icon-'+item.playerNationE"></use>
                                          <use xlink:href="#icon-China"></use>
                                          <use  xlink:href="#icon-bofang"></use>
                    </svg> -->
                  </td>
                    <td class="list_td"  @click="handleCard(index)">
                      <span style="margin-right: 10px">
                      {{item.player_name}}
                      </span>
                      <span>
                      {{item.eName}}
                      </span>
                      <!-- <div class="fr player-media-col">
                        <span class="icon article icon-article"></span>
                        <span class="icon regular-video-icon mr3"></span>
                      </div> -->
                    </td>
                    <td>{{item.total}}</td>
                    <td>{{item.thru}}</td>
                    <td>
                      {{item.round_score}}
                    </td>
                    <td>{{'R1' in item?item.R1:'-'}}</td>
                    <td>{{'R2' in item?item.R2:'-'}}</td>
                    <td>{{'R3' in item?item.R3:'-'}}</td>
                    <td>{{'R4' in item?item.R4:'-'}}</td>
                    <td>{{(('R1' in item)?parseInt(item.R1):0)+(('R2' in item)?parseInt(item.R2):0)+(('R3' in item)?parseInt(item.R3):0)+(('R4' in item)?parseInt(item.R4):0)}}</td>
                    <td class="list07">
                      <span>{{'PROJ'in item?item.PROJ:'-'}}</span>
                      <span>{{'Starting'in item?item.Starting:'-'}}</span>
                      <span>
                        <div>
                          <p class="arrowParent">
                            <img v-if="item.arrowArrow == 1" :src="arrowTop" class="arrowItem" />
                            <img v-else-if="item.arrowArrow == 2" :src="arrowBottom" class="arrowItem" />
                            <span v-if="item.Arrow && item.Arrow!=0">{{item.Arrow}}</span>
                            <span v-else>{{'-'}}</span>
                          </p>
                          <!--                      <p style="width:10px;display: inline-block;; position:relative;"  :class="item.movement>0?'up':'dowm'">→</p>-->
                          <!--                      <p style="display: inline-block;  "  >{{item.movement<0?-item.movement:item.movement}}</p>-->
                      </div>
                      <!-- <div v-if="item.movement==0">
                      -
                      </div> -->
                      </span>
                    </td>
                </tr>
                <!-- 这里是展示球员的数据的板块 -->
                <tr style="z-index: 1" v-show="item.trBgActive">
                  <td colspan="100">
                    <leader-card :playerName="item.player_name" :playerEname="item.eName" :country="item.country" ref="leader_card"></leader-card>
                  </td>
                </tr>
                </tbody>
              </template>
            </table>
          </div>
        </div>
        <div class="tip_text">
          <p>备注：</p>
          <p>
            <span style="">*</span>    
            <span class="p_span2">球手从后九洞开始比赛</span>
          </p>
          <p>
            <span style="margin-right: 15px">#</span>
            <span class="p_span2">当不同轮数比赛同时进行时，仍在打上一轮比赛的球手名字旁边会有#标记</span>
          </p>
          <p>
            <span style="margin-right: 15px">(a)</span>
            <span class="p_span2">业余球手</span>
          </p>
          <p>MDF=Made Cut Did Not Finish  <span style="margin-left: 15px"> 已晋级但未完赛 </span></p>
          <p>WD=Withdrawn <span style="margin-left: 15px"> 退赛</span></p>
          <p>DNS=Did Not Start<span style="margin-left: 15px"> 未开始比赛</span></p>
          <p>DQ=Disqualified<span style="margin-left: 15px"> 取消比赛资格</span></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import pgaApi from "../../../request/pgaApi";
  import leaderCard from "../../../components/leaderCard/index"
  import arrowTop from '../../../assets/images/arrowTop.png'
  import arrowBottom from '../../../assets/images/arrowBottom.png'
  export default {
    name: "Leader",
    data() {
      return {
        //  表格数据
        ListArray:[
        ],
        chang_icon:true,
        currentIndex:1,//默认最开始按照排名排序
        sortB:true,//默认降序
        arrowTop,
        arrowBottom,
        cutLine: {
          cutLineScore: 0,
          cutLineisHave: false,
          cutLineIndex: 0,
        },
        playOffData: {},
        playoffShow: false,
      }
    },
    components:{leaderCard},
    created() {
      this.getList()
    },
    methods: {
      goOutside() {
        window.open('https://www.pgatour.com/leaderboard.html', '__blank');
      },
      /**
       * 请求列表数据
       * */
      async getList(){
        let data = await pgaApi({
          url:'/getLeaderBoard'
        })
        this.cutLine.cutLineScore = data.cutLineScore
        this.ListArray = data.leaderboard
        if(data.playOffData) {
          this.playoffShow = true;
          this.playOffData = this.handlePlayOff(data.playOffData);
        } else {
          this.playoffShow = false;
        }
        for (let i in this.ListArray){
          if(this.ListArray[i].position == 'CUT' && !this.cutLine.cutLineisHave) {
            this.cutLine.cutLineIndex = this.ListArray[i].p_id;
            this.cutLine.cutLineisHave = true;
          }
          this.ListArray[i].trBgActive = false
          this.ListArray[i] = this.handleMovement(this.ListArray[i]);
        }
        if (!data){
          console.log(data);
          console.log('数据为空');
        }

      },
      handlePlayOff(data) {
        let result = [];
        result[0] = {};
        result[1] = {};
        result[2] = {};
        result[0].title = '延长赛洞数';
        result[1].title = '球洞号';
        result[2].title = '标准杆';
        data.players.forEach((item,index) => {
          result[index+3] = {};
          result[index+3].title = item.cName + ' ' + item.eName;
          result[index+3].eName = item.eName;
        })
        for(let i = 0; i < data.playOff.length; i++) {
          result[0]['data'+i] = data.playOff[i]['Playoff Hole'] ? data.playOff[i]['Playoff Hole'] : '--';
          result[1]['data'+i] = data.playOff[i]['Course Hole'] ? data.playOff[i]['Course Hole'] : '--';
          result[2]['data'+i] = data.playOff[i]['Par'] ? data.playOff[i]['Par'] : '--';
          for(let j = 3; j < result.length; j++) {
            result[j]['data'+i] = data.playOff[i][result[j].eName];
          }
        }
        for(let i = data.playOff.length; i < 18; i++) {
          result[0]['data'+i] = '--';
          result[1]['data'+i] = '--';
          result[2]['data'+i] = '--';
          for(let j = 3; j < result.length; j++) {
            result[j]['data'+i] = '--';
          }
        }
        console.log(result);
        return result;
      },
      // 处理排名变动
      handleMovement(item) {
        var regPos = /^\d+(\.\d+)?$/; //非负浮点数
        var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
        if(regPos.test(item.movement) && parseInt(item.movement) > 0) { // 正数
          item.movementArrow = 1; // 1表示上升
        } else if(regNeg.test(item.movement)) { // 负数
          item.movementArrow = 2; // 2表示下降
          item.movement = Math.abs(item.movement);
        } else {
          item.movementArrow = -1;
        }
        if(regPos.test(item.Arrow) && parseInt(item.Arrow) > 0) { // 正数
          item.arrowArrow = 1; // 1表示上升
        } else if(regNeg.test(item.Arrow)) { // 负数
          item.arrowArrow = 2; // 2表示下降
          item.Arrow = Math.abs(item.Arrow);
        } else {
          item.arrowArrow = -1;
        }
        return item;
      },
     /**
      * 积分卡切换
     * **/
      handleCard(i){
        if (this.ListArray[i].trBgActive === false){
          this.ListArray[i].trBgActive = true
          this.$refs.leader_card[i].getCardFather(this.ListArray[i].p_id)
        }else if (this.ListArray[i].trBgActive === true){
          this.ListArray[i].trBgActive = false
        }
        this.$forceUpdate()
     },
      //排序
      clickIndex(i){
        this.currentIndex=i;
        if(this.sortB){
          this.sortB = false
        }else{
          this.sortB = true
        }
      }

    }
  }
</script>

<style scoped lang="less">
  .shell_content2{
    background-color: #ffffff;
    .center{
      width: 1300px;
    }
  }
  .center{
    padding-top: 60px;
    padding-bottom: 40px;
    .title{
      margin: 20px 0 20px 30px;
      font-weight: 600;
    }
    table{
      /*width: 90%;*/
      border-collapse:collapse;
      thead{
        color: #ffffff;
        background-color: #00284b;
        font-size: 14px;
        th{
          border-right: 1px solid #405e78;
          font-weight: 500;
          height: 53px;
          font-size: 14px;
          white-space: nowrap;
        }
        td{
          border-right: 1px solid #405e78;
        }
      }
      tbody{
        text-align: center;
        z-index: 3;
        td{
          border-right: 1px solid #e6e6e6;
          height: 50px;
          font-size: 15px;
        }
        td:nth-last-child(1){
          border-right:none;
        }
      }
      .list_td{
        cursor: pointer;
        width: 550px;
        padding: 0px 20px;
        text-align: left
      }
      .list01{
        width: 30px;
        font-size: 14px;
        svg{
          height: 16px;
          margin-top: 5px;
          width: 24px;
          background-color: #eeeeee;
        }

      }
      .list02{
        width: 40px;
      }
      .list03{
        width: 75px;
      }
      .list04{
        width: 25px;
      }
      .list09{
        width: 60px;
      }
      .list05{
        text-align: center;
        margin: 0;
        padding: 0;
        border-right: none;
        .list06{
          width:150px;
          border-bottom: 1px solid #405e78;

        }
        th{
          text-align: center;
          height: 20px;
          border-right:1px solid #405e78;
        }
      }
      .list08{
        width: 50px;
        /*border-right: 1px solid #405e78;*/
      }
      .list07{
        display: flex;
        padding: 0;
        span{
          flex: 1;
          text-align: center;
          height: 52px;
          line-height: 50px;;
          /*border: 1px solid red;*/
          border-right: 1px solid #e6e6e6;
        }

        span:nth-last-child(1){
          border-right: none;
        }
      }


    }

  }
  .gruy{
    width: 100%;
    height: 100%;
    background-color: #eeeeee;
  }
  .playoffBlue {
    background-color: rgb(200,221,254);
  }
  .playoffWhite {
    background-color: rgb(218,236,255);
  }
  .play_time{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    /*display: none;*/
    /*z-index: 5;*/
  }
  .up{
    color: #398e01;
    top: 2px;
    /*border: 2px solid red;*/
    transform:rotate(-90deg);
    -ms-transform:rotate(-90deg); 	/* IE 9 */
    -moz-transform:rotate(-90deg); 	/* Firefox */
    -webkit-transform:rotate(-90deg); /* Safari 和 Chrome */
    -o-transform:rotate(-90deg); 	/* Opera */
  }
  .dowm{
    color: #fa0000;
    top: -2px;
    transform:rotate(90deg);
    -ms-transform:rotate(90deg); 	/* IE 9 */
    -moz-transform:rotate(90deg); 	/* Firefox */
    -webkit-transform:rotate(90deg); /* Safari 和 Chrome */
    -o-transform:rotate(90deg); 	/* Opera */
  }
  .trBg {
    background-color: #5a5a5a;
    color: #ffffff;
    border: none;

    td {
      border-right: 1px solid #6a6a6a !important;
      height: 50px;
      font-size: 15px;
    }

    td:nth-last-child(1) {
      border-right: none;
    }

    .list07 {
      span {
        border-right: 1px solid #5f5f5f !important;;
      }

    }

  }
  /*  底部备注文字样式*/
  .tip_text{
    font-size: 14px;
    padding: 18px;
    position: relative;
    p{
      //font-family: My_Medium;
      height:30px;
      line-height: 30px;
      span{
        //font-family: My_Medium;
      }
    }
    p:nth-child(1){
      font-size: 18px;
    }
    .p_span2{
      position: absolute;
      left: 55px;
    }
  }
  .sort-desc,.sort-asc{
    background: #001528 url(../../../assets/images/sprite.png) no-repeat 50% 1000px;
  }
  .sort-asc{
    background-position: 50% -817px;
  }
  .sort-desc{
    background-position: 50% -861px;
  }
  .center_list .playoff {
    position: relative;
    height: 50px;
    .playoffDiv {
      position: absolute;
      width: calc(100% - 10px);
      height: inherit;
      line-height: 50px;
      background-color: #00284b;
      padding-left: 10px;
    }
  }
  .center_list .cutLineTbody {
    position: relative;
    height: 50px;
    .cutLineDiv {
      position: absolute;
      width: 100%;
      height: inherit;
      line-height: 50px;
      background: rgb(197,197,197);
    }
  }
  .center_list table th{
    cursor:pointer;
  }
  .center_list table .list06{
    cursor:default;
  }
  .fr{
    float: right;
  }
  .featured-video-icon, .regular-video-icon{
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    top: 0;
    right: 0;
  }
  .player-media-col .icon{
    display: block;
    float: right;
    width: 22px;
    height: 22px;
    margin-left: 3px;
    cursor: pointer;
    position: relative;
  }
.regular-video-icon{
  background: url(../../../assets/images/live-video-icon.svg) center no-repeat #003E7E;
}
.article{
  background: url(../../../assets/images/sprite.png) no-repeat 50% 1000px;
  background-position: -5px -250px;
}
.mr3{
  margin-right: 3px;
}
.tc{
  text-align: center;
} 
.dis-bi{
 display: inline-block;
}
.vab{
  vertical-align: bottom;
}
.arrowParent {
  display: flex;
  align-items: center;
  justify-content: center;
  .arrowItem {
    width: 12px;
  }
}
.Leader /deep/ .advert .ad img {
  height: unset!important;
}
</style>