<template>
<div class="Broadcast">
  <div class="top">
    <div >
      <video class="top_video"
             id="video1"
             ref="video"
             width="100%"
             controls="controls"
             playsinline="true"
            
             :poster="video.videoPostUrl"
             :src="video.videoUrl" 
             
             >
        <!--                  <source :src="video.videoUrl" type="video/mp4">-->
      </video>
    </div>
    <div class="top_title" > 
      <p>{{video.videoName}}</p>
      <p >简介：{{video.videoDescribe}}</p>
    </div>
  </div>
</div>
</template>

<script>
  import getAxios from "../../request";

  export default {
    name: "Broadcast",
    data(){
      return {
        //  视频播放器视频源
        video:{},
      }
    },
    created() {
    },
    mounted() {
      document.getElementById("video1").oncontextmenu = function(e){
      　　return false;
      }
    },
    methods:{
      
      //请求视频播放数据
      async getVideo(videoId){
        this.video={}
        this.video = await getAxios({
          url:"/videoDetail",
          data:{
            videoId
          }
        })
         this.$refs.video.muted = false
      },
    //  控制播放
      videoPlay(){
          this.$refs.video.muted = true

      }
    }
  }
</script>

<style scoped lang="less">
  .top{
    padding: 10px 10px  20px 10px;
    .top_video{
      outline: none;
    }
    .top_title{
      padding-left: 10px;
      p:nth-child(1){
        font-size: 28px;
        //font-family: My_Bold;
        padding: 15px 0 10px 0;

      }
      p:nth-child(2){
        font-size: 16px;
        /*font-weight: 600;*/
      }
      /*padding-bottom: 50px;*/
      /*border-bottom: 2px solid #eeeeee;*/
    }
    .right{
      overflow: hidden;
    }
  }
</style>