<template>
  <div class="PushTop" v-show="imgshow">
    <div class="outside" v-if="newVideoList.length!=0" >
      <!--     左边-->
      <div class="left">
        <div class="left_top">
          <!--          左上-->
          <div class="left_news" v-if="newVideoList[0]">
            <a href="javascript:;" @click="goOther(newVideoList[0].relationId, newVideoList[0].recommendType)">
              <div class="img" :style="' background-image: url('+newVideoList[0].recommendPoster+');'"  >
              </div>
              <!--                <img src="https://pga-tour-res.cloudinary.com/image/upload/c_fill,dpr_1.5,f_auto,g_center,h_432,q_auto,w_630/v1/pgatour/editorial/2021/03/24/pgatl-1268-wolff-spieth.jpg">-->
              <svg v-if="newVideoList[0].recommendType === 'video'"
                   class="icon icon_blur"
                   aria-hidden="true"
              >
                <use  xlink:href="#icon-bofang"></use>
              </svg>
              <div class="left_title">
                <span>{{newVideoList[0].recommendTitle}}</span>
              </div>
            </a>

          </div>
        </div>
      </div>
      <!--        中间-->
      <div class="center">
        <div class="center_news">
          <a href="javascript:;" @click="goOther(newVideoList[3].relationId, newVideoList[3].recommendType)">
            <div class="img" :style="' background-image: url('+newVideoList[3].recommendPoster+');'"  ></div>
            <!--            <img src="../assets/default2.png" alt="">-->
            <svg v-if="newVideoList[3].recommendType === 'video'" class="icon icon_blur" aria-hidden="true">
              <use  xlink:href="#icon-bofang"></use>
            </svg>
            <div class="center_title">
              <span>{{newVideoList[3].recommendTitle}}</span>
            </div>
          </a>
        </div>
        <div class="center_news">
          <a href="javascript:;" @click="goOther(newVideoList[4].relationId, newVideoList[4].recommendType)">
            <div class="img" :style="' background-image: url('+newVideoList[4].recommendPoster+');'"  ></div>
            <!--            <img  alt="">-->
            <svg v-if="newVideoList[4].recommendType === 'video'" class="icon icon_blur" aria-hidden="true">
              <use  xlink:href="#icon-bofang"></use>
            </svg>
            <div class="center_title">
              <span>{{newVideoList[4].recommendTitle}}</span>
            </div>
          </a>
        </div>
        <div class="center_news" v-if="newVideoList[5]">
          <a href="javascript:;" @click="goOther(newVideoList[5].relationId, newVideoList[5].recommendType)">
            <div class="img" :style="' background-image: url('+newVideoList[5].recommendPoster+');'"  ></div>
            <!--            <img   alt="">-->
            <svg v-if="newVideoList[5].recommendType === 'video'" class="icon icon_blur" aria-hidden="true">
              <use  xlink:href="#icon-bofang"></use>
            </svg>
            <div class="center_title">
              <span>{{newVideoList[5].recommendTitle}}</span>
            </div>
          </a>
        </div>
      </div>


      <!--      右边部分-->
      <div class="right">
        <slot name="girl"></slot>
      </div>
    </div>
  </div>
</template>

<script>
  import getAxios from "../request";

  export default {
    name: "PushTop2",
    props:{
      topTitle:{
        type:String,
        default:''
      },
      page:{
        type: Number,
        default: 2
      },


    },
    data(){

      return {
        imgshow: true,
        //默认图片
        imgURL:'this.src="'+require('../assets/default2.png')+'"',
        newVideoList:[],
      //  标签
        title:this.topTitle,
      }
    },
    created() {
      this.getNewVideoList()
    },
    methods:{
      handleLoad(e){
        // e.path[0].src="../assets/default2.png"
        let _this = this
        _this.imgshow = true

      },
      handerr(e){
        e.path[0].src="../assets/default2.png"
        let _this = this
        _this.imgshow = true
      },
    //  发送请求
      async getNewVideoList(){
        this.newVideoList = await getAxios({
          url:"/recommendList",
          data:{
            location:this.title
          }
        })
        // console.log( this.newVideoList);
        this.$forceUpdate()
      },
      goOther(id, classfy){
        // 把参数传给父组件
        this.$emit('getPushTop',{id,classfy})

      },
    }
  }
</script>

<style scoped lang="less">
  .PushTop{
    overflow: hidden;
  }
  .outside{
    display: flex;
    svg{
      position: absolute;
      z-index: 2;
      width: 40px;
      height: 40px;
      left: 50%;
      top: 45%;
      margin-top: -20px;
      margin-left: -20px;
    }
  }
  .img{
     background-position: center;
     background-size: cover;
     background-repeat: no-repeat;
   }
  .left{
    overflow: hidden;
    /*border: 2px solid red;*/
    // width: 50%;
    .left_top{
      .left_news{
        overflow: hidden;
        width: 100%;
        position: relative;
        svg{
          width: 80px;
          height: 80px;
          margin-top: -40px;
          margin-left: -40px;
        }
        a{
          .img{
            /*border: 1px solid red;*/
            width: 630px;
            height: 649px;
            /*background-position: center;*/
            background-size: cover;
            background-position: -450px;
            background-repeat: no-repeat;
          }
          .left_title {
            position: absolute;
            bottom: 1px;
            background-color: rgba(0,0,0,.7);
            width: 630px;
            color: #ffffff;
            font-size: 33px;
            padding: 30px;
            text-align: left;
            z-index: 3;
            /*border: 2px solid red;*/
          }
        }
      }
    }
  }
  .center{
    width: 338px;
    overflow: hidden;
      .center_news{
        margin-left: 2.5px;
        width: 100%;
        position: relative;
        margin-bottom: 2px;
        a{
          .img{
            width: 338px;
            /*border: 1px solid red;*/
            height: 215px;
          }
          .center_title {
            position: absolute;
            bottom: 2px;
            background-color: rgba(0,0,0,.7);
            width:338px;
            color: #ffffff;
            font-size: 20px;
            padding: 15px;
            text-align: left;
            z-index: 3;
            /*border: 1px solid red;*/
          }
        }

      /*.center_news:nth-child(1){*/
      /*  margin-right: 1px;*/
      /*}*/
    }
  }
  .right{
    width: 346px;
    height: 649px;
    margin-left: 2.5px;
  }
</style>
